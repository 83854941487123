<template>
	<div class="copilot-reservation-kitchen flex-column"  :class="{'copilot-reservation-kitchen-finish': reservation.finishedDateTime}">
		<div class="d-flex gap-1">
			<div class="reservation-wrapper-kitchen-data" >
				<div class="product-card-employee product-card-employee-secondary" :class="{'reservation-kitchen-finish': !productsOrderAcceptedGroupedByDishClass.length && reservation.orderedItems.length}">
					<div class="badge badge-primary badge-time">
						<i class="fa-solid fa-clock" style="font-size: 10px"></i> {{ reservation.startDate }}
					</div>
					<div class="product-card-body">
						<div class="product-card-info">
							<div class="product-card-data">
								<div class="product-card-title">
									<i class="fas fa-user"></i> {{ reservation.pax }}
								</div>
								<div class="product-card-title">
									<!--								:fill-color="reservation.finishedDateTime || !productsOrderAcceptedGroupedByDishClass.length && reservation.orderedItems.length ? 'black': 'white'"-->
									<TableIcon fill-color="black" style="width: 15px" /> {{ reservation.table }}
								</div>
								<div class="product-card-title">
									<i class="fas fa-house-user"></i>{{ reservation.roomName }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="reservation-kitchen-content scroll-x" ref="kitchenProducts" @scroll="updateShadowProducts">
				<copilot-products-kitchen :key="productsDishClass.dishClass.sortOrder" v-for="productsDishClass in productsOrderAcceptedGroupedByDishClass" :dish-class="productsDishClass.dishClass" :products-order="productsDishClass.products"></copilot-products-kitchen>

				<CopilotProductsCollapseKitchen v-if="productsOrderFinished.length" :reservation-id="reservation.id" :products-order="productsOrderFinished"/>
			</div>
			<div v-if="canScrollRightScrollProducts" class="scroll-shadow" style="z-index: 3;width: 50px;height:4.5rem;background: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent); "></div>
		</div>

		<div v-if="filterClientMessagesForReservation"  class="container-fluid p-1  bg-secondary">
			<div class="row g-0">
				<div class="col-12">
					<div class="d-flex gap-1 align-items-center">
						<i class="fa-solid fa-comment"></i>

						<div class="text-uppercase w-100 comment-kitchen" ref="kitchenComment" @scroll="updateShadow">
							{{ filterClientMessagesForReservation }}
						</div>
						<div v-if="canScrollRight" class="scroll-shadow"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TableIcon from "@/core/icons/TableIcon";
import {mapGetters} from "vuex";
import CopilotProductsCollapseKitchen from "@/components/copilotProductsCollapseKitchen/CopilotProductsCollapseKitchen";
import CopilotProductsKitchen from "@/components/copilotProductsKitchen/CopilotProductsKitchen";

export default {
	name: 'CopilotReservationKitchen',
	components: {CopilotProductsKitchen, CopilotProductsCollapseKitchen, TableIcon },
	props:{
		reservation: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			canScrollRight: false,
			canScrollRightScrollProducts: false,
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.updateShadow();
			this.updateShadowProducts();
			window.addEventListener("resize", this.updateShadow);
		});
	},
	computed: {
		...mapGetters('copilot', ['selectedRole','reservationsWithComments']),

		productsOrderAccepted(){
			if (!this.reservation.orderedItems) {
				return [];
			}
			const productsAccepted = this.reservation.orderedItems
				.filter(item => {
					const requirementProductCopilotRoles = item.requirementProductCopilotRoles?.map(role => role.id);
					return item.statusProductOrder > 0 && item.statusProductOrder < 3 && requirementProductCopilotRoles.includes(this.selectedRole);
				})
				.sort((a, b) => {
					if (a.statusProductOrder === 2 && b.statusProductOrder !== 2) {
						return -1;
					}
					if (a.statusProductOrder !== 2 && b.statusProductOrder === 2) {
						return 1;
					}
					if (a.statusProductOrder === b.statusProductOrder) {
						return new Date(a.createdDateTime) - new Date(b.createdDateTime);
					}
					return a.statusProductOrder - b.statusProductOrder;
				});
			return productsAccepted
		},
		productsOrderFinished(){
			if (!this.reservation.orderedItems) {
				return [];
			}
			const productsFinished = this.reservation.orderedItems
				.filter(item => {
					const requirementProductCopilotRoles = item.requirementProductCopilotRoles?.map(role => role.id);
					return (item.statusProductOrder >= 3 || item.statusProductOrder <= -1) && requirementProductCopilotRoles.includes(this.selectedRole);
				})
				.sort((a, b) => {
					if (a.statusProductOrder === 3 && b.statusProductOrder !== 3) return -1;
					if (a.statusProductOrder !== 3 && b.statusProductOrder === 3) return 1;
					if (a.statusProductOrder === b.statusProductOrder) {
						return new Date(a.createdDateTime) - new Date(b.createdDateTime);
					}
					return a.statusProductOrder - b.statusProductOrder;
				});
			return productsFinished;
		},
		productsOrderAcceptedGroupedByDishClass() {
			// Encuentra el menor sortOrder en los productos con dishClass no nulo
			const minSortOrder = this.productsOrderAccepted
				.filter(product => product.dishClass !== null) // Filtrar productos con dishClass no nulo
				.map(product => product.dishClass.sortOrder);

			// Calcular el sortOrder ajustado para dishClass null
			const adjustedSortOrder = minSortOrder.length ? Math.min(...minSortOrder) -1 : 0

			// Agrupar productos por dishClass
			const groupedByDishClass = this.productsOrderAccepted.reduce((acc, product) => {
				// Si dishClass es null, usa el sortOrder ajustado
				if (product.dishClass === null) {
					product.dishClass = { id: null, name: null, sortOrder: adjustedSortOrder };
				}

				const key = product.dishClass.id;

				if (!acc[key]) {
					acc[key] = {
						dishClass: product.dishClass,
						products: [],
					};
				}

				acc[key].products.push(product);
				return acc;
			}, {});

			// Convertir el objeto agrupado en un array y ordenarlo
			return Object.values(groupedByDishClass).sort((a, b) => a.dishClass.sortOrder - b.dishClass.sortOrder);
		},
		filterClientMessagesForReservation() {
			const reservation = this.reservationsWithComments.find(
				(reservation) => reservation.reservationId === this.reservation.id
			);

			if (reservation && reservation.cartSessionOrders) {
				return reservation.cartSessionOrders
					.map(order => order.clientMessage)
					.filter(message => message)
					.join(', ');
			}

			return null;
		}
	},
	methods: {
		updateShadow() {
			const kitchenComment = this.$refs.kitchenComment;
			if (!kitchenComment) return; // Evitar acceso si el ref es nulo

			const isOverflowing =
				kitchenComment.scrollWidth > kitchenComment.clientWidth;

			this.canScrollRight =
				isOverflowing &&
				kitchenComment.scrollLeft + kitchenComment.clientWidth <
				kitchenComment.scrollWidth;
		},

		updateShadowProducts() {
			const kitchenProducts = this.$refs.kitchenProducts;
			if (!kitchenProducts) return; // Evitar acceso si el ref es nulo

			const isOverflowing =
				kitchenProducts.scrollWidth > kitchenProducts.clientWidth;

			this.canScrollRightScrollProducts =
				isOverflowing &&
				kitchenProducts.scrollLeft + kitchenProducts.clientWidth <
				kitchenProducts.scrollWidth;
		},

	}
}
</script>

