import {menuRepository} from "@/service/repository/menuRepository";
import {arraysAreEqualIgnoringOrder, normalizeChoices} from "@/utils";
import store from "@/store/index";
import {notificationRepository} from "@/service/repository/notificationRepository";

const state = {
	menuProducts: [],
	menus: [],
	productsCart: [],
	dishClasses: [],
	isAccountRequester: false,
	isOpenModalSearch: false,
	getProduct: null
};

const getters = {
	menus: state => {
		return state.menus
	},
	menusVenue: state => {
		return state.menus.map(menuItem => {
			// Convertir menuCategories a un array si es un objeto
			const categories = Array.isArray(menuItem.menuCategories)
				? menuItem.menuCategories
				: [menuItem.menuCategories]; // Convertir a array si es un objeto

			return {
				...menuItem,
				menuCategories: categories.map(category => {
					// Convertir products a un array si es un objeto
					const products = Array.isArray(category.products)
						? category.products
						: category.products ? Object.values(category.products) : []; // Convertir a array si es un objeto

					return {
						...category,
						products: products.map(product => {
							// Convertir productOptions a un array si es un objeto
							const productOptionsArray = Array.isArray(product.productOptions)
								? product.productOptions
								: product.productOptions ? Object.values(product.productOptions) : []; // Convertir a array si es un objeto

							return {
								...product,
								productOptions: productOptionsArray.map(productOption => {
									// Si options es un array, filtrar las opciones
									const filteredOptions = Array.isArray(productOption.options)
										? productOption.options.filter(option => option.enabled >= 1) // Filtrar opciones
										: []; // Si options no es un array, devolvemos un array vacío

									return {
										...productOption,
										options: filteredOptions
									};
								})
							};
						})
					};
				})
			};
		});
	},
	menuProducts: state => {
		return state.menus.flatMap(menu => {
			// Convertir menuCategories a un array si es un objeto
			const categories = Array.isArray(menu.menuCategories)
				? menu.menuCategories
				: [menu.menuCategories];  // Envuelve el objeto en un array

			return categories.flatMap(category => {
				// Convertir products a un array si es un objeto
				const products = Array.isArray(category.products)
					? category.products
					: category.products ? Object.values(category.products) : [];  // Convierte el objeto a array si no es nulo

				// Filtrar los productos cuya propiedad isMenu sea "SI"
				return products.filter(product => product.isMenu === "SI");
			});
		});
	},
	productsCart: state => {
		return state.productsCart
	},
	dishClasses: state => {
		return state.dishClasses
	},
	isAccountRequester: state => {
		return state.isAccountRequester
	},
	isOpenModalSearch: state => {
		return state.isOpenModalSearch
	},
	getProduct: state => {
		return state.getProduct
	}

};

const mutations = {

	setMenuProducts(state, payload) {
		console.log("Quiero asignar los menuproducts", payload.menuProducts)
		state.menuProducts = payload.menuProducts;
	},
	setMenus(state, payload) {
		console.log("Quiero asignar los menus", payload.menus)
		state.menus = payload.menus;
	},
	setDishClasses(state, payload) {
		state.dishClasses = payload.dishClasses;
	},
	setProductsCart(state, payload) {
		state.productsCart = payload.productsCart;
	},
	setProduct(state, payload) {
		state.getProduct = payload
	},
	addProductToCart(state, payload) {
		console.log('lo que llega al modulo', payload)
		let units = payload.units
		const normalizedOptions = normalizeChoices(payload.options);
		console.log('las normalilze', normalizedOptions)
		const isIncluded = state.productsCart.some(productCart =>
			productCart.sku === payload.product.sku &&
			arraysAreEqualIgnoringOrder(productCart.chosenOptions, normalizedOptions)
		);

		payload.product.isMenuIncluded = false;
		if (payload.product.isUnitary === 'SI') {
			units = payload.product.servingQtyMin;
		}

		if (!isIncluded) {
			units ? payload.product.cartItemCount = units : payload.product.cartItemCount = 1;
			const newProductsCart = [...state.productsCart, {
				...payload.product,
				chosenOptions: normalizedOptions,
				cartItemCount: units || 1
			}];
			console.log('el nuevo carrito', newProductsCart)
			state.productsCart = newProductsCart;
		} else {
			const existingProduct = state.productsCart.find(productCart =>
				productCart.sku === payload.product.sku &&
				arraysAreEqualIgnoringOrder(productCart.chosenOptions, normalizedOptions)
			);
			units = payload.product.unitaryIncrement;
			existingProduct.cartItemCount += units || 1;
		}
	},
	addUnit(state, payload) {
		const normalizedChosenOptions = normalizeChoices(payload.product.chosenOptions);
		console.log('Adding unit for product:', payload.product.sku, 'with options:', normalizedChosenOptions);

		const productCart = state.productsCart.find(productCart =>
			productCart.sku === payload.product.sku &&
			arraysAreEqualIgnoringOrder(normalizeChoices(productCart.chosenOptions), normalizedChosenOptions)
		);

		if (!productCart) {
			console.error('Product not found in cart:', payload.product);
			return;
		}

		if (productCart.isUnitary === 'SI') {
			productCart.cartItemCount += payload.product.unitaryIncrement || 1;
		} else {
			productCart.cartItemCount++;
		}
	},
	modifyDishClassOfCartProduct(state, payload){
		const productChosenOptionsIds = payload.product.chosenOptions.flatMap(option => option.chosenIds)
		const selectedDishClassId = payload.selectedDishClassId;

		const dishClass = state.dishClasses.find(({ id }) => id === selectedDishClassId);
		state.productsCart = state.productsCart.map(product => {
			const productCartChosenOptions = product.chosenOptions.flatMap(option => option.chosenIds);

			if (product.sku === payload.product.sku) {
				const optionsMatch = arraysAreEqualIgnoringOrder(productChosenOptionsIds, productCartChosenOptions);
				const dishClassMismatch = product.dishClass.id !== selectedDishClassId;

				if (optionsMatch && dishClassMismatch) {
					return {...product, modifyDishClass:dishClass};
				} else {
					return Object.fromEntries(Object.entries(product).filter(([key]) => key !== 'modifyDishClass'));
				}
			}

			return  { ...product };
		});

	},
	updateShareableProduct(state, payload){
		const productChosenOptionsIds = payload.product.chosenOptions.flatMap(option => option.chosenIds)
		state.productsCart = state.productsCart.map(product => {
			const productCartChosenOptions = product.chosenOptions.flatMap(option => option.chosenIds);

			if (product.sku === payload.product.sku) {
				const optionsMatch = arraysAreEqualIgnoringOrder(productChosenOptionsIds, productCartChosenOptions);
				if (optionsMatch) {
					return {...product, isShareable:payload.shareable };
				}
			}

			return  { ...product };
		});
	},

	subtractUnit(state, payload) {
		const normalizedChosenOptions = normalizeChoices(payload.product.chosenOptions);

		const productCart = state.productsCart.find(productCart => {
			const normalizedProductCartChosenOptions = normalizeChoices(productCart.chosenOptions);
			const isMatching = productCart.sku === payload.product.sku &&
				arraysAreEqualIgnoringOrder(normalizedProductCartChosenOptions, normalizedChosenOptions);
			console.log('Comparing products for subtract:', productCart, payload.product, 'isMatching:', isMatching, 'normalizedProductCartChosenOptions:', normalizedProductCartChosenOptions, 'normalizedChosenOptions:', normalizedChosenOptions);
			return isMatching;
		});

		if (!productCart) {
			console.error('Product not found in cart:', payload.product);
			return;
		}

		const valueDecrement = payload.product.unitaryIncrement || 1;
		const element = document.getElementById(payload.product.sku);
		if (valueDecrement === 0) {
			element.classList.remove('addProduct');
		}

		if (productCart.cartItemCount === 1 || productCart.cartItemCount === productCart.servingQtyMin) {
			const newProductsCart = state.productsCart.filter(productCart =>
				productCart.sku !== payload.product.sku ||
				!arraysAreEqualIgnoringOrder(normalizeChoices(productCart.chosenOptions), normalizedChosenOptions));
			store.commit('menu/setProductsCart', {productsCart: newProductsCart})
		} else {
			productCart.cartItemCount -= valueDecrement;
		}
	},
	deleteProductFromCart(state, payload){
		state.productsCart = state.productsCart.filter(product => product.sku !== payload.sku)
	},
	setIsAccountRequester(state, value) {
		state.isAccountRequester = value;
	},
	setIsOpenModalSearch(state, value) {
		state.isOpenModalSearch = value;
	},
	updateMenuProduct(state, payload) {
		const { product: updatedProduct } = payload;

		console.log("llamamos a cambiar un producto del menu con", payload);

		state.menus = state.menus.map(menu => ({
			...menu,
			menuCategories: menu.menuCategories?.map(category => ({
				...category,
				products: category.products?.map(product => {
					if (product.sku === updatedProduct.sku) {
						console.log('hay coincidencia en', product);
						return updatedProduct;
					}

					if (product.isMenu === "SI") {
						const productOptions = updatedProduct.productOptions;
						product.productOptions = product.productOptions.map(existingOption => {
							const matchingOption = productOptions?.find(newOption => newOption.id === existingOption.id);
							return matchingOption || existingOption;
						});
					}

					return product;
				}),
			})),
		}));
	},
};

const actions = {
	async loadMenus({commit}) {
		try {
			const menusProductResult = await menuRepository.getAvailableMenus();
			if (menusProductResult.result === 0) {
				console.log('menús del día desde la carga inicial', menusProductResult.menus);
				commit('setMenus', {menus: menusProductResult.menus});
				commit('setDishClasses', {dishClasses: menusProductResult.dishClasses});
			}
		} catch (error) {
			console.error("Failed to fetch venue menus", error);
		}
	},
	async loadMenuProducts({commit}) {
		try {
			const menuProductResult = await menuRepository.getAvailableMenuProducts();
			if (menuProductResult.result === 0) {
				console.log('menús del día desde la carga inicial de copilot', menuProductResult.menuProducts);
				commit('setMenuProducts', {menuProducts: menuProductResult.menuProducts});
			}
		} catch (error) {
			console.error("Failed to fetch venue menus", error);
		}
	},
	async updateMenuProduct(payload) {
		try {
			const updateMenuProductResult = await menuRepository.updateMenuProduct(payload);
			if (updateMenuProductResult.result === 0) {
				console.log('Menú actualizado correctamente');
			}
		} catch (error) {
			console.error("Error al actualizar un producto menú", error);
		}
	},

	async requestAccount({commit}, payload) {
		const reservationInfo = payload.reservationInfo
		const user = payload.user;
		const paymentMethod = payload.paymentMethod;
		let targetName = 'Comandero';
		let uid = null;

		if (user?.name !== undefined) {
			targetName = user.name;
		}
		if (user?.hostCode !== undefined) {
			uid = user.hostCode;
		}

		console.log(user, reservationInfo, ' desde el menu module')
		const notification = {
			"status": 0,
			"type": 'ticket_request',
			"subtype": 0,
			"target": targetName,
			"paymentMethod": paymentMethod
		};

		const data = {
			"pax": reservationInfo.pax,
			"reservationId": reservationInfo.id,
			"reservationName": targetName,
			"uid": uid,
		};


		const resultTicket = await notificationRepository.requestTicket({
			notification: notification,
			data: data
		})

		if (resultTicket.result === 0) {
			commit('setProductsCart', {productsCart: []})
		}

		return resultTicket;
	},
	changeAccountRequesterStatus({commit}, status) {
		commit('setIsAccountRequester', status);
	},
	changeIsOpenModalSearch({ commit }, status) {
		commit('setIsOpenModalSearch', status);
	},

	getProduct({ commit },payload) {
		commit('setProduct', payload);
	},

}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
};
