<template>
	<ul class="list-group list-group-flush">
		<li
			class="list-group-item border-bottom border-secondary"
			v-for="(item, index) in list"
			:key="`${item.id}-${index}`"
		>
			<div class="container">
				<div class="row">
					<div class="col-9">
						<strong>{{ item.name }}</strong>
						<br>
						<small class="text-muted">
							<span
								v-for="(optionName, idx) in getChosenOptionNames(item)"
								:key="idx"
							>
								{{ optionName }}
								<span v-if="idx !== getChosenOptionNames(item).length - 1">, </span>
							</span>
						</small>
					</div>
					<div class="col-3 text-end">
						<b>{{ item.cartItemCount }} ud</b>
					</div>
				</div>
			</div>
		</li>
	</ul>
</template>
<script>
export default {
	name: "ListProductBasicWrapper",
	props: {
		list: {
			type: Array,
			required: true
		}
	},
	methods: {
		getChosenOptionNames(item) {
			// Verifica si hay opciones y seleccionadas; si no, devuelve lista vacía.
			if (!item.productOptions || !item.chosenOptions) return [];

			// Itera por las opciones seleccionadas para buscar sus nombres.
			return item.chosenOptions.flatMap(chosenOption => {
				// Encuentra la opción principal según el ID.
				const mainOption = item.productOptions.find(opt => opt.id === chosenOption.optionId);
				if (!mainOption) return "N/A"; // Si no existe, retorna "N/A".

				// Busca cada ID seleccionado dentro de las opciones de la opción principal.
				return chosenOption.chosenIds.map(chosenId => {
					const selectedOption = mainOption.options?.find(opt => opt.id === chosenId);

					// Retorna el nombre de la opción directamente o "N/A".
					return selectedOption?.name || "N/A";
				});
			});
		},
	}
}
</script>
