<template>
	<h4>{{ t('copilot.chooseStation') }}</h4>
	<ul class="navbar-nav justify-content-end flex-grow-1 gap-3">
		<li class="list-group-item">
			<input
				class="form-check-input me-1"
				type="checkbox"
				:id="'station-all'"
				:checked="showAllProductOrdersInKitchen"
				@change="handleAllChange($event)"
			/>
			<label class="form-check-label mb-0" :for="'station-all'">
				{{ t('copilot.allStations') }}
			</label>
		</li>
		<li
			class="list-group-item"
			v-for="station in elaborationStations"
			:key="station.id"
		>
			<input
				class="form-check-input me-1"
				type="checkbox"
				:value="station.id"
				:id="`station-${station.id}`"
				:checked="selectedStations.includes(station.id)"
				@change="handleStationChange(station.id, $event)"
			/>
			<label class="form-check-label mb-0" :for="`station-${station.id}`">
				{{ station.name }}
			</label>
		</li>
	</ul>
	<div class="vr-horizontal"></div>
</template>




<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {useI18n} from "vue-i18n";
export default {
	name: "ElaborationStationSelector",
	computed:{
		...mapGetters('copilot', ["elaborationStations", "showAllProductOrdersInKitchen", "selectedStations"]),
	},
	setup() {
		const { t } = useI18n(); // Extract the translation function `t` and the reactive `locale`

		return {
			t,
		};
	},
	methods:{
		handleAllChange(event) {
			if (event.target.checked) {
				store.commit('copilot/setShowAllProductOrdersInKitchen',{show:true})
				store.commit("copilot/setSelectedStations", {selectedStations: []})
			}
		},
		handleStationChange(stationId, event) {
			store.commit('copilot/setShowAllProductOrdersInKitchen', { show: false });

			if (event.target.checked) {
				const updatedStations = [...this.selectedStations, stationId];
				store.commit('copilot/setSelectedStations', { selectedStations: updatedStations });
			} else {
				const updatedStations = this.selectedStations.filter(id => id !== stationId);
				store.commit('copilot/setSelectedStations', { selectedStations: updatedStations });

				if (updatedStations.length === 0) {
					store.commit('copilot/setShowAllProductOrdersInKitchen', { show: true });
				}
			}
		},

	}
}
</script>

<style scoped>

</style>
