<template>
	<ModalWrapper id="modal-message-products" title="¡Pedido enviado con éxito!">
		<template #icon>
			<i class="fas fa-check-circle"></i>
		</template>

		<p class="text-center">
			<b>¡Gracias, {{message.clientName}}!</b> Estamos preparando tu pedido.
		</p>

		<ListProductBasicWrapper :list="message.products" />
	</ModalWrapper>
</template>

<script>
import ModalWrapper from "@/components/utils/modalWrapper/ModalWrapper";
import ListProductBasicWrapper from "@/components/utils/listProductBasicWrapper/ListProductBasicWrapper";
export default {
	name: "ModalMessageProducts",
	components: {ListProductBasicWrapper, ModalWrapper},
	props: ['message'],
	computed:{
		getOptionName(optionId, chosenId) {
			const productOption = this.product.productOptions.find(option => option.id === optionId);
			const option = productOption ? productOption.options.find(opt => opt.id === chosenId) : null;
			return option ? option.name : '';
		},
	},
}
</script>
