<template>
	<div class="navbar-bottom-container" v-if="!isOpenModalSearch" :style="isCopilotRoute ? { bottom: '62px', position : 'absolute' } : {}">
		<div class="navbar-btns-action float-btns">
			<button v-if="isValidReservation" :class="{ 'view-btn visible': isVisibleBtnAccount, 'invisible': isInvisibleBtnAccount }" type="button" @click="openModalAccount" class="btn  btn-primary ">
				<i class="fa-solid fa-hand-holding-dollar"></i> {{ t('menu.requestBill') }}
			</button>
			<div v-else></div>
			<div class="w-auto align-items-end" style="display: flex; flex-direction: column; gap: .5rem">
				<div>
					<!--		TODO: no borrar muestra y oculta al scroll  :class="{ 'hidden-button': !scrollButtonVisible, 'view-btn': scrollButtonVisible}" 		-->
					<a href="#" @click.prevent="scrollToTop()" :class="{ 'hidden-button': !scrollButtonVisible, 'view-btn': scrollButtonVisible}" class="btn shopping-cart border-secondary btn-primary btn-rounded">
						<i class="fas fa-arrow-up"></i>
					</a>
				</div>
				<button id="cartIcon" v-if="isValidReservation" @click="openModalOrders" :class="[{ 'hidden-button': totalProducts === 0, 'view-btn': totalProducts > 0},totalProducts !== 0 ? 'pulse' : 'shopping-cart']" class="btn w-100  border-primary btn-secondary btn-rounded">
					<!--				<span v-if="showNewProductAlert" class="alert-new-product">-->
					<!--					  {{ lastAction === 'add' ? 'Producto añadido' : 'Producto eliminado' }}-->
					<!--				</span>-->
					<i class="fas fa-shopping-cart"></i> {{ t('menu.miCarritoButton') }}
					<span v-if="totalProducts !== 0" class="badge badge-primary badge-cart">{{ totalProducts }}</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import * as bootstrap from "bootstrap";
import {showModal, showOffcanvas} from "@/utils";
import {mapGetters} from "vuex";
import {useI18n} from "vue-i18n";
// import { scrollTo } from 'vue-scrollto';

export default {
	name: 'NavBottom',
	emits: [],
	data() {
		return {
			scrollButtonVisible: false,
			isOrdersExist: false,
			activeCategory: null,

			lastTotalProducts: 0,
			// showNewProductAlert: false,
			timeoutID: null,
			lastAction: 'add',
		};
	},
	setup() {
		const { t } = useI18n();
		return { t };
	},
	props: {
		activeMenu: {
			type: Object,
			default: () => ({
				menus: []
			})
		},
		totalProducts: {
			type: Number,
			default: 0
		},
		ticket: {
			type: Object,
			default: () => ({})
		},
		isValidReservation: {
			type: Boolean,
			default: false
		},
		cartSession: {
			type: Object,
			default: () => ({})
		},
		isCopilotRoute: {
			type: Boolean,
		}
	},
	computed: {
		...mapGetters('menu', ['isOpenModalSearch']),

		isVisibleBtnAccount() {
			return this.cartSession.productOrders != null && this.cartSession.productOrders.length > 0
		},
		isInvisibleBtnAccount() {
			return this.cartSession.productOrders == null || this.cartSession.productOrders.length === 0 || this.cartSession.productOrders === []
		}
	},
	beforeMount() {
	},
	async mounted() {
		// window.addEventListener('scroll', this.handleScroll);
		// window.addEventListener('scroll', this.handleScrollShowBtn);
		this.initializeScrollListener();

	},
	beforeUnmount() {
		// window.removeEventListener('scroll', this.handleScroll);
		// window.removeEventListener('scroll', this.handleScrollShowBtn);
		if (this.scrollContainer) {
			this.scrollContainer.removeEventListener('scroll', this.handleScroll);
		}
		this.disabledButtonAccount()
	},
	watch: {
		'cartSession.orders': {
			handler(newOrders) {
				this.isOrdersExist = newOrders && newOrders.length > 0;
			},
			deep: true
		},
		totalProducts(newVal) {
			if (newVal !== this.lastTotalProducts) {
				// this.showNewProductAlert = false;

				if (this.timeoutID) {
					clearTimeout(this.timeoutID);
				}

				// this.$nextTick(() => {
				// 	this.showNewProductAlert = true;
				// 	this.timeoutID = setTimeout(() => {
				// 		this.showNewProductAlert = false;
				// 	}, 2000);
				// });

				if (newVal > this.lastTotalProducts) {
					this.lastAction = 'add';
				} else {
					this.lastAction = 'remove';
				}

				this.lastTotalProducts = newVal;
			}
		},
	},
	methods: {
		disabledButtonAccount() {
				console.log('Cart sesion', this.cartSession.productOrders)
		},
		initializeScrollListener() {
			let offcanvasBody = document.querySelector('#offcanvas-body-commander');
			if (offcanvasBody && offcanvasBody.contains(this.$el)) {
				this.scrollContainer = offcanvasBody;
			} else {
				this.scrollContainer = window;
			}
			this.scrollContainer.addEventListener('scroll', this.handleScrollShowBtn);
		},
		scrollTo(targetId) {
			const targetSection = document.getElementById(targetId);
			if (targetSection) {
				const targetOffset = targetSection.offsetTop;
				const navbarTopHeight = 0;
				targetSection.scrollIntoView({behavior: 'smooth'});
				setTimeout(() => {
					window.scrollTo({
						top: targetOffset - navbarTopHeight,
						behavior: 'smooth'
					});
				}, 10);
			}
		},
		handleScrollShowBtn() {
			const scrollPosition = this.scrollContainer === window ? window.scrollY : this.scrollContainer.scrollTop;
			this.scrollButtonVisible = scrollPosition > 300;
		},

		scrollToTop() {
			if (this.scrollContainer && this.scrollContainer !== window) {
				this.scrollContainer.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
				// scrollTo(this.scrollContainer, {
				// 	// container: "body",
				// 	// duration: 500,
				// 	offset: 0,
				// 	// easing: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
				// 	lazy: false,
				// 	force: false,
				// });
			} else {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});

				// scrollTo(window, {
				// 	// container: "body",
				// 	// duration: 500,
				// 	offset: 0,
				// 	// easing: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
				// 	lazy: false,
				// 	force: false,
				//
				// });
			}
		},
		openModalOrders() {
			const modalElement = document.getElementById('orders');
			if (modalElement) {
				let myModal = bootstrap.Modal.getInstance(modalElement);

				if (!myModal) {
					myModal = new bootstrap.Modal(modalElement, {
						backdrop: false,
						keyboard: true,
						focus: true
					});
				}
				myModal.show();

			} else {
				console.error('El elemento del modal no se encontró en el DOM.');
			}
		},
		openModalAccount() {
			if (this.isCopilotRoute) {
				const elementId = 'modalMenuRequestAction';
				showModal(elementId)
			} else {
				const elementId = 'menu-modal-assessment';
				showOffcanvas(elementId)
			}
		},

	}
}
</script>

