<template src="./menu.html"></template>
<script>
import * as bootstrap from 'bootstrap';
import MenuProductCard from "@/components/menuProductCard/MenuProductCard";
import MenuCartProduct from "@/components/menuCartProduct/MenuCartProduct";
import NavBottom from "@/components/navBottom/NavBottom";
import ModalMessage from "@/components/mods/modalMessage/ModalMessage";
import GeneratorQr from "@/components/generatorQr/GeneratorQr";
import NavBarTop from "@/components/navBarTop/NavBarTop";
import FooterWelcom from "@/core/FooterWelcom";
import {mapGetters} from "vuex";
import {
	arraysAreEqualIgnoringOrder,
	groupItemsBySkuAndChoices, showModal,
} from "@/utils";
import {WebSocketClient} from "@/service/webSocketClient";
import NavbarScrollCategories from '@/components/navbarScrollCategories/NavbarScrollCategories'
import HeaderComponent from "@/core/HeaderComponent";
import MenuSearcher from "@/components/menuSearcher/MenuSearcher";
import SpinnerIcon from "@/core/icons/SpinnerIcon";
import DismissButton from "@/core/DismissButton";
import BagOrdersIcon from "@/core/icons/BagOrdersIcon";
import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";
import ChefHatIcon from "@/core/icons/ChefHatIcon";
import store from "@/store";
import menuOffcanvasAssessment from "@/components/menuOffcanvasAssessment/menuOffcanvasAssessment";
import MenuModalRequestAccount from "@/components/modalRequestAccount/ModalRequestAccount";
import ModalPrivacy from "@/components/mods/modalPrivacy/ModalPrivacy";
import MenuModalPaymentResult from "@/components/menuModalPaymentResult/MenuModalPaymentResult";
import MenuCategory from "@/components/menuCategory/MenuCategory";
import {useI18n} from "vue-i18n";
import {changeLocale, getLanguageName, i18n} from "@/i18n";
import {ref} from "vue";
import MenuModalSelectedNameInvitation
	from "@/components/menuModalSelectedNameInvitation/MenuModalSelectedNameInvitation";
import MenuCartDailyMenuProduct from "@/components/menuCartProduct/MenuCartDailyMenuProduct";
import ModalMessageProducts from "@/components/mods/modalMessageProducts/ModalMessageProducts";

export default {
	name: 'MenuWelcom',
	data() {
		return {
			webSocketClient: WebSocketClient,
			activeMenu: null,
			cartClientMessage: '',
			messageCart: {
				clientName: '',
				products: []
			},
			reservationDate: '',
			reservationTime: '',
			reservationInfo: {
				isRealReservation: false,
				pax: null,
				id: null,
				promotion: null,
			},
			isLoad: false,
			isLoading: true,
			idQr: '',

			menuButtonContentOrder: this.t('menu.orderSendButton'),
			isSpinnerIconLoading: false,
			sendCartButtonDisabled: false,
			isRequestAccount: false,
			isModalOpen: null,
			isTypeFood: true,
			menuCategories: [],
			isCreator: false,
			invitedUsers: [],
			copilotProducts: null,
			viewMode: 'unified',

			showLeftArrow: true,
			showRightArrow: true,

			isActiveProductInputs: false,
		};
	},
	setup() {
		const { t, locale } = useI18n(); // Extract the translation function `t` and the reactive `locale`
		const availableLocales = store.getters['venue/locales']; // Available locales from `i18n.js`
		const selectedLocale = ref(locale.value); // Make `selectedLocale` reactive

		// Method to change the locale
		const handleChangeLocale = (newLocale) => {
			changeLocale(i18n, newLocale);
			locale.value = newLocale; // Update `locale` directly
			selectedLocale.value = newLocale;
		};

		return {
			t,
			locale,
			availableLocales,
			selectedLocale,
			handleChangeLocale,
			getLanguageName,
		};
	},
	inject: {
		notificationRepository: 'notificationRepository',
		cartSessionRepository: 'cartSessionRepository',
		userRepository: 'userRepository',
		venueRepository: 'venueRepository',
		menuRepository: 'menuRepository',
		cookieManager: 'cookieManager',
		reservationRepository: 'reservationRepository',
		dateFormatterService: 'dateFormatterService',
	},
	computed: {
		...mapGetters('config', ['websocketUrl', 'websocketPort', 'weWelcomUrl']),
		...mapGetters('user', ['user']),
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName', 'weOrderClientComments']),
		...mapGetters('menu', ['menusVenue', 'productsCart', 'dishClasses', 'menus', 'isOpenModalSearch']),
		groupedProducts() {
			console.log('estamos en el grouped');
			const groups = new Map();

			// Agrupamos los productos
			this.productsCart.forEach(product => {
				const currentDishClass = product.modifyDishClass ?? product.dishClass;
				const dishClassName = currentDishClass?.name || 'Otros';

				if (!groups.has(dishClassName)) {
					groups.set(dishClassName, {
						sortOrder: currentDishClass?.sortOrder ?? Number.MAX_VALUE, // Por si no hay sortOrder, asignar un valor alto.
						products: []
					});
				}

				groups.get(dishClassName).products.push(product);
			});

			// Convertimos a objeto y ordenamos según sortOrder
			const sortedGroups = Array.from(groups.entries())
				.sort((a, b) => a[1].sortOrder - b[1].sortOrder) // Ordenar los grupos por sortOrder
				.map(([key, value]) => [key, value.products]); // Extraer solo los productos

			return Object.fromEntries(sortedGroups);
		},

		isProductUpdateDishClass() {
			return this.productsCart.some(product => {
				const switchableClasses = product.dishClass?.switchableClasses;

				// Regla 1 y 2: Evaluar si es un array y si está vacío
				if (Array.isArray(switchableClasses)) {
					return switchableClasses.length > 0; // True si tiene elementos
				}

				// Regla 3: Evaluar si es un objeto con claves
				if (switchableClasses && typeof switchableClasses === 'object') {
					return Object.keys(switchableClasses).length > 0; // True si tiene datos
				}

				// Regla 4: Si dishClass es null, devolver false
				return false;
			});
		},

		totalProducts() {
			return this.productsCart.reduce((acumulator, product) => {
				return acumulator + product.cartItemCount;
			}, 0);
		},
		linkShareMenuWhatsapp() {
			if (this.reservationInfo && this.user) {
				return this.urlVenue + `/world/api/reservation/whatsappmenulink/${this.reservationInfo.id}/${this.user.id}`;
			} else {
				return ''
			}
		},
		linkShareMenuQr() {
			if (this.reservationInfo && this.user) {
				return this.urlVenue + `/world/api/reservation/qrmenulink/${this.reservationInfo.id}/${this.user.id}`;
			} else {
				return ''
			}
		},
		isValidReservation() {
			console.log('cambia la reserva', this.reservationInfo.finishedDateTime)
			return this.reservationInfo && !this.reservationInfo.finishedDateTime && this.reservationInfo.status === 1 && this.reservationInfo.menuAccessEnabled === 1
		},
		isCopilotRoute() {
			return this.$route.path.includes('/copilot')
		},

		backdropAccount() {
			return !this.isCopilotRoute;
		},
		matchingProductUnits() {
			return (product) => {
				if (!this.product || !this.product.sku) {
					console.error('Product or product SKU is not defined');
					return {cartItemCount: 0};
				}
				return product.find(unit => unit.sku === this.product.sku) || {cartItemCount: 0};
			};
		},
		// recommendedProducts() {
		// 	const topChoiceProducts = [];
		//
		// 	this.menus.forEach(menu => {
		// 		if (Array.isArray(menu.menuCategories)) {
		// 			menu.menuCategories.forEach(category => {
		// 				if (Array.isArray(category.products)) {
		// 					category.products.forEach(product => {
		// 						if (product.topChoice === "SI") {
		// 							topChoiceProducts.push(product);
		// 						}
		// 					});
		// 				}
		// 			});
		// 		}
		// 	});
		//
		// 	return topChoiceProducts;
		// },
		recommendedProducts() {
			const topChoiceProducts = [];

			// Función recursiva para buscar productos con topChoice en una categoría
			const findTopChoiceProducts = (category) => {
				// Buscar productos que cumplan la condición en la categoría actual
				if (Array.isArray(category.products)) {
					category.products.forEach(product => {
						if (product.topChoice === "SI") {
							topChoiceProducts.push(product);
						}
					});
				}

				// Procesar recursivamente cada subcategoría
				if (Array.isArray(category.menuCategories)) {
					category.menuCategories.forEach(subCategory => {
						findTopChoiceProducts(subCategory);
					});
				}

				// Procesar recursivamente cada categoría de children
				if (Array.isArray(category.children)) {
					category.children.forEach(childCategory => {
						findTopChoiceProducts(childCategory);
					});
				}
			};

			// Recorrer cada menú y buscar productos en todas sus categorías
			this.menus.forEach(menu => {
				if (Array.isArray(menu.menuCategories)) {
					menu.menuCategories.forEach(category => {
						findTopChoiceProducts(category);
					});
				}
			});

			console.log(topChoiceProducts);

			return topChoiceProducts;
		},
		hasSubcategories() {
			return (
				(this.category.children && this.category.children.length > 0) ||
				(this.category.menuCategories && this.category.menuCategories.length > 0)
			);
		},
		// Combina todas las subcategorías en un solo arreglo
		subcategories() {
			return [
				...(this.category.children || []),
				...(this.category.menuCategories || [])
			];
		},
		filteredMenus() {
			const filterProducts = (products) => {
				return Array.isArray(products)
					? products.filter(product => product.menuCategoryProductInfo.availability !== -1)
					: [];
			};

			const filterCategoriesRecursively = (category) => {
				if (!category) return null; // Excluir si no hay categoría

				// Procesa hijos recursivamente y omite los que son nulos
				const filteredChildren = Array.isArray(category.children)
					? category.children
						.map(filterCategoriesRecursively)
						.filter(child => child !== null)
					: [];

				// Procesa subcategorías recursivamente y omite las que son nulas
				const filteredMenuCategories = Array.isArray(category.menuCategories)
					? category.menuCategories
						.map(filterCategoriesRecursively)
						.filter(subCategory => subCategory !== null)
					: [];

				// Filtra productos sin excluir recomendados
				const filteredProducts = filterProducts(category.products);

				// Si no hay subcategorías, submenús ni productos, excluye la categoría
				if (
					filteredChildren.length === 0 &&
					filteredMenuCategories.length === 0 &&
					filteredProducts.length === 0
				) {
					return null;
				}

				// Retorna la categoría con sus subcategorías y productos
				return {
					...category,
					children: filteredChildren,
					menuCategories: filteredMenuCategories,
					products: filteredProducts,
				};
			};

			return Array.isArray(this.menus)
				? this.menus
					.map(menu => ({
						...menu,
						menuCategories: Array.isArray(menu.menuCategories)
							? menu.menuCategories
								.map(filterCategoriesRecursively)
								.filter(category => category !== null)
							: [],
					}))
					.filter(menu => menu.menuCategories.length > 0)
				: [];
		},

		cartSessionOrders() {
			return this.reservationInfo.cartSession ? this.reservationInfo.cartSession.productOrders : [];
		},
		processedCartSessionOrders() {
			return this.cartSessionOrders.filter(order => order.cartSessionStatus > 0);
		},
		historicUnification() {
			const groupedItems = groupItemsBySkuAndChoices(this.processedCartSessionOrders);

			const arrayProducts = Object.values(groupedItems).map(item => ({
				productSku: item.productSku,
				name: item.name,
				quantity: item.count,
				category: item.category,
				pictureGeneral: item.pictureGeneral,
				pictureMhd: item.pictureMhd,
				type: item.type,
				choices: item.choices.map(choice => ({
					...choice,
					quantity: choice.quantity || 1
				})),
				choicesKey: item.choicesKey
			}));

			console.log('arrayProducts:', arrayProducts);

			return arrayProducts;
		},

	},
	components: {
		'menu-product-card': MenuProductCard,
		'menu-cart-product': MenuCartProduct,
		'nav-bottom': NavBottom,
		'modal-message': ModalMessage,
		'generator-qr': GeneratorQr,
		'navbar-top': NavBarTop,
		'footer-welcom': FooterWelcom,
		HeaderComponent,
		NavbarScrollCategories,
		MenuSearcher,
		SpinnerIcon,
		DismissButton,
		BagOrdersIcon,
		OrdersProductCard,
		ChefHatIcon,
		menuOffcanvasAssessment,
		MenuModalRequestAccount,
		ModalPrivacy,
		MenuModalPaymentResult,
		MenuCategory,
		MenuModalSelectedNameInvitation,
		MenuCartDailyMenuProduct,
		ModalMessageProducts
	},
	async beforeMount() {
		if (!this.user) {
			// this.$router.push('/reservar');
			console.log('no hay usuario en el menu')
		} else {
			this.webSocketClient = new WebSocketClient(this, this.websocketUrl, this.websocketPort);
			this.webSocketClient.connect(this.user.id);
		}

		const localStorageCart = localStorage.getItem('cart');
		if (localStorageCart) {
			store.commit('menu/setProductsCart', {productsCart: JSON.parse(localStorageCart)})
		}

		await this.loadInfoReservation();

		if (this.reservationInfo && this.reservationInfo.reservationUsers) {
			this.invitedUsers = this.reservationInfo.reservationUsers.filter(user => user.userId && user.type !== 1);
		} else {
			this.invitedUsers = []; // Asegurar que invitedUsers no es undefined
		}

		this.isCreator = this.isValidReservation ? this.reservationInfo.reservationUsers.filter(user => user.userId === this.user.id && user.type === 1).length > 0 : false;

		this.setupData();
	},
	async mounted() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
		this.activeMenu = this.menus;

		const localStorageCart = localStorage.getItem('cart');
		if (localStorageCart) {
			store.commit('menu/setProductsCart', {productsCart: JSON.parse(localStorageCart)})
		}

		this.$nextTick(() => {
			this.updateArrows();

			this.productsCart.forEach(product => {
				const element = document.getElementById(product.sku);
				if (element) {
					element.classList.add('addProduct');
				}
			});
		});


		// this.selectMenuCategories()

		this.isLoad = true;
	},
	watch: {
		productsCart: {
			handler(newCart, oldCart) {
				console.log('ha cambiaod el carrito desde el watch')
				const oldCartSkus = oldCart.map(product => product.sku);
				const newCartSkus = newCart.map(product => product.sku);

				// Add 'addProduct' class for new items in the cart
				newCart.forEach(product => {
					if (!oldCartSkus.includes(product.sku)) {
						const element = document.getElementById(product.sku);
						if (element) {
							element.classList.add('addProduct');
						}
					}
				});

				// Remove 'addProduct' class for items no longer in the cart
				oldCart.forEach(product => {
					if (!newCartSkus.includes(product.sku)) {
						const element = document.getElementById(product.sku);
						if (element) {
							element.classList.remove('addProduct');
						}
					}
				});

				// Update local storage with the new cart state
				localStorage.setItem('cart', JSON.stringify(newCart));
			},
			deep: true
		},
	},
	methods: {
		generateProductKey(product) {
			const chosenIds = product.chosenOptions
				? product.chosenOptions.flatMap(chosen => chosen.chosenIds).sort().join('-')
				: '';

			const dishClassId = product.dishClass ? product.dishClass.id : '';
			return `${product.sku}-${chosenIds}-${dishClassId}`;
		},
		productChosenOptionsIds(product) {
			if (!product.chosenOptions) {
				return '';
			}
			return product.chosenOptions.map(option => option.chosenIds).flat();
		},
		activateDishClassInputs() {
			this.isActiveProductInputs = true
		},
		dismissProductInputs() {
			this.isActiveProductInputs = false;
		},
		ncodeCredentials(username, password) {
			const credentials = `${username}:${password}`;
			return btoa(credentials);
		},
		removeQueryParams() {
			let url = window.location.href;
			const urlParts = url.split('?');

			if (urlParts.length >= 2) {
				url = urlParts[0];
				window.history.replaceState({}, document.title, url);
			}
		},
		async loadInfoReservation() {
			const values = window.location.search;
			const urlParams = new URLSearchParams(values);
			let reservationId = urlParams.get('r');

			const cookieReservationId = this.cookieManager.getReservationMenuId();

			if (!reservationId && cookieReservationId) {
				reservationId = cookieReservationId;
			}

			if (reservationId) {
				const reservationResponse = await this.reservationRepository.find(reservationId);
				const reservation = reservationResponse.reservation

				if (reservation && !reservation.finishedDateTime && reservation.status === 1 && reservation.menuAccessEnabled === 1) {
					this.reservationInfo = reservation;
					console.log('Es una reserva valida: ', this.reservationInfo)
					this.cookieManager.setReservationMenuId(this.reservationInfo.id);
				} else {
					if (reservation) {
						this.reservationInfo = reservation;
					}

					console.log('No es una reserva valida: ', this.reservationInfo);
					// this.cookieManager.clearReservationMenuId();
					localStorage.setItem('cart', JSON.stringify([]));
					this.productsCart = [];
					// window.location.href = this.urlVenue;
				}

			} else {
				console.log('No es una reserva real: ', this.reservationInfo)
				// this.cookieManager.clearReservationMenuId();
				localStorage.setItem('cart', JSON.stringify([]));
				this.productsCart = [];
				// this.$router.push('/reservar')
				console.log('no hay usuario en el menu')
			}

			this.removeQueryParams();
		},
		addProductToCart(product, units, options) {
			store.commit('menu/addProductToCart', {product, units, options})
		},
		addUnit(product) {
			store.commit('menu/addUnit', {product})
		},
		subtractUnit(product) {
			store.commit('menu/subtractUnit', {product})
		},

		async handleConfirmCart() {

			this.menuButtonContentOrder = `${this.t('menu.sendingCart')}...`;
			this.sendCartButtonDisabled = true;
			this.isSpinnerIconLoading = true;
			const orderInfo = {
				name: this.user.name,
				welKey: this.user.welKey,
				userId: this.user.id,
				pax: this.reservationInfo.pax,
				source: 1,
				clientMessage: this.cartClientMessage,
				orderReservationName: this.user.name,
				reservationId: this.reservationInfo.id,
				av: 0,
			}


			const products = this.productsCart.flatMap(product => {
				if (product.isUnitary === "SI") {
					return {
						sku: product.sku,
						options: product.chosenOptions,
						units: product.cartItemCount,
						modifyDishClass: product.modifyDishClass?.id,
						isShareable: product.isShareable
					}
				} else {
					return Array(product.cartItemCount).fill({
						sku: product.sku,
						options: product.chosenOptions,
						modifyDishClass: product.modifyDishClass?.id,
						isShareable: product.isShareable
					});
				}
			});

			const resultOrder = await this.cartSessionRepository.createCartSessionOrder(orderInfo, products);
			this.isRequestAccount = false;
			console.log(resultOrder)
			if (resultOrder.result === 0) {
				this.menuButtonContentOrder = `${this.t('menu.sendingCart')}...`;
				this.isSpinnerIconLoading = true;

				const modal = document.getElementById('orders');
				const modalOrders = bootstrap.Modal.getInstance(modal);
				modalOrders.hide();

				this.menuButtonContentOrder = this.t('menu.orderSendButton');
				this.isSpinnerIconLoading = false;

				this.messageCart = {clientName: this.user.name, products: this.productsCart};
				showModal('modal-message-products')
				// const modalCheckInbox = new bootstrap.Modal(document.getElementById('messageModal'), {
				// 	backdrop: true,
				// });
				// modalCheckInbox.show();
				store.commit('menu/setProductsCart', {productsCart: []})
				this.cartClientMessage = '';
				this.isActiveProductInputs = false
				await this.loadInfoReservation()
			}

			if (resultOrder.result === -2){
				store.commit('menu/setProductsCart', {productsCart: []});
				store.commit("copilot/setToast", {toast:{toastMessage: 'La reserva ha finalizado.', showToast:true, idToast: "incorrectlyToast"}});
				await this.loadInfoReservation();
				this.menuButtonContentOrder = this.t('menu.orderSendButton');
				this.isSpinnerIconLoading = false;
			}

			this.sendCartButtonDisabled = false;

		},

		setupData() {
			const dateTime = new Date(this.reservationInfo.startDateTime);

			const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
			const nameDay = daysOfWeek[dateTime.getDay()];
			const translator = {
				Sunday: "Dom.",
				Monday: "Lun.",
				Tuesday: "Mar.",
				Wednesday: "Mié.",
				Thursday: "Jue.",
				Friday: "Vie.",
				Saturday: "Sáb.",
			};
			const nameDayTranslated = translator[nameDay];

			const months = {
				Jan: 'Ene',
				Feb: 'Feb',
				Mar: 'Mar',
				Apr: 'Abr',
				May: 'May',
				Jun: 'Jun',
				Jul: 'Jul',
				Aug: 'Ago',
				Sep: 'Sep',
				Oct: 'Oct',
				Nov: 'Nov',
				Dec: 'Dic'
			};
			const month = months[dateTime.toLocaleString('en', {month: 'short'})];
			this.reservationDate = `${nameDayTranslated} ${dateTime.getDate()} ${month}`;

			const hours = dateTime.getHours();
			const minutes = dateTime.getMinutes();
			this.reservationTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
		},

		openModalNewUserName(){
			showModal('modal-selected-name-invitation')
		},

		shouldRemoveProductCart(productCart, product) {
			if (productCart.sku !== product.sku) {
				return false;
			}
			return arraysAreEqualIgnoringOrder(productCart.chosenOptions, product.chosenOptions);
		},

		groupedItems(order) {
			return groupItemsBySkuAndChoices([order]);
		},

		scrollLeft() {
			this.scroll('left');
		},
		scrollRight() {
			this.scroll('right');
		},
		scroll(direction) {
			const scrollContainer = this.$refs.scrollContainer;
			const cardWidth = scrollContainer.querySelector('.product-card-client-vertical').offsetWidth;
			if (direction === 'left') {
				scrollContainer.scrollBy({left: -cardWidth, behavior: 'smooth'});
			} else if (direction === 'right') {
				scrollContainer.scrollBy({left: cardWidth, behavior: 'smooth'});
			}
			this.updateArrows();
		},
		updateArrows() {
			const scrollContainer = this.$refs.scrollContainer;
			if (!scrollContainer) return;  // Prevent error if ref is not defined
			// const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;
			// this.showLeftArrow = scrollContainer.scrollLeft > 0;
			// this.showRightArrow = scrollContainer.scrollLeft < maxScrollLeft;
		},
	},
}
</script>
<style>
.scroll-arrow-welcom {
	position: absolute;
	bottom: 0;
	color: var(--bs-white);
	border: none;
	padding: 10px;
	cursor: pointer;
	z-index: 1;
	height: 120%;
	font-size: 1.2rem;
	background: transparent;
}

.left-arrow {
	left: 0;
}

.right-arrow {
	right: 0;
}
</style>
