<template>
	<div class="copilot-reservation-kitchen hotTable" style="z-index: 4">
		<div class="reservation-kitchen-content scroll-x" style="height: 3.5rem;">
			<CopilotElaborationRoleHotTableProductCard
				v-for="product in hotTable"
				:key="product.productOrderId"
				source="hotTable"
				:product="product"/>
		</div>
		<div class="reservation-wrapper-kitchen-data ">
			<button :disabled="hotTable.length === 0" @click="handleProductOrdersReady" class="btn btn-secondary position-relative btn-welcom-sm h-100" style="width: 6rem;line-height:1rem;">
				<service-bell-icon fill-color="black" v-if="!isLoadingMarkToReady"/><span v-else><SpinnerIcon/></span>
				<span v-if="!isLoadingMarkToReady">
					<span v-if="!selectedProductOrdersToServe.length">Enviar Todos</span>
					<span v-else>Enviar <span class="badge badge-primary">{{selectedProductOrdersToServe.length}}</span></span>
				</span>
			</button>
		</div>
	</div>

	<copilot-reservation-kitchen :reservation="reservation" v-for="reservation in sortedReservationsWithProducts" :key="reservation.id"></copilot-reservation-kitchen>

	<div class="copilot-reservation-kitchen hotTable position-fixed bottom-0" style="top: auto">
		<div class="reservation-wrapper-kitchen-data ">
			<button @click="handleServeProductOrders()" :class="{'disabled': !serviceEventPendingService.length}" class="btn btn-secondary ps-1 h-100 btn-welcom-sm position-relative" style="width: 6rem;line-height:1rem;">
				<ServeFoodIcon :fill-color="'black'" v-if="!isRequestInLoading"/> <SpinnerIcon v-else/>
				<span v-if="!isRequestInLoading">
					<span v-if="!selectedProductOrdersToClient.length">LLevar Todos</span>
					<span v-else>LLevar <span class="badge badge-primary">{{selectedProductOrdersToClient.length}}</span></span>
				</span>
			</button>
		</div>
		<div class="reservation-kitchen-content scroll-x ps-2" v-if="serviceEventPendingService && serviceEventPendingService.length > 0" style="height: 3.5rem">
			<template v-for="order in unifiedProducts">
				<CopilotElaborationRoleHybridTableProductCard
					v-for="product in order.products" :key="product.productOrderId"
					source="hotTable"
					:product="product"/>
			</template>
		</div>

		<div v-if="false" class="reservation-kitchen-content scroll-x" style="height: 3.5rem">
			<div v-for="(productOrder, index) in pendingProductOrdersFromServiceEvents" :key="'pendingProductOrder-' + index" >
				<span style="color: black" v-if="productOrder.serviceEventId">
					productOrder.serviceEventTimeCreated.date
				</span>
				<span style="color: black" v-else>
					{{ productOrder.name }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>

import {mapGetters} from "vuex";
import store from "@/store";
import SpinnerIcon from "@/core/icons/SpinnerIcon";
import CopilotReservationKitchen from "@/components/CopilotReservationKitchen/CopilotReservationKitchen";
import ServeFoodIcon from "@/core/icons/ServeFoodIcon";
import CopilotElaborationRoleHotTableProductCard from "@/components/copilotElaborationRoleHotTableProductCard/CopilotElaborationRoleHotTableProductCard";
import CopilotElaborationRoleHybridTableProductCard
	from "@/components/copilotElaborationRoleHybridTableProductCard/CopilotElaborationRoleHybridTableProductCard";
import ServiceBellIcon from "@/core/icons/serviceBellIcon";

export default {
	name: 'CopilotContainerKitchen',
	inject: {
		productOrderRepository: 'productOrderRepository',
	},
	components: {
		ServiceBellIcon,
		CopilotElaborationRoleHybridTableProductCard,
		CopilotElaborationRoleHotTableProductCard,
		ServeFoodIcon, CopilotReservationKitchen, SpinnerIcon},
	data() {
		return {
			isLoadingMarkToReady: false,

			isRequestInLoading: false,
		}
	},
	mounted() {
		console.log('Los cartSessionOrder con comentarios', this.reservationsWithComments)
		console.log( this.serviceEventPendingService);
		console.log(this.pendingProductOrdersFromServiceEvents);
	},
	beforeUnmount() {
		store.commit('copilot/clearHotTable');
	},
	computed: {
		...mapGetters('copilot', ['reservationsWithProducts','selectedProductOrdersToServe','selectedProductOrdersToClient',"isKitchenSelectedRole", 'allOrderProducts', 'hotTable', 'selectedRole', 'getSelectedRole', 'serviceEventPendingService', 'selectedProductOrdersToServe', 'reservationsWithComments']),
		...mapGetters('venue', ['copilotMultipleServiceEvent']),
		buttonTextOrder() {
			if (this.serviceEventIsDrinkOrDish === 'Cocina') {
				return this.selectedProductOrdersToServe.length === 0 ? 'Llevar a Cliente' : ``
			} else {
				return this.selectedProductOrdersToServe.length === 0 ? 'Llevar a Cliente' : ``
			}
		},
		sortedReservationsWithProducts() {
			return this.reservationsWithProducts
				.filter(reservation => !reservation.finishedDateTime)
				.slice()
				.sort((a, b) => {
					// Primero las reservas que tienen productos con dateFired y sin dateReady
					const aHasProductsWithoutDateReadyAndWithDateFired = a.orderedItems.some(item => item.dateFired && !item.dateReady);
					const bHasProductsWithoutDateReadyAndWithDateFired = b.orderedItems.some(item => item.dateFired && !item.dateReady);

					if (aHasProductsWithoutDateReadyAndWithDateFired && !bHasProductsWithoutDateReadyAndWithDateFired) {
						return -1;
					}
					if (!aHasProductsWithoutDateReadyAndWithDateFired && bHasProductsWithoutDateReadyAndWithDateFired) {
						return 1;
					}

					// Si ambas tienen productos con dateFired y sin dateReady, ordenar por el que lleva más tiempo con dateFired (considerando hora y minuto)
					if (aHasProductsWithoutDateReadyAndWithDateFired && bHasProductsWithoutDateReadyAndWithDateFired) {
						const aOldestDateFired = Math.min(...a.orderedItems.filter(item => item.dateFired && !item.dateReady).map(item => new Date(item.dateFired).getTime()));
						const bOldestDateFired = Math.min(...b.orderedItems.filter(item => item.dateFired && !item.dateReady).map(item => new Date(item.dateFired).getTime()));

						if (aOldestDateFired < bOldestDateFired) return -1;
						if (aOldestDateFired > bOldestDateFired) return 1;
					}

					// Si ambas tienen o no tienen productos con dateFired y sin dateReady, se usa la prioridad existente
					const aPriority = this.getReservationPriority(a);
					const bPriority = this.getReservationPriority(b);

					// Ordenar primero por sortOrder (menor sortOrder primero)
					if (aPriority.sortOrder < bPriority.sortOrder) return -1;
					if (aPriority.sortOrder > bPriority.sortOrder) return 1;

					// Si sortOrder es el mismo, ordenar por earliestCreatedDateTime (el más antiguo primero)
					if (aPriority.earliestCreatedDateTime < bPriority.earliestCreatedDateTime) return -1;
					if (aPriority.earliestCreatedDateTime > bPriority.earliestCreatedDateTime) return 1;

					return 0;
				});
		},

		unifiedProducts() {
			const groupedByServiceEvent = [];

			let selectedRole = this.getSelectedRole;

			// Define the service events to process
			const serviceEvents = this.copilotMultipleServiceEvent ? this.serviceEventPendingService : [this.serviceEventPendingService[0]];

			// Loop through each service event
			serviceEvents.forEach(serviceEvent => {
				// Filter product orders where statusProductOrder < 100
				console.log("Comprobando si el role del producto es "+this.getSelectedRole.name)
				const items = serviceEvent.items.filter(productOrder => productOrder.statusProductOrder < 100 && productOrder.requirementProductCopilotRoles.some(role => role.name === selectedRole.name));

				if (items.length > 0) {
					const groupedProducts = {};

					// Group products by reservationId
					items.forEach(item => {
						const reservationId = item.reservationInfo.reservationId;

						if (!groupedProducts[reservationId]) {
							groupedProducts[reservationId] = {
								reservationInfo: item.reservationInfo,
								products: []
							};
						}
						groupedProducts[reservationId].products.push(item);
					});

					// Add the grouped products for this service event
					groupedByServiceEvent.push(...Object.values(groupedProducts).map(group => ({
						reservationInfo: group.reservationInfo,
						products: group.products
					})));
				}
			});

			// Return the array grouped by reservation, ordered by serviceEvent
			return groupedByServiceEvent;
		},
		pendingProductOrdersFromServiceEvents(){
			return this.serviceEventPendingService.flatMap(event => {
				const separator = {
					serviceEventId: event.serviceEventId,
					serviceEventStatus: event.serviceEventStatus,
					serviceEventTimeCreated: event.serviceEventTimeCreated
				};

				return [separator, ...event.items];
			});
		}
	},
	methods: {
		async handleProductOrdersReady() {
			this.isLoadingMarkToReady = true;

			const selectedProductOrders = store.getters['copilot/selectedProductOrdersToServe'];

			// If no products are selected, process all products from the hot table
			const data = selectedProductOrders.length > 0
				? selectedProductOrders
				: this.hotTable.map(product => product.productOrderId);

			const resultFinishPreparation = await this.productOrderRepository.finishPreparation({
				productOrderIds: data,
				roleId: this.selectedRole
			});
			console.log(resultFinishPreparation)
			if (resultFinishPreparation.result === 0) {
				// store.commit("copilot/setToast", {
				// 	toast: {
				// 		toastMessage: `Has marcado el pedido como listo.`,
				// 		showToast: true,
				// 		idToast: "correctlyToast"
				// 	}
				// })
				store.commit('copilot/clearSelectedProductOrdersToServe');
				store.commit('copilot/clearHotTable');
			} else {
				// store.commit("copilot/setToast", {
				// 	toast: {
				// 		toastMessage: `Ha habido un error marcando el pedido como listo.`,
				// 		showToast: true,
				// 		idToast: "incorrectlyToast"
				// 	}
				// });
			}

			this.isLoadingMarkToReady = false;

		},
		getReservationPriority(reservation) {
			if (!reservation.orderedItems || reservation.orderedItems.length === 0) {
				return {sortOrder: Number.MAX_SAFE_INTEGER, earliestCreatedDateTime: Number.MAX_SAFE_INTEGER};
			}

			// Filtrar productos con statusProductOrder igual a 1 o 2
			const orderedProducts = reservation.orderedItems.filter(item => item.statusProductOrder === 1 || item.statusProductOrder === 2);

			if (orderedProducts.length === 0) {
				return {sortOrder: Number.MAX_SAFE_INTEGER, earliestCreatedDateTime: Number.MAX_SAFE_INTEGER};
			}

			// Obtener el sortOrder más bajo de dishClass entre los productos
			const minSortOrder = Math.min(...orderedProducts.map(item => item.dishClass?.sortOrder || Number.MAX_SAFE_INTEGER));

			// Obtener la hora de creación más antigua entre los productos con el mismo sortOrder
			const earliestCreatedDateTime = Math.min(...orderedProducts
				.filter(item => item.dishClass?.sortOrder === minSortOrder)  // Solo considerar productos con el sortOrder mínimo
				.map(item => new Date(item.createdDateTime).getTime()));

			return {
				sortOrder: minSortOrder,
				earliestCreatedDateTime: earliestCreatedDateTime
			};
		},

		async handleServeProductOrders() {
			console.log('Vamos a procesar los pedidos como servidos');
			this.isRequestInLoading = true;
			console.log("Este es el serviceEvent", this.serviceEventPendingService[0])

			if (this.copilotMultipleServiceEvent){

				// Retrieve selected product orders from Vuex store
				const selectedProductOrders = this.$store.getters['copilot/selectedProductOrdersToClient'];

				// Check if any products are selected
				const data = selectedProductOrders.length > 0
					? selectedProductOrders
					: this.serviceEventPendingService.flatMap(serviceEvent =>
						serviceEvent.items.filter(productOrder => productOrder.statusProductOrder === 3).map(productOrder => productOrder.productOrderId)
					);

				console.log("Product order data to process:", data);

				const serviceEventId = this.serviceEventPendingService[0].serviceEventId; // Assuming you're processing the first service event for now
				const resultServeProductOrders = await this.productOrderRepository.serveProductOrders({
					productOrderIds: data,
					serviceEventId: serviceEventId
				});
				console.log(resultServeProductOrders);

				// Clear selected products after serving
				store.commit('copilot/clearSelectedProductOrdersToClient');

			} else {

				if (!this.selectedProductOrdersToServe.length) {
					const allProductsOrders = this.serviceEventPendingService[0].items.filter(productOrder => productOrder.statusProductOrder === 3).map(productOrder => productOrder.productOrderId);
					console.log(allProductsOrders);
					const data = {
						productOrderIds: allProductsOrders,
						serviceEventId: this.serviceEventPendingService[0].serviceEventId,
					};
					const resultServeProductOrders = await this.productOrderRepository.serveProductOrders(data);
					console.log(resultServeProductOrders)
				} else {
					const data = {
						productOrderIds: this.selectedProductOrdersToServe,
						serviceEventId: this.serviceEventPendingService[0].serviceEventId,
					};
					const resultServeProductOrders = await this.productOrderRepository.serveProductOrders(data);
					console.log(resultServeProductOrders);
					store.commit('copilot/setSelectedProductOrdersToServe', {selectedProductOrdersToServe: []});
				}

			}

			this.isRequestInLoading = false;
		},
	}
}
</script>

