<template>
	<div :id="id" aria-hidden="true" class="offcanvas offcanvas-bottom fade" :aria-labelledby="`${id}-label`" tabindex="-1">
		<div class="offcanvas-header mb-0">
			<div class="offcanvas-title h4 mb-0" :id="`${id}-label`">
				<slot name="icon"></slot>

				{{ title }}
			</div>
			<button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
		</div>
		<div class="offcanvas-body p-3">

			<slot></slot>

		</div>

		<div class="offcanvas-footer">
			<DismissButton :is-modal="false" :content-btn-text="textBtnClose"/>
		</div>
	</div>
</template>

<script>
import DismissButton from "@/core/DismissButton";
export default {
	name: "OffcanvasBottomWrapper",
	components: {DismissButton},
	props:{
		id: {
			type: String,
			required: true
		},
		title: {
			type: String,
			required: true
		},
		textBtnClose: {
			type: String,
		}
	},
}
</script>
