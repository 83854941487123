<template>
	<div class="toast-container position-fixed end-0 p-3" style="top: 60px;">
		<div id="correctlyToast" class="toast align-items-center toast-success" role="alert" aria-live="assertive" aria-atomic="true">
			<div class="d-flex">
				<div class="toast-body">
					{{toast.toastMessage}}
				</div>
				<button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
			</div>
		</div>
		<div id="incorrectlyToast" class="toast align-items-center toast-danger" role="alert" aria-live="assertive" aria-atomic="true">
			<div class="d-flex">
				<div class="toast-body">
					{{toast.toastMessage}}
				</div>
				<button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
			</div>
		</div>
	</div>
</template>

<script>
import * as bootstrap from "bootstrap";
import {mapGetters} from "vuex";
import {hideAllModals} from "@/utils";
import store from "@/store";

export default {
	name: 'ToastWelcom',
	emits: [],
	computed: {
		...mapGetters('copilot', ["toast"]),
	},
	mounted() {

	},
	beforeUnmount() {

	},
	watch: {
		toast(newValue){
			if(newValue.showToast === true){
				this.showToast();
				hideAllModals();
				store.commit('copilot/setIsShowToastScanQr',{isShowToastScanQr: null})
			}
			if(newValue === false){
				this.showToast('scannerIncorrectly');
				store.commit('copilot/setIsShowToastScanQr',{isShowToastScanQr: null})
			}
		}
	},
	methods: {
		showToast() {
			const toastElement = document.getElementById(this.toast.idToast);
			const toastInstance = bootstrap.Toast.getOrCreateInstance(toastElement);
			toastInstance.show();
		}
	}
}
</script>
