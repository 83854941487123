<template>
	<div class="modal fade" v-if="reservationUserUid" id="view-details-user" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4">
						{{ t('copilot.modalUserDetails.title') }} {{ reservationUserUid.name }}
					</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="container p-0">
						<div class="row g-0">
							<div class="col-6">
								<div class="fw-bold">{{ t('copilot.modalUserDetails.userName') }}</div>
								<p v-if="reservationUserUid.name">{{reservationUserUid.name}}</p><p v-else>{{ t('copilot.modalUserDetails.notInformation') }}</p>
							</div>
							<div class="col-6">
								<div class="fw-bold">{{ t('copilot.modalUserDetails.userSurName') }}</div>
								<p v-if="reservationUserUid.surname">{{reservationUserUid.surname}}</p><p v-else>{{ t('copilot.modalUserDetails.notInformation') }}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-md-6">
								<div class="fw-bold">{{ t('copilot.modalUserDetails.userEmail') }}</div>
								<p v-if="reservationUserUid.email">{{reservationUserUid.email}}</p><p v-else>{{ t('copilot.modalUserDetails.notInformation') }}</p>
							</div>
							<div class="col-12 col-md-6">
								<div class="fw-bold">{{ t('copilot.modalUserDetails.userPhone') }}</div>
								<p v-if="reservationUserUid.hostPhone">{{reservationUserUid.hostPhone}}</p><p v-else>{{ t('copilot.modalUserDetails.notInformation') }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<DismissButton />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import DismissButton from "@/core/DismissButton";
import {useI18n} from "vue-i18n";

export default {
	name: "CopilotDetailsUserReservation",
	components: {DismissButton},
	inject: {
		userRepository: 'userRepository',
	},
	data() {
		return {
		}
	},
	setup() {
		const { t } = useI18n();

		return { t };
	},
	mounted() {

		console.log('desde el componente',this.reservationUserUid)
	},
	computed: {
		...mapGetters("copilot", ['reservationUserUid']),
	},
	methods:{

	}
}
</script>

<style scoped>

</style>
