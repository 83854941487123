<template>
	<div class="modal fade" v-if="order" :id="'modal-confirm-dish-class-changed-' + order.name + '-' + order.productOrderId" tabindex="-1" :aria-labelledby="'modal-confirm-dish-class-changed-label' + order.name + order.productOrderId" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4" :id="'modal-confirm-dish-class-changed-label' + order.name + order.productOrderId">
					{{order.name}}
					</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
							<p>¿Estás seguro de que quieres mover {{order.name}} a los {{ textModal }}?</p>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<DismissButton content-btn-text="Cancelar" :on-click="handleCheckBoxClose"/>

					<button :disabled="isLoading" @click="confirmMoveProduct(textModal)" class="btn btn-success">
						<span v-if="!isLoading">{{textBtn}}</span><span v-else> Actualizando...<SpinnerIcon/> </span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import DismissButton from "@/core/DismissButton";
import store from "@/store";
import {hideAllModals} from "@/utils";
import SpinnerIcon from "@/core/icons/SpinnerIcon";

export default {
	name: "ModalConfirmDishClassChanged",
	inject:{
		copilotRepository: 'copilotRepository'
	},
	components: {
		SpinnerIcon,
		DismissButton
	},
	props: {
	},
	data() {
		return {
			textBtn: 'Mover orden',
			isLoading: false
		}
	},
	computed:{
		...mapGetters('copilot',['productOrder']),
		...mapGetters('menu', ['dishClasses']),

		order() {
			return this.productOrder
		},
		textModal() {
			return this.productOrder.dishClassHasChanged ? 'Segundos' : 'Primeros'
		},
		activeCheckboxDishClass() {
			return this.productOrder.dishClassHasChanged
		},

	},
	watch: {

	},
	beforeMount() {
	},
	mounted() {
	},
	methods: {
		async confirmMoveProduct(position) {
			if (position === 'Primeros') {
				this.isLoading = true;
				const dishClassId = this.dishClasses.find(dishClass =>
					dishClass.name === 'Primeros'
				);

				console.log(dishClassId)
				const result = await this.copilotRepository.updateProductorderDishClass({
					productOrderId: this.order.productOrderId,
					dishClassId: dishClassId.id,
					dishClassHasChanged: true,
				})
				console.log(result);

				if (result.result === 0) {
					this.isLoading = false;
					hideAllModals();
				} else {
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: 'Ha ocurrido un error actualizado el producto.',
							showToast: true,
							idToast: "incorrectlyToast"
						}
					});
				}
			} else {
				this.isLoading = true;
				const dishClassId = this.dishClasses.find(dishClass =>
					dishClass.name === 'Segundos'
				);
				console.log(dishClassId)
				const result = await this.copilotRepository.updateProductorderDishClass({
					productOrderId: this.order.productOrderId,
					dishClassId: dishClassId.id,
					dishClassHasChanged: false,
				})
				console.log(result);

				if (result.result === 0) {
					this.isLoading = false;
					hideAllModals();
				} else {
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: 'Ha ocurrido un error actualizado el producto.',
							showToast: true,
							idToast: "incorrectlyToast"
						}
					});
				}
			}
		},

		handleCheckBoxClose(){
			if (this.order.dishClassHasChanged) {
				this.activeCheckboxDishClass = this.order.dishClassHasChanged
			} else {
				this.activeCheckboxDishClass = false
			}
		},
	}
}
</script>

<style scoped>

</style>
