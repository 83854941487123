import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import store from "@/store";

const datetimeFormats = {
	'es-ES': {
		short: {
			year: 'numeric', month: 'short', day: 'numeric'
		},
		long: {
			year: 'numeric', month: 'short', day: 'numeric',
			weekday: 'short', hour: 'numeric', minute: 'numeric'
		}
	},
	'en-US': {
		short: {
			year: 'numeric', month: 'short', day: 'numeric'
		},
		long: {
			year: 'numeric', month: 'short', day: 'numeric',
			weekday: 'short', hour: 'numeric', minute: 'numeric'
		}
	},
	'ja-JP': {
		short: {
			year: 'numeric', month: 'short', day: 'numeric'
		},
		long: {
			year: 'numeric', month: 'short', day: 'numeric',
			weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
		}
	}
}

const numberFormats = {
	'es-ES': {
		currency: {
			style: 'currency', currency: 'EUR', notation: 'standard'
		},
		decimal: {
			style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
		},
		percent: {
			style: 'percent', useGrouping: false
		}
	},'en-US': {
		currency: {
			style: 'currency', currency: 'USD', notation: 'standard'
		},
		decimal: {
			style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
		},
		percent: {
			style: 'percent', useGrouping: false
		}
	},
	'ja-JP': {
		currency: {
			style: 'currency', currency: 'JPY', useGrouping: true, currencyDisplay: 'symbol'
		},
		decimal: {
			style: 'decimal', minimumSignificantDigits: 3, maximumSignificantDigits: 5
		},
		percent: {
			style: 'percent', useGrouping: false
		}
	}
}

export const i18n = setupI18n({
	legacy: false,
	locale: 'es',
	fallbackLocale: 'es',
	datetimeFormats,
	numberFormats,
	messages: {
		es: require('./locales/es.json'), // Load the default language
	},
	missing(locale, key) {
		console.warn(`Missing translation for key "${key}" in locale "${locale}"`);
	},
	globalInjection: true,
})

export function setupI18n(options = { locale: 'es' }) {
	const i18n = createI18n(options)
	setI18nLanguage(i18n, options.locale)
	return i18n
}

export function setI18nLanguage(i18n, locale) {
	if (i18n.mode === 'legacy') {
		i18n.global.locale = locale
	} else {
		i18n.global.locale.value = locale
	}
	/**
	 * NOTE:
	 * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
	 * The following is an example for axios.
	 *
	 * axios.defaults.headers.common['Accept-Language'] = locale
	 */
	document.querySelector('html').setAttribute('lang', locale)
}

export function getLanguageName(locale) {
	const languageNames = {
		es: "Español",
		en: "English",
		de: "Deutsche",
		fr: "Français",
		eu: "Euskara",
		ca: "Catalá",
		it: "Italiano",
		gl: "Galego",
		pt: "Portugês"
	};
	return languageNames[locale] || locale;
}

export async function changeLocale(i18n, newLocale) {
	//await store.dispatch('config/showLoadingScreen');

	const venueId = store.getters['venue/venueId'];
	const venueName = store.getters['venue/businessName'];

	console.log("Cambiando locale a "+newLocale);

	await store.dispatch('fetchTranslations', {
		locale: newLocale,
		venueId,
		venueName,
		keys: [] // Fetch all necessary keys for the given locale
	});

	// Load new locale messages before setting the locale
	await loadLocaleMessages(i18n, newLocale);

	// Set the language
	setI18nLanguage(i18n, newLocale);

	// Log the merged messages for the given locale
	//console.log('Merged translations for locale:', newLocale);
	//console.log(i18n.global.getLocaleMessage(newLocale));

	store.commit('copilot/setSelectedLocale', {selectedLocale: newLocale});

	//await store.dispatch('config/hideLoadingScreen');
}

export async function loadLocaleMessages(i18n, locale) {
	// Load locale messages with dynamic import
	const messagesFromJson = await import(
		/* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
		);

	// Get the current locale messages
	const existingMessages = i18n.global.getLocaleMessage(locale) || {};
	//console.log("Existing messages", existingMessages)

	// Merge the loaded messages from the JSON file with the existing messages
	const mergedMessages = {
		...existingMessages,
		...messagesFromJson.default,
	};

	//console.log("después de mergear", mergedMessages)

	i18n.global.setLocaleMessage(locale, mergedMessages); // For non-legacy mode

	return nextTick();
}

export function getTranslationKey(entityType, entity, fieldName) {
	switch (entityType) {
		case 'product':
			return `product_${entity.id}_${fieldName}`;
		case 'menuCategory':
			return `menuCategory_${store.getters['venue/venueId']}_${entity.name}_${fieldName}`;
		case 'productOptionType':
			return `productOptionType_${entity.id}_${fieldName}`;
		case 'productOptionCatalog':
			return `productOptionCatalog_${entity.id}_${fieldName}`;
		default:
			console.warn('Unsupported entity type for translation key generation:', entityType);
			return null;
	}
}
