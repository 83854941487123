<template>
	<div class="offcanvas offcanvas-bottom" tabindex="2" :id="'offcanvas_' + product.sku" aria-labelledby="offcanvasBottomLabel" style="z-index: 1060">
		<div class="offcanvas-header">
			<button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>

		<div class="offcanvas-body ">
			<div class="wrapper">

				<h5 class="offcanvas-title " id="offcanvasBottomLabel">{{ product.name }}</h5>
				<div v-if="product.allergens && product.allergens.length > 0" class="food-allergens">
					<img class="allergens-img" v-for="allergen in product.allergens" :key="allergen.id" :src="getImageAllergenUrl(allergen)" :alt="allergen.name">
				</div>
				<div>
					<p>{{ t('menu.checkAllergensWithStaff') }}</p>
				</div>

				<h4>{{ t('menu.howManyMenus') }}</h4>

				<div class="btns d-flex gap-3 mb-3 justify-content-center">
					<button :disabled="menuQuantity <= 0" @click="subtractMenuQuantity" class="btn btn-secondary btn-circle">
						<i class="fas fa-minus"></i>
					</button>
					<div class="product-card-unit">
						<span>&nbsp;{{ menuQuantity }}</span></div>
					<button @click="addMenuQuantity" class="btn btn-secondary btn-circle">
						<i class="fas fa-plus"></i>
					</button>
				</div>
				<template v-if="menuQuantity !== 0">
					<div v-for="(productOption, index) in product.productOptions" :key="`product_option_${product.sku}-${productOption.id}`">
						<hr class="vr-horizontal">
						<div class="extras-container">
							<div class="extras-header">
								<div class="extras-title">
											<span v-if="product.productOptions && product.productOptions.length > 1">{{ index + 1 }} de {{
													product.productOptions.length }}: </span>{{ productOption.description }}
								</div>
								<div class="extras-subtitle">
									{{
										productOption.max === 1
											? "Elige una opción"
											: productOption.max === productOption.min ? `Elige hasta ${productOption.max} opciones` : `Elige hasta ${productOption.max} opciones`
									}}
								</div>
							</div>
							<div class="extras-products-list">
								<div class="product-card-client product-card-client-list" v-for="option in productOption.options.filter(option => option.enabled)" :key="option.id">
									<div class="product-card-body">
										<div class="product-card-info">
											<div class="product-card-data">
												<div class="product-card-title">{{ option.name }}</div>
											</div>
										</div>
										<div class="product-card-btns">
											<div class="btns">
												<button v-if="countOptionsInSelectedOptions(option.id) > 0"
														@click="subtractOption({productOptionId: productOption.id,optionId:option.id })"
														class="btn btn-secondary btn-circle">
													<i class="fas" :class="countOptionsInSelectedOptions(option.id) === 1 ? 'fa-trash' : 'fa-minus'"></i>
												</button>
												<div class="product-card-unit">
													{{ countOptionsInSelectedOptions(option.id) }}
												</div>
												<button
													@click="addOption({productOptionId: productOption.id,optionId:option.id })"
													class="btn btn-secondary btn-circle">
													<i class="fas fa-plus"></i>
												</button>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
		<div class="offcanvas-footer flex-column">
			<div v-if="selectedOptions.length && optionsValidationMessage !== true" v-html="optionsValidationMessage"></div>
			<button @click="sendToCart" :disabled="!selectedOptions.length" type="button"
					class="btn btn-primary w-100" :id="'buttonModalAdd' + product.sku">Añadir
			</button>
		</div>
	</div>
</template>

<script>

import {arraysAreEqualIgnoringOrder, hideOffcanvas} from "@/utils";
import store from "@/store";
import {useI18n} from "vue-i18n";

export default {
	name: "MenuProductCardOffCanvasOptionDailyMenu",
	props: {
		product: {
			type: Object,
			required: true
		},
	},
	mounted() {
		console.log('❤️❤️❤️❤️❤️', this.getProduct)

	},
	components: {},
	computed: {
		optionsValidationMessage() {
			const validProductOptions = this.product.productOptions.filter(option => option.min > 0);
			const productOptionIds = validProductOptions.map(option => option.id);
			const productOptionNames = this.product.productOptions.reduce((acc, option) => {
				acc[option.id] = option.name;
				return acc;
			}, {});

			// Contar la cantidad de opciones seleccionadas por cada 'productOptionId'
			const selectedCount = this.selectedOptions.reduce((acc, item) => {
				acc[item.productOptionId] = (acc[item.productOptionId] || 0) + 1;
				return acc;
			}, {});

			// Calcular la cantidad de selecciones necesarias para cumplir con el número de menús
			const requiredSelectionsPerOption = this.menuQuantity;

			// Revisar si alguna opción tiene menos selecciones de las necesarias
			const missingOptions = productOptionIds.filter(id => (selectedCount[id] || 0) < requiredSelectionsPerOption);

			// Revisar si alguna opción tiene más selecciones de las necesarias
			const excessOptions = productOptionIds.filter(id => (selectedCount[id] || 0) > requiredSelectionsPerOption);

			// Generar el mensaje de validación
			let message = `Has elegido ${this.menuQuantity} menús.`;

			if (missingOptions.length > 0) {
				const missingMessages = missingOptions.map(id => {
					const countMissing = requiredSelectionsPerOption - (selectedCount[id] || 0);
					return `${countMissing} ${productOptionNames[id]}`;
				});

				// Formatear el mensaje de las opciones que faltan
				const lastMissing = missingMessages.pop();
				const formattedMissingMessage = missingMessages.length > 0
					? `${missingMessages.join(", ")} y ${lastMissing}`
					: lastMissing;

				message += ` Faltan ${formattedMissingMessage}.`;
			}

			if (excessOptions.length > 0) {
				const excessMessages = excessOptions.map(id => {
					const countExcess = (selectedCount[id] || 0) - requiredSelectionsPerOption;
					return `${countExcess} ${productOptionNames[id]}`;
				});

				// Formatear el mensaje de las opciones que sobran
				const lastExcess = excessMessages.pop();
				const formattedExcessMessage = excessMessages.length > 0
					? `${excessMessages.join(", ")} y ${lastExcess}`
					: lastExcess;

				message += ` Te has excedido con ${formattedExcessMessage}.`;
			}

			// Si no faltan ni sobran opciones, todo es correcto
			if (missingOptions.length === 0 && excessOptions.length === 0) {
				message = "Todas las opciones requeridas han sido marcadas correctamente.";
			}

			return message;
		},
		highestOptionCount() {
			const counts = this.selectedOptions.reduce((acc, item) => {
				acc[item.productOptionId] = (acc[item.productOptionId] || 0) + 1;
				return acc;
			}, {});

			return Object.keys(counts).length > 0 ? Math.max(...Object.values(counts)) : 0;
		},
	},
	data() {
		return {
			selectedOptions: [],
			menuQuantity: 0
		}
	},
	setup() {
		const {t} = useI18n();
		return {t};
	},
	methods: {
		getImageAllergenUrl(allergen) {
			return `assets/img/allergies/${allergen.pictureName.toLowerCase()}.png`;
		},
		countOptionsInSelectedOptions(optionId) {
			return this.selectedOptions.filter(item => item.optionId === optionId).length;
		},
		countSelectedOptionsForProductOptionId(productOptionId) {
			return this.selectedOptions.filter(item => item.productOptionId === productOptionId).length;
		},
		addOption(option) {
			console.log(option)
			this.selectedOptions.push(option)
		},
		addMenuQuantity() {
			this.menuQuantity += 1
		},
		subtractMenuQuantity() {
			this.menuQuantity -= 1
		},
		subtractOption(option) {
			const index = this.selectedOptions.findIndex(item =>
				item.productOptionId === option.productOptionId &&
				item.optionId === option.optionId
			);

			if (index !== -1) {
				this.selectedOptions.splice(index, 1);
			}
		},
		createMenuObjectsV3() {
			console.log("selectedOptions al crear el menu", this.selectedOptions);

			// Agrupar opciones por `productOptionId`
			const grouped = this.selectedOptions.reduce((acc, item) => {
				const optionId = item.productOptionId;
				const chosenId = item.optionId;

				if (!acc[optionId]) {
					acc[optionId] = {optionId, chosenIds: []};
				}

				acc[optionId].chosenIds.push(chosenId);
				return acc;
			}, {});

			// Convertir el objeto agrupado en un array
			const transformedArray = Object.values(grouped);

			// Crear los menús en base al parámetro `menuQuantity`, asegurando que todos los `chosenIds` se asignen
			const chosenArrays = Array.from({length: this.menuQuantity}, () => ({
				product: this.product,
				options: transformedArray.map(option => ({
					optionId: option.optionId,
					chosenIds: [] // Inicializar un array para agregar varios `chosenIds` en cada opción
				})),
				units: 1
			}));

			// Distribuir los `chosenIds` cíclicamente para asegurar que todos los elementos se asignen a algún menú
			transformedArray.forEach((option, optionIndex) => {
				option.chosenIds.forEach((chosenId, chosenIndex) => {
					const menuIndex = chosenIndex % this.menuQuantity;
					chosenArrays[menuIndex].options[optionIndex].chosenIds.push(chosenId);
				});
			});

			// Consolidar los menús para combinar aquellos con las mismas opciones
			return chosenArrays.reduce((acc, currentArray) => {
				const currentChosenIds = currentArray.options.map(option => option.chosenIds.join(","));

				const existing = acc.find(item => {
					const existingChosenIds = item.options.map(opt => opt.chosenIds.join(","));
					return arraysAreEqualIgnoringOrder(existingChosenIds, currentChosenIds);
				});

				if (existing) {
					existing.units += 1;
				} else {
					acc.push(currentArray);
				}

				return acc;
			}, []);
		},
		sendToCart() {
			const menus = this.createMenuObjectsV3();
			menus.forEach(menu => store.commit('menu/addProductToCart', {
				product: menu.product,
				units: menu.units,
				options: menu.options
			}))

			this.menuQuantity = 0
			this.selectedOptions = [];
			hideOffcanvas("offcanvas_" + this.product.sku);
		},

		closeModal() {
			this.selectedOptions = [];
			this.menuQuantity = 0
		}

	}
}
</script>

