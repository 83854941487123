<template>
	<div :id="id" class="modal fade" tabindex="-1" role="dialog" :aria-labelledby="`${id}-label`" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4" :id="`${id}-label`">

						<slot name="icon"></slot>

						{{ title }}
					</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">

					<slot></slot>

				</div>
				<div id="modal-footer-section" class="modal-footer">
					<DismissButton :is-modal="true" :content-btn-text="textBtnClose"/>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import DismissButton from "@/core/DismissButton";
export default {
	name: "ModalWrapper",
	components: {DismissButton},
	props:{
		id: {
			type: String,
			required: true
		},
		title: {
			type: String,
			required: true
		},
		textBtnClose: {
			type: String,
		}
	},
	data() {
		return {}
	}
}
</script>
