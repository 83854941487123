<template>
	<div v-if="user && isValidReservation" class="modal fade" id="modal-selected-name-invitation" tabindex="-1" aria-labelledby="modalUserFoundByTokenLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4" id="modalUserFoundByTokenLabel">{{ t('menu.tellUsYourName') }}</div>
					<button type="button" class="btn-close hidden" data-bs-dismiss="modal" aria-label="Cerrar"></button>
				</div>
				<div class="modal-body">

					<template v-if="invitedUsers.length && false">
						<p class="">Busca tu nombre en la lista y selecciónalo.</p>

						<div class="card" >
							<ul class="list-group">
								<li class="list-group-item" v-for="(invitedUser) in invitedUsers" :key="invitedUser.uid">
									<input class="form-check-input me-1" type="radio" name="listGroupRadio" :disabled="invitedUser.typeHost === 2" :value="invitedUser.uid" :id="invitedUser.uid" v-model="selectedUserName" @change="clearSignupName">
									<label class="form-check-label" :for="invitedUser.uid">{{invitedUser.name}}</label>
								</li>
							</ul>
						</div>
					</template>

					<div class="row justify-content-center">
						<div class="col-md-8">
							<p class="label" v-if="false">¿No eres ninguno de ellos?</p>
							<p class="label">Dinos cómo prefieres que te llamemos.</p>
							<div class="input-group">
								<span class="input-group-text" id="basic-addon1"><i class="fas fa-user"></i></span>
								<input type="text" class="form-control"  @focus="clearSelectedUser" :placeholder="t('menu.enterYourName')" v-model="signupName" aria-describedby="basic-addon1">
							</div>
						</div>
						<div class="col-md-8" v-if="isErrorAddName">
							<div class="alert alert-danger mt-3" role="alert">
								{{ t('menu.pleaseEnterYourName') }}
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<DismissButton :is-modal="true" @click="signupName = ''; isErrorAddName = false"/>
					<button type="button" class="btn btn-primary" @click="submitAcceptInvitationMenu()">
						<span v-if="!isLoadingAddName">Cambiar Nombre</span><span v-else>Cambiando...</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
// import {cookieManager} from "@/service/cookieManager";
import DismissButton from "@/core/DismissButton";
import {useI18n} from "vue-i18n";
import {hideAllModals} from "@/utils";
import store from "@/store";
// import {userRepository} from "@/service/repository/userRepository";

export default {
	name: "MenuModalSelectedNameInvitation",
	inject: {
		userRepository: 'userRepository',
	},
	props:{
		reservationInfo:Object,
		invitedUsers:Array
	},
	data(){
		return {
			selectedUserName: null,
			signupName: null,
			hostCode: '',

			isErrorAddName: false,
			isLoadingAddName: false,
		}
	},
	setup() {
		const { t } = useI18n(); // Extract the translation function `t` and the reactive `locale`
		return {
			t,
		};
	},
	computed:{
		...mapGetters('user', ['user']),

		isValidReservation() {
			console.log('cambia la reserva', this.reservationInfo.finishedDateTime)
			return this.reservationInfo && !this.reservationInfo.finishedDateTime && this.reservationInfo.status === 1 && this.reservationInfo.menuAccessEnabled === 1
		},
	},
	watch: {
		invitedUsers(newUsers) {
			if (this.user && newUsers.some(invited => invited.uid === this.user.uid)) {
				this.selectedUserName = this.user.uid;
			}
		}
	},
	components:{DismissButton},
	methods:{
		...mapActions("user",['find']),
		async submitAcceptInvitationMenu() {
			if (this.selectedUserName === 1 || !this.selectedUserName) {
				if (!this.signupName) {
					this.isErrorAddName = true
					return;
				}
				this.isErrorAddName = false
				this.isLoadingAddName = true;
				const response = await this.userRepository.updateUserName(this.signupName);
				if (response.result === 0) {
					// const invitedUser = response.invitedUser;
					// invitedUser.creationDate = new Date();
					// cookieManager.setToken(response.token);
					await this.find(this.user.uid)

					this.isLoadingAddName = false;
					hideAllModals();
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: "Se ha Actualizado Correctamente el Nombre",
							showToast: true,
							idToast: "correctlyToast"
						}
					});
					// window.location.href = `https://${this.reservationInfo.venueParams.subdomain}.wewelcom.io/menu?r=${this.reservationInfo.id}&uid=${this.user.hostCode}`;
				} else {
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: "No se ha Actualizado Correctamente el Nombre",
							showToast: true,
							idToast: "incorrectlyToast"
						}
					});
					this.isLoadingAddName = false;
				}
			} else {
				// window.location.href = `https://${this.reservationInfo.venueParams.subdomain}.wewelcom.io/menu?r=${this.reservationInfo.id}&uid=${this.selectedUserName}`;
			}
		},

		clearSelectedUser() {
			this.selectedUserName = null;
		},

		clearSignupName() {
			this.signupName = null;
		}
	}
}
</script>
