<template>
	<div :id="id" class="modal fade" tabindex="-1" role="dialog" :aria-labelledby="`${id}-label`" aria-hidden="true">
		<div v-if="commanderReservation" class="modal-dialog modal-dialog-scrollable modal-fullscreen" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4" :id="`${id}-label`">
						{{ commanderReservation.name }}
						<span class="vr mx-2"></span>
						<TableIcon/>
						{{commanderReservation.table}}
					</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body p-0">
					<div class="container-fluid h-100 g-0">
						<div class="row h-100 g-0">
							<!-- Scrollable Content -->
							<div class="col-8 h-100 overflow-y-scroll">
								<div ref="scrollContainer" class="scroll-section-wrapper">
									<div v-for="menu in displayedSections" :key="menu.id" :id="menu.id" class="h-100">
										<ProductsCategory :category="menu"/>
									</div>
								</div>
							</div>

							<!-- Sidebar -->
							<div class="col-4 h-100 overflow-y-scroll ">
								<div class="my-2">
									<button
										class="btn w-100 btn-secondary mb-1 btn-square fw-bold text-uppercase"
										@click.prevent="showAllSections"
										:class="{ active: showAll }"
									>
										Todo
									</button>
								</div>

								<div id="custom-navbar" v-for="(menu, index) in filteredMenus" :key="index" class="mb-3">
									<!-- Menú Principal -->
									<button
										:id="`${menu.id}-${menu.menuTypeName}`"
										class="btn w-100 btn-secondary mb-2 btn-square fw-bold"
										@click.prevent="filterMenu(menu.id)"
										:class="{ active: activeMenu === menu.id && !activeMenuCategory }"
									>
										{{ menu.menuTypeName }}
									</button>

									<!-- Subcategorías -->
									<a
										v-for="(category, cIndex) in menu.menuCategories"
										:key="`cat-${cIndex}`"
										class="btn w-100 btn-secondary mb-1 btn-square justify-content-start"
										@click.prevent="filterMenuCategory(category.id)"
										:class="{ active: activeMenuCategory === category.id }"
									>
										{{ category.name }}
									</a>
								</div>
							</div>
						</div>
					</div>

					<template v-if="product">
						<MenuProductCardOffCanvasOptionDailyMenu v-if="product.isMenu === 'SI'" :product="product"></MenuProductCardOffCanvasOptionDailyMenu>
						<InformationProduct :product="product" v-else/>
						<div class="modal" :id="'modalRemoveProduct_'+product.sku " tabindex="-1">
							<div class="modal-dialog modal-dialog-centered">
								<div class="modal-content">
									<div class="modal-header">
										<div class="modal-title h4">Eliminar Producto del Carrito</div>
										<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
										<p>¿Estás seguro de que deseas eliminar {{ product.name }} del carrito?</p>
									</div>
									<div class="modal-footer">
										<DismissButton :on-click="closeModal" content-btn-text="No, mantener producto"/>
										<button type="button" class="btn btn-danger" @click="confirmSubtractUnit">Eliminar</button>
									</div>
								</div>
							</div>
						</div>
					</template>

					<ProductCart/>
					<MenuModalRequestAccount :reservation-info="reservationInfo"></MenuModalRequestAccount>

				</div>
				<div id="modal-footer-section" class="modal-footer">
					<FooterBtnsAction :total-products="totalProducts" :cart-session="reservationInfo.cartSession"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import ProductsCategory from "@/components/copilotCommander/components/ProductsCategory";
import FooterBtnsAction from "@/components/copilotCommander/components/FooterBtnsAction";
import DismissButton from "@/core/DismissButton";
import MenuProductCardOffCanvasOptionDailyMenu
	from "@/components/menuProductCardOffCanvasOptionDailyMenu/MenuProductCardOffCanvasOptionDailyMenu";
import InformationProduct from "@/components/copilotCommander/components/InformationProduct";
import ProductCart from "@/components/copilotCommander/components/ProductCart";
import MenuModalRequestAccount from "@/components/modalRequestAccount/ModalRequestAccount";
import TableIcon from "@/core/icons/TableIcon";

export default {
	name: "CopilotCommander",
	components: {
		TableIcon,
		MenuModalRequestAccount,
		ProductCart,
		InformationProduct,
		MenuProductCardOffCanvasOptionDailyMenu,
		DismissButton, FooterBtnsAction, ProductsCategory},
	inject: {
		cartSessionRepository: 'cartSessionRepository',
		userRepository: 'userRepository',
		menuRepository: 'menuRepository',
	},
	data() {
		return {
			id: 'copilot-commander',
			activeSection: null,
			showAll: true,
			sections: [],
			displayedSections: [],
		};
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName', 'weOrderClientComments']),
		...mapGetters('copilot', ['commanderReservation']),
		...mapGetters('menu', ['menusVenue', 'productsCart', 'menus','getProduct']),

		filteredMenus() {
			const filterProducts = (products) => {
				return Array.isArray(products)
					? products
					: [];
			};

			const filterCategoriesRecursively = (category) => {
				if (!category) return null;

				const filteredChildren = Array.isArray(category.children)
					? category.children
						.map(filterCategoriesRecursively)
						.filter(child => child !== null)
					: [];

				const filteredMenuCategories = Array.isArray(category.menuCategories)
					? category.menuCategories
						.map(filterCategoriesRecursively)
						.filter(subCategory => subCategory !== null)
					: [];

				const filteredProducts = filterProducts(category.products);

				if (
					filteredChildren.length === 0 &&
					filteredMenuCategories.length === 0 &&
					filteredProducts.length === 0
				) {
					return null;
				}

				return {
					...category,
					children: filteredChildren,
					menuCategories: filteredMenuCategories,
					products: filteredProducts,
				};
			};

			return Array.isArray(this.menus)
				? this.menus
					.map(menu => ({
						...menu,
						menuCategories: Array.isArray(menu.menuCategories)
							? menu.menuCategories
								.map(filterCategoriesRecursively)
								.filter(category => category !== null)
							: [],
					}))
					.filter(menu => menu.menuCategories.length > 0)
				: [];
		},

		product() {
			return this.getProduct
		},

		totalProducts() {
			return this.productsCart.reduce((acumulator, product) => {
				return acumulator + product.cartItemCount;
			}, 0);
		},
		reservationInfo(){
			return this.commanderReservation
		},
	},
	watch: {
		activeMenu() {
			this.updateDisplayedSections();
		},
		activeMenuCategory() {
			this.updateDisplayedSections();
		},
		showAll() {
			this.updateDisplayedSections();
		}
	},
	methods: {
		handleScroll() {
			if (this.showAll) {
				const container = this.$refs.scrollContainer;
				if (!container) {
					console.error("scrollContainer is undefined");
					return;
				}

				const sectionOffsets = this.sections.map((section) => {
					const element = document.getElementById(section.id);
					if (element) {
						return {
							id: section.id,
							offsetTop: element.offsetTop,
							offsetBottom: element.offsetTop + element.offsetHeight,
						};
					} else {
						console.warn(`Element with id "${section.id}" not found.`);
						return null;
					}
				}).filter(Boolean);

				const scrollTop = container.scrollTop + container.clientHeight / 2;

				for (const section of sectionOffsets) {
					if (scrollTop >= section.offsetTop && scrollTop < section.offsetBottom) {
						this.activeSection = section.id;
						break;
					}
				}
			}
		},
		scrollToSection(id) {
			this.activeSection = id;
			this.showAll = false; // Cambia a "mostrar solo una sección"
			const container = this.$refs.scrollContainer;
			const target = document.getElementById(id);
			if (target) {
				container.scrollTo({
					top: target.offsetTop,
					behavior: "smooth",
				});
			}
		},

		updateDisplayedSections() {
			if (this.showAll) {
				this.displayedSections = this.filteredMenus.flatMap(menu => menu.menuCategories);
			} else if (this.activeMenu) {
				const menu = this.filteredMenus.find(menu => menu.id === this.activeMenu);
				if (menu) {
					if (this.activeMenuCategory) {
						const category = menu.menuCategories.find(cat => cat.id === this.activeMenuCategory);
						this.displayedSections = category ? [{ ...category }] : [];
					} else {
						this.displayedSections = menu.menuCategories.map(category => ({
							...category,
						}));
					}
				} else {
					this.displayedSections = [];
				}
			} else {
				this.displayedSections = [];
			}
		},

		resetState() {
			this.showAll = false;
			this.activeMenu = null;
			this.activeMenuCategory = null;
		},
		filterMenu(menuId) {
			this.resetState();

			if (this.activeMenu === menuId) {
				this.activeMenu = null;
			} else {
				this.activeMenu = menuId;
			}

			this.showAll = false;

			this.updateDisplayedSections();
		},
		filterMenuCategory(categoryId) {
			const foundMenu = this.filteredMenus.find(menu =>
				menu.menuCategories.some(category => category.id === categoryId)
			);

			if (foundMenu && this.activeMenu !== foundMenu.id) {
				this.activeMenu = foundMenu.id;
			}

			if (this.activeMenuCategory === categoryId) {
				this.activeMenuCategory = null;
			} else {
				this.activeMenuCategory = categoryId;
			}

			this.showAll = false;

			this.updateDisplayedSections();
		},
		showAllSections() {
			this.resetState();
			this.showAll = true;
			this.activeMenu = null;
			this.activeMenuCategory = null;

			this.updateDisplayedSections();
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.handleScroll();
			this.updateDisplayedSections();
		});
		const modalElement = document.getElementById(this.id);
		if (modalElement) {
			modalElement.addEventListener('show.bs.modal', () => {
				this.showAllSections();
			});
		}
	},
};
</script>

<style scoped>
.modal-body {
	height: calc(100vh - 56px - 56px); /* 100% de la altura menos los headers y footers */
	overflow: hidden;
}

.scroll-section {
	/*margin-bottom: 1rem; !* Añadir espacio entre las secciones *!*/
}

/*@media (max-width: 768px) {*/
/*	.modal-fullscreen {*/
/*		height: 100%;*/
/*	}*/
/*}*/
</style>
