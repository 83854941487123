<div class="col-12">
	<div class="product-card-client product-card-client-list" :id="`id_${product.sku}_${productChosenOptionsIds}`">
		<div class="product-card-body">
			<img v-if="showPictureMenu" class="product-card-img" :src="productImg" @error="handleImageError" alt="...">
			<div class="product-card-info">
				<div class="product-card-data">
					<div class="product-card-title">{{ product.name }}</div>
				</div>
				<ul v-for="(choice, index) in product.chosenOptions" :key="`product_option_${index}`" class="card-extras text-start list-group list-group-flush list-unstyled">
					<!--					<span>{{ getOptionDescription(choice.optionId) }}:</span>-->
					<li v-for="(chosenId, idx) in choice.chosenIds" :key="`chosen_option_${idx}`">
            				- {{getOptionName(choice.optionId, chosenId) }}
         			 </li>
				</ul>
			</div>
			<div class="product-card-btns flex-column">
				<div :class="hasMultipleButtons && isActiveProductInputs && dishClassAvailable.length === 2 ? 'btn-group btn-group-sm btn-group-vertical' : ''" role="group" aria-label="Basic checkbox toggle button group">
					<input v-if="product.shareable" type="checkbox" class="btn-check"
						   :id="`${productId}-is-share`"
						   @change="handleCheckBoxMarksToShare($event.target.checked)">
<!--					:class="{'btn-sm': !hasMultipleButtons}"-->
					<label v-if="product.shareable" class="btn btn-outline-primary btn-sm" :for="`${productId}-is-share`" >Para Compartir</label>

					<input v-if="isActiveProductInputs && dishClassAvailable.length === 2" type="checkbox" class="btn-check" :checked="hasChangedDishClass"
						   :id="`${productId}-move-order`"
						   @change="handleCheckboxDishClass($event.target.checked)">
					<label v-if="isActiveProductInputs && dishClassAvailable.length === 2" :class="{'btn-sm': !hasMultipleButtons}" class="btn btn-outline-primary" :for="`${productId}-move-order`">
						Servir como {{alternativeDishClass.name.toLowerCase().slice(0, -1)}}
					</label>
				</div>

				<div v-if="isActiveProductInputs && dishClassAvailable.length > 2">
					<label class="form-check-label ms-1" :for="productId">
						Servir como:
					</label>
					<select  v-model="dishClassSelected" @change="handleChangeSelectDishClass(dishClassSelected)"
							class="form-select" style="width: 10rem;"
							aria-label="Default select example">
						<option v-for="dishClass in dishClassAvailable" :key="`modify-dish-class-${dishClass.id}`" :value="dishClass.id">{{dishClass.name}}</option>
					</select>
				</div>


				<div class="btns">
					<button @click="subtractUnit" :disabled="isTimeOutSub" class="btn btn-secondary btn-circle">
						<i v-if="!isTimeOutSub" class="fas" :class="getMinusButtonIcon()"></i><SpinnerIcon v-else/>
					</button>
					<div class="product-card-unit">{{ product.cartItemCount }}
						<span v-if="!isCopilotRoute && product.isUnitary === 'SI'">&nbsp;uds.</span></div>
					<button @click="addUnit" :disabled="isTimeOutAdd" class="btn btn-secondary btn-circle">
						<i v-if="!isTimeOutAdd" class="fas fa-plus"></i><SpinnerIcon v-else/>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
