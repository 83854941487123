<!-- MenuCategory.vue -->
<template>
	<div v-if="category" :id="category.id + '-' + category.name">
		<!-- Nombre de la categoría -->
		<div class="card-secction" :class="[isCopilotRoute ? 'mb-0 p-1' : '', levelClass]">
			<hr v-if="level > 1">
			{{ translatedMenuCategoryName }}
			<hr>
		</div>

		<!-- Descripción de la categoría -->
		<div class="container" v-if="category.description && !isCopilotRoute">
			<div class="product-card-client mb-2 text-center fst-italic fw-semibold">
				{{ translatedMenuCategoryDescription }}
			</div>
		</div>

		<!-- Contenedor principal -->
		<!-- Productos de la categoría -->
		<div class="container">
			<div v-if="category.products && category.products.length" :class="isCopilotRoute ? 'row' : 'list-products'">
				<menu-product-card
					v-for="product in category.products"
					:key="product.sku"
					:is-copilot-route="isCopilotRoute"
					:reservation-promotion="reservationInfo.promotion"
					:is-valid-reservation="isValidReservation"
					@add-product="addProductToCart"
					:product="product"
					@subtract-unit="subtractUnit"
					@add-unit="addUnit"
					:product-unit="productsCart"
				></menu-product-card>
			</div>
		</div>

		<!-- Renderizar subcategorías recursivamente -->
		<div v-if="hasSubcategories">
			<menu-category
				v-for="subCategory in subcategories"
				:key="subCategory.id"
				:category="subCategory"
				:is-copilot-route="isCopilotRoute"
				:reservation-info="reservationInfo"
				:is-valid-reservation="isValidReservation"
				@add-product="addProductToCart"
				:products-cart="productsCart"
				@subtract-unit="subtractUnit"
				@add-unit="addUnit"
				:level="level + 1"
			></menu-category>
		</div>
	</div>
</template>

<script>
import MenuProductCard from "@/components/menuProductCard/MenuProductCard";
import store from "@/store";
import {useI18n} from "vue-i18n";
import {computed} from "vue";
import {getTranslationKey} from "@/i18n";

export default {
	name: 'MenuCategory',
	components: {MenuProductCard},
	props: {
		category: {
			type: Object,
			required: true
		},
		isCopilotRoute: Boolean,
		reservationInfo: Object,
		isValidReservation: Boolean,
		productsCart: Array,
		level: {
			type: Number,
			default: 1
		}
	},
	setup(props) {
		const { t } = useI18n(); // Extract the translation function `t` and the reactive `locale`

		const translatedMenuCategoryName = computed(() => {
			const translationKey = getTranslationKey('menuCategory', props.category, 'name');
			let translation = t(`dynamic.${translationKey}`);
			return translation !== `dynamic.${translationKey}` ? translation : props.category.name;
		});

		const translatedMenuCategoryDescription = computed(() => {
			const translationKey = getTranslationKey('menuCategory', props.category, 'description');
			let translation = t(`dynamic.${translationKey}`);
			return translation !== `dynamic.${translationKey}` ? translation : props.category.description;
		});

		return {
			t,
			translatedMenuCategoryName,
			translatedMenuCategoryDescription
		};
	},
	computed: {
		// Determina si la categoría tiene subcategorías
		hasSubcategories() {
			return (
				(this.category.children && this.category.children.length > 0) ||
				(this.category.menuCategories && this.category.menuCategories.length > 0)
			);
		},
		// Combina todas las subcategorías en un solo arreglo
		subcategories() {
			return [
				...(this.category.children || []),
				...(this.category.menuCategories || [])
			];
		},
		// Clases basadas en el nivel
		levelClass() {
			switch (this.level) {
				case 1:
					return '';
				case 2:
					return '';
				case 3:
					return 'card-subsecction z-0';
				default:
					return '';
			}
		}
	},
	methods: {
		// Propaga los eventos hacia arriba
		addProductToCart(product, units, options) {
			store.commit('menu/addProductToCart', {product, units, options})
		},
		subtractUnit(product) {
			this.$emit('subtract-unit', product);
		},
		addUnit(product) {
			this.$emit('add-unit', product);
		}
	}
};
</script>
