<template>
	<div class="product-card-client product-card-client-list" >
		<div class="product-card-body">
			<div class="product-card-info">
				<div class="product-card-data">
					<div class="product-card-title">
						{{menu.quantity}} Unidades de {{menu.name}}
					</div>
				</div>
				<div v-for="choice in menu.choices" :key="`menu-cart-daily-menu-choice-${menu.productSku + choice.type}`">
					<div class="fw-bold">{{choice.type}}:</div>
					<ul class="list-group list-group-flush list-unstyled" v-for="option in choice.options" :key="`menu-cart-daily-menu-choice-option-${menu.productSku}${choice.type}${option.name}`"
					>
						<li>- <span class="fw-bold">{{option.quantity}}</span> {{option.name}}</li>
					</ul>
				</div>
			</div>
			<div class="product-card-btns">
				<div class="btns">
					<button @click="deleteMenu" class="btn btn-secondary btn-circle">
						<i class="fas fa-trash" ></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
export default {
	name: "MenuCartDailyMenuProduct",
	props: {
		menu: {
			type: Object,
			required: true,
			default: null
		},
	},
	computed:{
		choices(){
			return this.menu.choices
		}
	},
	methods:{
		deleteMenu(){
			store.commit('menu/deleteProductFromCart', {sku: this.menu.productSku})
		}
	}

}
</script>

