<template>
	<div class="btn-group col-12" role="group" aria-label="Small button group">
		<button class="btn btn-secondary w-100 " type="button" @click.stop="openMenuFinisher">
			<span >Completar Menú</span>
		</button>
	</div>
</template>

<script>
import {showOffcanvas} from "@/utils";

export default {
	name: "CopilotCtaMenuFinisher",
	props: {
		reservationId: {
			type: String,
			required: true
		},
	},
	methods:{
		openMenuFinisher() {
			if (event) {
				event.stopPropagation();
			}
			this.$store.commit('copilot/setSelectedReservationId', { reservationId: this.reservationId });

			this.$nextTick (() => {
				showOffcanvas("offcanvas-copilot-menu-finisher")
			})
		},
	}
}
</script>

<style scoped>

</style>
