<template>
	<OffcanvasBottomWrapper id="offcanvas-order-drink" title=" Bebidas pendientes">
		<template #icon>
			<i class="fa-solid fa-wine-glass"></i>
		</template>

		<div v-for="reservation in sortedReservationsWithProducts" :key="'copilot-offcanvas-hybrid-role-reservation-'+reservation.id">
			<copilot-offcanvas-hybrid-role-reservation :reservation="reservation"></copilot-offcanvas-hybrid-role-reservation>
		</div>
	</OffcanvasBottomWrapper>
</template>

<script>

import {mapGetters} from "vuex";
import CopilotOffcanvasHybridRoleReservation
	from "@/components/copilotOffcanvasHybridRoleReservation/CopilotOffcanvasHybridRoleReservation";
import OffcanvasBottomWrapper from "@/components/utils/offcanvas/OffcanvasBottomWrapper";
export default {
	inject: {
		productOrderRepository: 'productOrderRepository',
	},
	components: {OffcanvasBottomWrapper, CopilotOffcanvasHybridRoleReservation},
	props: {},
	data() {
		return {}
	},
	computed:{
		...mapGetters('copilot', ['reservationsWithProducts', 'availableRoles', 'selectedRole']),
		sortedReservationsWithProducts() {
			return this.reservationsWithProducts
				.filter(reservation => !reservation.finishedDateTime)
				.slice()
				.sort((a, b) => {
					if (a.cartsession && !b.cartsession) {
						return -1;
					} else if (!a.cartsession && b.cartsession) {
						return 1;
					} else {
						return 0;
					}
				});
		},
	},
	watch: {
	},
	beforeMount() {
	},
	mounted() {

	},
	methods: {
	}
}
</script>
