<template>
	<div v-if="pendingMenus.length" class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvas-copilot-menu-finisher" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header">
		</div>
		<div class="offcanvas-header mb-0">
			<div class="offcanvas-title h4" id="offcanvasBottomLabel">{{ product.name }}</div>
			<button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body ">
			<div class="wrapper">
				<!-- Menú desplegable -->
				<div v-if="pendingMenus.length > 1" class="container-select">
					<label>
						Que menú deseas completar:
					</label>
					<select class="form-select mb-3" v-model="selectedMenu">
						<option v-for="menu in pendingMenus" :key="menu" :value="menu">
							{{ menu.name }}
						</option>
					</select>
				</div>
				<div class="btns d-flex gap-3 justify-content-center bg-primary p-2 position-sticky w-100" style="top: 0">
					<h4 class="mb-0">Menus: </h4>
					<button @click="subtractAdditionalMenuQuantity" :disabled="menuQuantity + additionalMenuQuantity <= menuQuantity" class="btn btn-secondary btn-circle">
						<i class="fas fa-minus"></i>
					</button>
					<div class="product-card-unit">
						{{menuQuantity + additionalMenuQuantity}}
					</div>
					<button @click="addAdditionalMenuQuantity" class="btn btn-secondary btn-circle">
						<i class="fas fa-plus"></i>
					</button>
				</div>
				<div v-for="(productOption, index) in product.productOptions" :key="`product-option-${product.sku}-${productOption.id}`">
					<hr class="vr-horizontal">
					<div class="extras-container">
						<div class="extras-header">
							<div class="extras-title">
								<span v-if="product.productOptions && product.productOptions.length > 1">{{ index + 1 }} de {{
										product.productOptions.length
									}}: </span>{{ productOption.description }}
							</div>
							<div class="extras-subtitle">
								{{
									productOption.max === 1
										? "Elige una opción"
										: productOption.max === productOption.min ? `Elige hasta ${productOption.max} opciones` : `Elige hasta ${productOption.max} opciones`
								}}
							</div>
						</div>
						<div class="extras-products-list">
							<div class="product-card-client product-card-client-list" :class="{ 'highlight-background': option.quantity > 0 }" v-for="option in productOption.options.filter(option => option.enabled)" :key="option.id">
								<div class="product-card-body">
									<div class="product-card-info">
										<div class="product-card-data">
											<div class="product-card-title">
												{{ option.name }}
											</div>
										</div>
									</div>
									<div class="product-card-btns">
										<div class="btns">
											<button v-if="countOptionsInSelectedOptions(option.id) > 0"
													@click="subtractOption({productOptionId: productOption.id,optionId:option.id })"
													class="btn btn-secondary btn-circle">
												<i class="fas fa-minus" ></i>
											</button>
											<div class="product-card-unit">
												{{ option.quantity + countOptionsInSelectedOptions(option.id) }}
											</div>
											<button
												@click="addOption({productOptionId: productOption.id,optionId:option.id })"
												class="btn btn-secondary btn-circle">
												<i class="fas fa-plus"></i>
											</button>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="offcanvas-footer flex-column">
			<div>
				{{ optionsValidationMessage }}
			</div>
			<button @click="handleCompleteMenu" :disabled="(!selectedOptions.length && !additionalMenuQuantity) || isSendingRequest" type="button"
					class="btn btn-primary w-100" id="button-menu-fisher-add">Añadir
			</button>
		</div>
	</div>
</template>

<script>

import {hideOffcanvas} from "@/utils";
import {useI18n} from "vue-i18n";
import {mapGetters} from "vuex";
import store from "@/store";

export default {
	name: "CopilotMenuFinisher",
	props: {
	},
	inject: {
		copilotRepository: 'copilotRepository',
	},
	components: {},
	watch: {
		reservation: {
			handler() {
				this.selectedOptions = [];
				this.additionalMenuQuantity = 0;
				this.selectedMenu = this.pendingMenus[0]
			},
			deep: true
		},
		selectedMenu: {
			handler() {
				this.selectedOptions = [];
				this.additionalMenuQuantity = 0;
			},
			deep: true
		},
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('copilot',['selectedReservation']),
		...mapGetters('menu',['menuProducts']),
		menuQuantity(){
			return this.selectedMenu.quantity
		},
		reservation(){
			return this.selectedReservation
		},
		pendingMenus() {
			return this.reservation?.cartSession?.productOrders
				?.filter(order => order.cartSessionStatus > 1)
				.flatMap(order => order.items.filter(item => item.isMenu === "SI"))
				.reduce((uniqueItems, item) => {
					const existingItem = uniqueItems.find(existing => existing.productSku === item.productSku);

					if (existingItem) {
						existingItem.quantity += 1;

						item.choices.forEach(choice => {
							const existingChoice = existingItem.choices.find(
								existingChoice => existingChoice.productChoiceId === choice.productChoiceId
							);

							if (existingChoice) {
								existingChoice.quantity += 1;
							} else {
								existingItem.choices.push({ ...choice, quantity: 1 });
							}
						});
					} else {
						uniqueItems.push({
							...item,
							quantity: 1,
							choices: item.choices.map(choice => ({
								...choice,
								quantity: 1
							}))
						});
					}

					return uniqueItems;
				}, []) || [];
		},
		product(){
			const product = this.menuProducts.find(menu => this.selectedMenu?.productSku === menu.sku)
			const productOptions = product.productOptions
				.map(productOption => ({
					...productOption,
					options: productOption.options
						.map(option => ({
							...option,
							quantity: this.countOptionInSelectedMenu(option.id) || 0
						}))
						.sort((a, b) => b.quantity - a.quantity)
				}))

			return {
				...product,
				productOptions: productOptions
			};
		},
		optionsValidationMessage() {
			const totalMenus = this.menuQuantity + this.additionalMenuQuantity;
			console.log('el selectedMenu', this.selectedMenu)
			const choicesSelectedMenu = this.selectedMenu?.choices?.flatMap(choice =>
				Array.from({ length: choice.quantity || 1 }, () => ({
					productOptionId: choice.optionTypeId,
					optionId: choice.productChoiceId
				}))
			);
			const allChoices = [...choicesSelectedMenu, ...this.selectedOptions]

			const validProductOptions = this.product.productOptions.filter(option => option.min > 0);
			const productOptionIds = validProductOptions.map(option => option.id);
			const productOptionNames = this.product.productOptions.reduce((acc, option) => {
				acc[option.id] = option.name;
				return acc;
			}, {});

			// Contar la cantidad de opciones seleccionadas por cada 'productOptionId'
			const selectedCount = allChoices.reduce((acc, item) => {
				acc[item.productOptionId] = (acc[item.productOptionId] || 0) + 1;
				return acc;
			}, {});

			// Calcular la cantidad de selecciones necesarias para cumplir con el número de menús
			const requiredSelectionsPerOption = totalMenus;

			// Revisar si alguna opción tiene menos selecciones de las necesarias
			const missingOptions = productOptionIds.filter(id => (selectedCount[id] || 0) < requiredSelectionsPerOption);

			// Revisar si alguna opción tiene más selecciones de las necesarias
			const excessOptions = productOptionIds.filter(id => (selectedCount[id] || 0) > requiredSelectionsPerOption);

			// Generar el mensaje de validación
			let message = `Has elegido ${totalMenus} menús.`;

			if (missingOptions.length > 0) {
				const missingMessages = missingOptions.map(id => {
					const countMissing = requiredSelectionsPerOption - (selectedCount[id] || 0);
					return `${countMissing} ${productOptionNames[id]}`;
				});

				// Formatear el mensaje de las opciones que faltan
				const lastMissing = missingMessages.pop();
				const formattedMissingMessage = missingMessages.length > 0
					? `${missingMessages.join(", ")} y ${lastMissing}`
					: lastMissing;

				message += ` Faltan ${formattedMissingMessage}.`;
			}

			if (excessOptions.length > 0) {
				const excessMessages = excessOptions.map(id => {
					const countExcess = (selectedCount[id] || 0) - requiredSelectionsPerOption;
					return `${countExcess} ${productOptionNames[id]}`;
				});

				// Formatear el mensaje de las opciones que sobran
				const lastExcess = excessMessages.pop();
				const formattedExcessMessage = excessMessages.length > 0
					? `${excessMessages.join(", ")} y ${lastExcess}`
					: lastExcess;

				message += ` Te has excedido con ${formattedExcessMessage}.`;
			}

			// Si no faltan ni sobran opciones, todo es correcto
			if (missingOptions.length === 0 && excessOptions.length === 0) {
				message = "Todas las opciones requeridas han sido marcadas correctamente.";
			}

			return message;
		},
	},
	data() {
		return {
			isSendingRequest: false,
			selectedMenu: null,
			pictureMenu: null,
			showPictureMenu: null,
			selectedOptions: [],
			additionalMenuQuantity: 0
		}
	},
	setup() {
		const { t } = useI18n();
		return { t };
	},
	methods: {
		countOptionInSelectedMenu(optionId){
			return this.selectedMenu.choices.find(choice => choice.productChoiceId === optionId)?.quantity
		},
		getImageAllergenUrl(allergen) {
			return `assets/img/allergies/${allergen.pictureName.toLowerCase()}.png`;
		},
		addAdditionalMenuQuantity(){
			this.additionalMenuQuantity += 1
		},
		subtractAdditionalMenuQuantity(){
			if(this.menuQuantity + this.additionalMenuQuantity <= this.menuQuantity) return
			this.additionalMenuQuantity -= 1
		},
		addOption(option) {
			console.log(option)
			this.selectedOptions.push(option)
		},
		subtractOption(option) {
			const index = this.selectedOptions.findIndex(item =>
				item.productOptionId === option.productOptionId &&
				item.optionId === option.optionId
			);

			if (index !== -1) {
				this.selectedOptions.splice(index, 1);
			}
		},
		countOptionsInSelectedOptions(optionId) {
			return this.selectedOptions.filter(item => item.optionId === optionId).length;
		},
		async handleCompleteMenu() {
			this.isSendingRequest = true;
			const data = {
				userId: this.user.id,
				reservationId: this.selectedReservation?.id,
				menuSku: this.selectedMenu.productSku,
				productOptions: this.selectedOptions,
				additionalMenus: this.additionalMenuQuantity
			}

			const resultCompleteMenu = await this.copilotRepository.completeMenu(data)
			if(resultCompleteMenu.result < 0){
				store.commit("copilot/setToast", {toast:{toastMessage: 'Ha habido un error completando el menu.', showToast:true, idToast: "incorrectlyToast"}});
				this.isSendingRequest = false;
				return
			}
			this.selectedOptions = [];
			this.additionalMenuQuantity = 0;
			this.isSendingRequest = false;
			hideOffcanvas("offcanvas-copilot-menu-finisher");
		},

		closeModal(){
			this.selectedOptions = [];
			this.additionalMenuQuantity = 0;
		}

	}
}
</script>

<style scoped>
.highlight-background {
	background-color: #f1e78f;
}
</style>

