<template>
	<div class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" id="orders">
		<div class="modal-dialog  modal-fullscreen">
			<div class="modal-content d-flex flex-column">
				<div class="modal-header">
					<h1 class="modal-title fs-5" id="exampleModalLabel">{{ t('menu.orderTopText') }}</h1>
					<button type="button" class="btn-close" @click="dismissProductInputs" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">

					<div v-if="groupedMenuChoices.length > 0" class="products-list-content overflow-auto" style="flex-grow: 1;">
						<div v-for="menu in groupedMenuChoices" :key="`menu-cart-daily-menu-${menu.productSku}`">
							<p class="fw-bold text-uppercase">Menus:</p>
							<MenuCartDailyMenuProduct :menu="menu"></MenuCartDailyMenuProduct>
						</div>
					</div>

					<div v-if="totalProducts > 0" class="products-list-content overflow-auto" style="flex-grow: 1;">
						<div v-for="(group, dishClassName) in groupedProducts" :key="dishClassName">
							<p class="fw-bold text-uppercase">{{ dishClassName }}</p>
							<MenuCartProduct
								v-for="product in group"
								:key="generateProductKey(product)"
								@subtract-unit="subtractUnit"
								@add-unit="addUnit"
								:product="product"
								:is-copilot-route="true"
								:is-active-product-inputs="isActiveProductInputs"
								class="mb-2"
							></MenuCartProduct>
						</div>
					</div>
				</div>
				<div class="modal-footer flex-column">
					<div class="w-100">
						<button class="btn btn-sm btn-outline-primary w-100 mb-3" @click="activateDishClassInputs" v-if="!isActiveProductInputs && isProductUpdateDishClass">
							{{ t('menu.changeDishClassQuestion') }}</button>

						<div v-if="totalProducts > 0 && weOrderClientComments" class="form-floating">
							<textarea v-model="cartClientMessage" class="form-control" placeholder="¿Algún comentario de tu pedido?" id="comments"></textarea>
							<label for="comments">{{ t('menu.orderCommentQuestion') }}</label>
						</div>
					</div>
					<div class="w-100 d-flex justify-content-end gap-2">
						<DismissButton :on-click="dismissProductInputs"/>
						<button
							v-if="totalProducts > 0"
							class="btn btn-primary"
							:disabled="sendCartButtonDisabled"
							@click="handleConfirmCart"
						>
							<span v-html="menuButtonContentOrder"></span>
							<SpinnerIcon v-if="isSpinnerIconLoading"/>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {mapGetters} from "vuex";
import MenuCartDailyMenuProduct from "@/components/menuCartProduct/MenuCartDailyMenuProduct";
import DismissButton from "@/core/DismissButton";
import * as bootstrap from "bootstrap";
import {hideAllModals, hideOffcanvas, showModal} from "@/utils";
import store from "@/store";
import SpinnerIcon from "@/core/icons/SpinnerIcon";
import MenuCartProduct from "@/components/menuCartProduct/MenuCartProduct";

export default {
	name: "ProductCart",
	components: {MenuCartProduct, SpinnerIcon, DismissButton, MenuCartDailyMenuProduct},
	data(){
		return {
			isActiveProductInputs: false,
			cartClientMessage: '',
			sendCartButtonDisabled: false,
			isSpinnerIconLoading: false,
			menuButtonContentOrder: this.t('menu.orderSendButton'),
		}
	},
	setup() {
		const { t } = useI18n();

		return { t };
	},
	inject:{
		cartSessionRepository: 'cartSessionRepository',
		cookieManager: 'cookieManager',
		reservationRepository: 'reservationRepository',

	},
	computed:{
		...mapGetters('menu', ['productsCart']),
		...mapGetters('venue', ['weOrderClientComments']),
		...mapGetters('user', ['user']),
		...mapGetters('copilot', ['commanderReservation']),

		groupedMenuChoices() {
			if (!Array.isArray(this.productsCart)) {
				console.error("productsCart no es un array válido.");
				return [];
			}

			const groupedByProductSku = [];

			this.productsCart
				.filter(item => item.isMenu === "SI")
				.forEach(product => {
					let productGroup = groupedByProductSku.find(group => group.productSku === product.sku);

					if (!productGroup) {
						productGroup = {
							productSku: product.sku,
							name: product.name,
							quantity: 0,
							choices: []
						};
						groupedByProductSku.push(productGroup);
					}

					productGroup.quantity += 1;

					const choices = (product.chosenOptions || []).flatMap(chosenOption => {
						const option = (product.productOptions || []).find(optionP => optionP.id === chosenOption.optionId);

						if (!option) return [];

						return (chosenOption.chosenIds || [])
							.map(chosenId => option.options.find(optionItem => optionItem.id === chosenId))
							.filter(Boolean)
							.map(chosenOptionMap => ({
								choice: chosenOptionMap.name,
								optionTypeId: option.id,
								type: option.name,
								productChoiceId: chosenOptionMap.product?.id
							}));
					});

					choices.forEach(choice => {
						let typeGroup = productGroup.choices.find(c => c.type === choice.type);

						if (!typeGroup) {
							typeGroup = {
								type: choice.type,
								options: []
							};
							productGroup.choices.push(typeGroup);
						}

						const existingChoice = typeGroup.options.find(c => c.name === choice.choice);

						if (existingChoice) {
							existingChoice.quantity += 1;
						} else {
							typeGroup.options.push({
								name: choice.choice,
								quantity: 1
							});
						}
					});
				});

			return groupedByProductSku;
		},
		totalProducts() {
			return this.productsCart.reduce((acumulator, product) => {
				return acumulator + product.cartItemCount;
			}, 0);
		},
		reservationInfo(){
			return this.commanderReservation
		},
		groupedProducts() {
			const groups = new Map();

			this.productsCart
				.filter(product => product.isMenu !== "SI")
				.forEach(product => {
					const currentDishClass = product.modifyDishClass ?? product.dishClass;
					const dishClassName = currentDishClass?.name || 'Bebidas y guarniciones';

					if (!groups.has(dishClassName)) {
						groups.set(dishClassName, []);
					}

					groups.get(dishClassName).push(product);
				});

			return Object.fromEntries(groups)
		},

		isProductUpdateDishClass() {
			return this.productsCart.some(product => {
				const switchableClasses = product.dishClass?.switchableClasses;

				// Regla 1 y 2: Evaluar si es un array y si está vacío
				if (Array.isArray(switchableClasses)) {
					return switchableClasses.length > 0; // True si tiene elementos
				}

				// Regla 3: Evaluar si es un objeto con claves
				if (switchableClasses && typeof switchableClasses === 'object') {
					return Object.keys(switchableClasses).length > 0; // True si tiene datos
				}

				// Regla 4: Si dishClass es null, devolver false
				return false;
			});
		},
	},
	async beforeMount() {
		const localStorageCart = localStorage.getItem('cart');
		if (localStorageCart) {
			store.commit('menu/setProductsCart', {productsCart: JSON.parse(localStorageCart)})
		}

		await this.loadInfoReservation();
	},
	methods:{
		dismissProductInputs() {
			this.isActiveProductInputs = false;
		},
		addUnit(product) {
			store.commit('menu/addUnit', {product})
		},
		subtractUnit(product) {
			store.commit('menu/subtractUnit', {product})
		},
		generateProductKey(product) {
			const chosenIds = product.chosenOptions
				? product.chosenOptions.flatMap(chosen => chosen.chosenIds).sort().join('-')
				: '';

			const dishClassId = product.dishClass ? product.dishClass.id : '';

			return `${product.sku}-${chosenIds}-${dishClassId}`;
		},
		activateDishClassInputs() {
			this.isActiveProductInputs = true
			console.log(this.isActiveProductInputs)
		},

		async handleConfirmCart() {

			this.menuButtonContentOrder = `${this.t('menu.sendingCart')}...`;
			this.sendCartButtonDisabled = true;
			this.isSpinnerIconLoading = true;
			const orderInfo = {
				name: this.user.name,
				welKey: this.user.welKey,
				userId: this.user.id,
				pax: this.reservationInfo.pax,
				source: 2,
				clientMessage: this.cartClientMessage,
				orderReservationName: this.user.name,
				reservationId: this.reservationInfo.id,
				av: 0,
			}


			const products = this.productsCart.flatMap(product => {
				if (product.isUnitary === "SI") {
					return {
						sku: product.sku,
						options: product.chosenOptions,
						units: product.cartItemCount,
						modifyDishClass: product.modifyDishClass?.id,
						isShareable: product.isShareable
					}
				} else {
					return Array(product.cartItemCount).fill({
						sku: product.sku,
						options: product.chosenOptions,
						modifyDishClass: product.modifyDishClass?.id,
						isShareable: product.isShareable
					});
				}
			});

			const resultOrder = await this.cartSessionRepository.createCartSessionOrder(orderInfo, products);
			this.isRequestAccount = false;
			console.log(resultOrder)
			if (resultOrder.result === 0) {
				this.menuButtonContentOrder = `${this.t('menu.sendingCart')}...`;
				this.isSpinnerIconLoading = true;

				const modal = document.getElementById('orders');
				const modalOrders = bootstrap.Modal.getInstance(modal);
				modalOrders.hide();

				this.menuButtonContentOrder = this.t('menu.orderSendButton');
				this.isSpinnerIconLoading = false;

				this.messageCart = {clientName: this.user.name, products: this.productsCart};
				showModal('modal-message-products')
				// const modalCheckInbox = new bootstrap.Modal(document.getElementById('messageModal'), {
				// 	backdrop: true,
				// });
				// modalCheckInbox.show();
				store.commit('menu/setProductsCart', {productsCart: []})
				this.cartClientMessage = '';
				this.isActiveProductInputs = false
				await this.loadInfoReservation()
			}

			if (resultOrder.result === -2){
				store.commit('menu/setProductsCart', {productsCart: []});
				store.commit("copilot/setToast", {toast:{toastMessage: 'La reserva ha finalizado.', showToast:true, idToast: "incorrectlyToast"}});
				await this.loadInfoReservation();
				this.menuButtonContentOrder = this.t('menu.orderSendButton');
				this.isSpinnerIconLoading = false;
			}

			this.sendCartButtonDisabled = false;

			hideAllModals()
			hideOffcanvas()
		},

		async loadInfoReservation() {
			const values = window.location.search;
			const urlParams = new URLSearchParams(values);
			let reservationId = urlParams.get('r');

			const cookieReservationId = this.cookieManager.getReservationMenuId();

			if (!reservationId && cookieReservationId) {
				reservationId = cookieReservationId;
			}

			if (reservationId) {
				const reservationResponse = await this.reservationRepository.find(reservationId);
				const reservation = reservationResponse.reservation

				if (reservation && !reservation.finishedDateTime && reservation.status === 1 && reservation.menuAccessEnabled === 1) {
					this.reservationInfo = reservation;
					console.log('Es una reserva valida: ', this.reservationInfo)
					this.cookieManager.setReservationMenuId(this.reservationInfo.id);
				} else {
					if (reservation) {
						this.reservationInfo = reservation;
					}

					console.log('No es una reserva valida: ', this.reservationInfo);
					// this.cookieManager.clearReservationMenuId();
					localStorage.setItem('cart', JSON.stringify([]));
					this.productsCart = [];
					// window.location.href = this.urlVenue;
				}

			} else {
				console.log('No es una reserva real: ', this.reservationInfo)
				// this.cookieManager.clearReservationMenuId();
				localStorage.setItem('cart', JSON.stringify([]));
				this.productsCart = [];
				// this.$router.push('/reservar')
				console.log('no hay usuario en el menu')
			}

			this.removeQueryParams();
		},
		removeQueryParams() {
			let url = window.location.href;
			const urlParts = url.split('?');

			if (urlParts.length >= 2) {
				url = urlParts[0];
				window.history.replaceState({}, document.title, url);
			}
		},
	},
}
</script>

<style scoped>

</style>
