<template>
	<div v-if="shouldShowButton" class="btn-group col-12" role="group" >
		<button :disabled="isSendingRequest" class="btn btn-secondary w-100 " type="button" @click.stop="handleEnableReservation">
			<span v-html="buttonContent"></span><SpinnerIcon v-if="isSendingRequest"/>
		</button>
	</div>
</template>

<script>
import store from "@/store";
import SpinnerIcon from "@/core/icons/SpinnerIcon";

export default {
	name: "CopilotCtaEnableReservation",
	inject: {
		reservationRepository: 'reservationRepository',
	},
	components:{
		SpinnerIcon
	},
	props: {
		reservation: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			isSendingRequest: false,
		};
	},
	computed:{
		buttonContent(){
			return this.isSendingRequest ? 'Habilitando reserva' : 'Habilitar reserva';
		},
		shouldShowButton() {
			return this.reservation.menuAccessEnabled === 0 &&!this.reservation.finishedDateTime
		},
	},
	methods:{
		async handleEnableReservation(){
			if (event) {
				event.stopPropagation();
			}
			this.isSendingRequest = true;
			const resultEnableReservation = await this.reservationRepository.enableReservation({reservationWid: this.reservation.id})
			if(resultEnableReservation.result !== 0){
				store.commit("copilot/setToast", {toast:{toastMessage: 'Ha habido un error.', showToast:true, idToast: "incorrectlyToast"}});
			}
			this.isSendingRequest = false;
		}
	}
}
</script>

<style scoped>

</style>
