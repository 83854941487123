<template>
	<label class="product-card-client product-card-client-list" >
		<div class="product-card-body">
			<div class="product-card-info">
				<div class="product-card-data">
					<div class="product-card-title">
						{{menu.quantity}} Unidades de {{menu.name}}
					</div>
				</div>
				<div v-for="choice in menu.choices" :key="menu.productSku + choice.type">
					<div class="fw-bold">{{choice.type}}:</div>
					<ul class="list-group list-group-flush list-unstyled" v-for="option in choice.options" :key="menu.productSku + choice.type + option.name">
						<li>- {{option.quantity}} {{option.name}}</li>
					</ul>
				</div>
			</div>
		</div>
	</label>
</template>

<script>
export default {
	name: "CopilotHistoricMenuDetails",
	props: {
		menu: {
			type: Object,
			required: true,
			default: null
		},
	},
	choices(){
		return this.menu.choices
	},
}
</script>


