<template src="./copilotCardReservation.html"></template>

<script>
import GeneratorQr from "@/components/generatorQr/GeneratorQr";
import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";
import MenuWewelcom from "@/views/menu/Menu";
import {mapGetters} from "vuex";
import store from "@/store";
import {hideAllModals, printChoices, showModal, showOffcanvas} from "@/utils";
import TableIcon from "@/core/icons/TableIcon";
import * as bootstrap from 'bootstrap'
import CopilotCardSelectTable from "@/components/copilotCardSelectTable/CopilotCardSelectTable";
import ChefHatIcon from "@/core/icons/ChefHatIcon";
import DismissButton from "@/core/DismissButton";
import SittingIcon from "@/core/icons/SittingIcon";
import SpinnerIcon from "@/core/icons/SpinnerIcon";
import {walkingService} from "@/service/walkingService";
import CopilotOrdersContainer from "@/components/copilotOrdersContainer/CopilotOrdersContainer";
import {useI18n} from "vue-i18n";
import CopilotFireableButtons from "@/components/copilotFireableButtons/CopilotFireableButtons";
import {userRepository} from "@/service/repository/userRepository";
import CopilotCtaMenuFinisher from "@/components/copilotMenuFinisher/CopilotCtaMenuFinisher";
import OrdersProductMenuDailyCard from "@/components/ordersProductCard/OrdersProductMenuDailyCard";
import CopilotCtaEnableReservation from "@/components/copilotCtaEnableReservation/CopilotCtaEnableReservation";

export default {
	name: 'CopilotCardReservation',
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
		cartSessionRepository: 'cartSessionRepository',
		dateFormatterService: 'dateFormatterService',
		cameraService: 'cameraService',
	},
	emits: ['open-details'],
	props: {
		reservation: {},
		serviceName: String,
	},
	components: {
		'menu-wewelcom': MenuWewelcom,
		'generator-qr': GeneratorQr,
		'orders-product-card': OrdersProductCard,
		'table-icon': TableIcon,
		CopilotCardSelectTable,
		ChefHatIcon,
		DismissButton,
		SittingIcon,
		SpinnerIcon,
		CopilotOrdersContainer,
		CopilotFireableButtons,
		CopilotCtaMenuFinisher,
		OrdersProductMenuDailyCard,
		CopilotCtaEnableReservation
	},
	data() {
		return {
			timeFormate: '',
			buttonTextOrder: 'Comanda anotada',
			reactivateReservationTextButton: 'Reactivar reserva',
			promotionDiscountDrink: '',
			promotionDiscountFood: '',
			elapsedTime: '',
			isOpenReserve: false,
			paxTuUpdate: null,
			isWalkin: false,
			isSitting: false,
			isReserve: false,
			viewListUsers: true,

			isLoadingBtnOrder:false
		}
	},
	computed: {
		...mapGetters('venue', ['resourceId', 'urlVenue']),
		...mapGetters('config', ['wePassUrl', 'weWelcomUrl']),
		...mapGetters('copilot', ['pendingOrders', 'tables', 'allOrderProducts', 'reservations', 'selectedRole', 'notifications', 'showTicketRequestNotifications']),
		localTables() {
			return this.tables.filter(table => table.name !== this.reservation.table);
		},
		// cartSessionOrders() {
		// 	return this.reservation.cartSession ? this.reservation.cartSession.productOrders : [];
		// },
		reservationFinishDisabled() {
			return this.reservation.finishedDateTime != null;
		},

		isReservationRequest() {
			if (this.pendingOrders.length === 0 || !this.reservation.cartSession || this.reservation.cartSession.productOrders.length === 0) {
				return false;
			}

			const match = this.reservation.cartSession.productOrders.some(order => {
				return order.cartSessionOrderId === this.pendingOrders[0].cartSessionOrderId;
			});
			return match;
		},

		invitationText() {
			const users = this.reservation?.reservationUsers;
			const reviews = this.reservation?.userReviews;

			if (!users) {
				return [];
			}

			const sortedUsers = users.slice().sort((a, b) => {
				return a.type === 1 && b.type !== 1 ? -1 : b.type === 1 && a.type !== 1 ? 1 : 0;
			});

			return sortedUsers.map((diner) => {
				const userReview = reviews?.find(review => review.userId === diner.userId);
				return {
					name: diner.name,
					type: diner.type,
					reviewScore: userReview ? userReview.score : null,
					userId: diner.userId,
					userUid: diner.uid
				};
			});
		},

		isReservationStatusStart() {
			return this.reservation.menuAccessEnabled === 1;
		},

		isReservationCancelled() {
			return this.reservation.status === -1;
		},
		isReservationFinish() {
			return this.reservation.status === 7;
		},
		isNoShow() {
			return this.reservation.status === -4
		},

		hasDubbing() {
			const currentReservation = this.reservation
			const endDate = new Date(this.reservation.endDateTime);
			const table = this.reservation.table;

			return this.reservations.some(other => {
				if (!other.finishedDateTime && !currentReservation.finishedDateTime && other.table === table && other.id !== currentReservation.id) {
					const startDate = new Date(other.startDateTime);
					const timeDifference = (startDate - endDate) / (1000 * 60);
					return timeDifference <= 30 && timeDifference > 0;
				}
				return false;
			});
		},
		reservationReview() {
			const reviews = this.reservation.userReviews;
			if (!reviews || reviews.length === 0) {
				return null;
			}
			const totalScore = reviews.reduce((sum, review) => sum + review.score, 0);

			const averageScore = totalScore / reviews.length;

			if (Number.isInteger(averageScore)) {
				return averageScore;
			}

			return Math.round(averageScore * 10) / 10;
		},

		groupedOrdersMenuDaily(){
			const groupedByProductSku = [];
			// Verificar si hay datos válidos en `pendingOrders`
			if (!this.pendingOrders || !this.pendingOrders[0] || typeof this.pendingOrders[0].items !== 'object') {
				return {};
			}
			const items = this.pendingOrders[0].items;
			const menusArray =Object.values(items)
				.filter(product => product.isMenu === "SI")
				.map((product, index) => {
					return { ...product, index };
				});
			console.log("menusArray",menusArray)
			menusArray.forEach(order => {
				let productGroup = groupedByProductSku.find(group => group.productSku === order.productSku);

				if (!productGroup) {
					productGroup = {
						productSku: order.productSku,
						name: order.name,
						quantity: 0,
						choices: []
					};
					groupedByProductSku.push(productGroup);
				}

				productGroup.quantity += order.units;

				order.choices.forEach(choice => {
					let typeGroup = productGroup.choices.find(c => c.type === choice.type);

					if (!typeGroup) {
						typeGroup = {
							type: choice.type,
							options: []
						};
						productGroup.choices.push(typeGroup);
					}

					const existingChoice = typeGroup.options.find(c => c.name === choice.choice);

					if (existingChoice) {

						existingChoice.quantity += 1;
					} else {
						typeGroup.options.push({
							name: choice.choice,
							quantity: 1
						});
					}
				});
			});

			return groupedByProductSku
		},
		groupedOrders() {
			console.log('pendingOrders', this.pendingOrders);
			console.log('groupedOrdersMenuDaily', this.groupedOrdersMenuDaily);

			// Verificar si hay datos válidos en `pendingOrders`
			if (!this.pendingOrders || !this.pendingOrders[0] || typeof this.pendingOrders[0].items !== 'object') {
				return {};
			}

			const items = this.pendingOrders[0].items;
			console.log('items', items);
			const filteredItems = Object.fromEntries(
				Object.entries(items).filter(
					([, product]) => product.isMenu !== "SI"
				)
			);

			const grouped = {
				"Sin Categoría": [] // Grupo especial para los que no tienen dishClass
			};

			for (const key in filteredItems) {
				const order = filteredItems[key];

				// Verificar si tiene dishClass.name
				if (order.dishClass?.name) {
					if (!grouped[order.dishClass.name]) {
						grouped[order.dishClass.name] = [];
					}
					grouped[order.dishClass.name].push(order);
				} else {
					// Si no tiene dishClass, agrégalo al grupo "Sin DishClass"
					grouped["Sin Categoría"].push(order);
				}
			}

			// Ordenar cada grupo (excepto "Sin DishClass") por `dishClass.sortOrder`
			for (const dishName in grouped) {
				if (dishName !== "Sin Categoría") {
					grouped[dishName].sort((a, b) => {
						return (a.dishClass?.sortOrder || 0) - (b.dishClass?.sortOrder || 0);
					});
				}
			}

			// Retornar los grupos con "Sin DishClass" al principio
			return {
				"Sin Categoría": grouped["Sin Categoría"],
				...Object.keys(grouped)
					.filter(name => name !== "Sin Categoría")
					.sort() // Ordena los nombres de las claves alfabéticamente
					.reduce((sorted, name) => {
						sorted[name] = grouped[name];
						return sorted;
					}, {})
			};
		}
,
		hasPendingMenus(){
			return this.reservation.cartSession?.productOrders
				?.filter(order => order.cartSessionStatus > 1)
				.some(order =>
					order.items.some(item => item.isMenu === "SI")
				) || false;
		}
	},
	beforeMount() {
	},
	created() {
		this.cameraService.sourceComponent = 'Copilot';
		this.setReservationStatus()
	},
	setup() {
		const { t } = useI18n();

		return { t };
	},
	mounted() {
		document.addEventListener('buttonTextOrderUpdated', () => {
			this.buttonTextOrder = 'Comanda anotada';
		});
		this.promotionDiscountDrink = this.calculateDiscountPercentage(this.reservation.promotion ? this.reservation.promotion.discount : null);
		this.promotionDiscountFood = this.calculateDiscountPercentage();

		this.updateElapsedTime();
		this.initializeTooltips();

		setInterval(() => {
			this.updateElapsedTime();
		}, 1000);

		const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
		popoverTriggerList.forEach(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl));
		console.log('La reserva desde la carta:', this.reservation.id, this.hasPendingMenus)
	},
	methods: {
		paymentMethodName(message) {
			if (message.includes("Pago en efectivo")) {
				return "Efectivo";
			} else if (message.includes("Pago con tarjeta")) {
				return "Tarjeta";
			} else if (message.includes("pago online")) {
				return "Online";
			}
			return "";
		},
		setReservationStatus() {
			if (this.reservation.walkIn == null || this.reservation.walkIn === 0) {
				this.isReserve = true;
			} else if (this.reservation.walkIn === 1) {
				this.isWalkin = true;
			} else if (this.reservation.walkIn === 2) {
				this.isSitting = true;
			}
		},
		openCollapse(elementId) {
			let collapseElement = document.getElementById('collapse' + elementId);
			if (collapseElement) {
				let iconArrow = document.getElementById('iconArrow' + elementId)
				let containerElement = document.getElementById('containerArrow' + elementId)

				let collapseInstance = bootstrap.Collapse.getInstance(collapseElement);
				if (!collapseInstance) {
					collapseInstance = new bootstrap.Collapse(collapseElement, {
						toggle: false
					});
				}
				if (collapseElement.classList.contains('show')) {
					collapseInstance.hide();
					iconArrow.classList.remove('fa-rotate-180');
					containerElement.classList.remove('active');
					this.viewListUsers = true

				} else {
					collapseInstance.show();
					iconArrow.classList.add('fa-rotate-180')
					containerElement.classList.add('active');
					this.viewListUsers = false
				}
			}
		},
		printChoices(choices) {
			return printChoices(choices)
		},
		async handleProcessOrder(cartSessionOrderId) {
			this.isLoadingBtnOrder=true;
			this.buttonTextOrder = `Anotando comanda <i class="fas fa-spinner fa-spin"></i>`
			const resultProcessOrder = await this.cartSessionRepository.processOrder(cartSessionOrderId, this.selectedRole);

			if (resultProcessOrder.result === 0) {
				console.log('Orden: ', cartSessionOrderId, ' procesada');
				console.log(this.cartSessionOrders)
				this.isLoadingBtnOrder=false;
			}
			this.isLoadingBtnOrder=false;
		},
		arrayBufferToBase64(buffer) {
			let binary = '';
			const bytes = new Uint8Array(buffer);
			const len = bytes.byteLength;
			for (let i = 0; i < len; i++) {
				binary += String.fromCharCode(bytes[i]);
			}
			return window.btoa(binary);
		},

		async handleReactivateReservation() {
			try {
				this.reactivateReservationTextButton = `Reactivando la reserva <i class="fas fa-spinner fa-spin"></i>`;


				const resultReactivateReservation = await this.reservationRepository.reactivateReservation(this.reservation.id)
				if (resultReactivateReservation.result === 0) {
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: "Se ha reactivado la reserva correctamente",
							showToast: true,
							idToast: "correctlyToast"
						}
					});
				}
				this.reactivateReservationTextButton = `Reactivar reserva`
			} catch (e) {
				console.log(e)
			}

		},

		async handleAskAccount(paymentMethod) {
			// const data = {reservationWid: this.reservationInfo.id, userId: this.user.id, score: this.selectedRating}
			//
			// await this.menuRepository.sendReview(data);


			await store.dispatch('menu/requestAccount', {
				reservationInfo: this.reservationInfo,
				user: this.user,
				paymentMethod: paymentMethod
			})
		},

		updateElapsedTime() {
			if (!this.pendingOrders.length || !this.pendingOrders[0].createdDateTime) {
				return;
			}
			this.elapsedTime = this.dateFormatterService.formattedTimeChronometer(this.pendingOrders[0].createdDateTime)
		},
		calculateDiscountPercentage(discount) {
			if (discount !== null && discount !== 0 && discount !== '') {
				return `-${discount / 100}%`;
			}
			return null;
		},
		reservationStatus(reservation) {
			if (reservation.status === -1) {
				return 'cancelled';
			} else if (reservation.status === -4) {
				return 'no-show'
			} else if (reservation.status === 7) {
				return 'process-pay'
			} else if (reservation.status === 10) {
				return 'finish';
			} else if (this.isReservationRequest) {
				return 'order';
			} else if (reservation.menuAccessEnabled === 1) {
				return 'started';
			} else if (reservation.startDate !== null) {
				return 'standar'
			}
			return '';
		},
		openComanderMenu(event) {
			event.stopPropagation();
			hideAllModals();
			const collapseElement = document.getElementById('copilot-commander');
			store.commit('copilot/setCommanderReservation', {commanderReservation: this.reservation});

			this.$nextTick(() => {
				if (collapseElement) {
					showModal('copilot-commander')
				}
			})
		},
		async openQRInvitation(reservation) {
			event.stopPropagation();
			store.commit('copilot/setSelectedReservationId', { reservationId:reservation.id });

			await walkingService._openQRInvitation(reservation.id);
		},
		openOffcanvasHistoricAccount(reservation) {
			if (event) {
				event.stopPropagation();
			}
			this.$store.commit('copilot/setSelectedReservationId', { reservationId:reservation.id });

			this.$nextTick (() => {
				showOffcanvas('offcanvas-historic-account-' + reservation.id)
			})
		},
		initializeTooltips() {
			const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
			tooltipTriggerList.forEach(tooltipTriggerEl => {
				new bootstrap.Tooltip(tooltipTriggerEl);
			});
		},

		handleOptionClick(option, id) {
			this.isMenuOpen = false;
			if (option === 'camera') {
				this.cameraService.showModalCameraScan();
				this.$store.dispatch('copilot/getIdReservation', id);
			}
		},

		openModalUpdatePax(reservation) {
			if (event) {
				event.stopPropagation();
			}
			this.$store.commit('copilot/setSelectedReservationId', { reservationId:reservation.id });

			this.$nextTick (() => {
				showOffcanvas('offcanvasUpdatePax-' + reservation.id)
			})
		},

		openModalFinishReservation(reservation) {
			if (event) {
				event.stopPropagation();
			}
			this.$store.commit('copilot/setSelectedReservationId', { reservationId:reservation.id });

			this.$nextTick (() => {
				showOffcanvas('offcanvas-confirm-close-' + reservation.id)
			})
		},

		async handleFinishReservation(noShow, paymentMethod) {
			try {
				if (paymentMethod === 1) {
					this.finishReservationTextButtonCash = `Pidiendo... <i class="fas fa-spinner fa-spin"></i>`
				}
				if (paymentMethod === 2) {
					this.finishReservationTextButtonCard = `Pidiendo... <i class="fas fa-spinner fa-spin"></i>`
				}

				hideAllModals();

				const resultFinishReservation = await this.reservationRepository.finishReservation(this.reservation.id, noShow)

				if (noShow !== 1){
					await store.dispatch('menu/requestAccount', {
						reservationInfo: this.reservation,
						paymentMethod: paymentMethod
					});

					if (resultFinishReservation.result === 0) {
						// store.commit("copilot/setToast", {
						// 	toast: {
						// 		toastMessage: "Se ha finalizad la reserva correctamente",
						// 		showToast: true,
						// 		idToast: "correctlyToast"
						// 	}
						// });
						this.finishReservationTextButtonCash = 'Pago en efectivo';
						this.finishReservationTextButtonCard = 'Pago con tarjeta';

					}
				}
			} catch (e) {
				console.log(e)
			}
		},

		async viewDetailsUser(userUid) {
			event.stopPropagation();

			const result = await userRepository.find(userUid);
			if	(result.result === 0){
				store.commit('copilot/setSelectedReservationUserId',result.user)
			}
			this.$nextTick(() => {
				showModal('view-details-user');
			})
		},
	}
}
</script>
