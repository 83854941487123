<template>
	<div class="offcanvas offcanvas-bottom" tabindex="-1" :id="id">
		<div class="offcanvas-header">
			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body">
			<slot name="body"></slot>
		</div>
		<div class="offcanvas-footer">
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "OffcanvasBottomProductWrapper",
	components: {},
	props:{
		id: {
			type: String,
			required: true
		},
	},
}
</script>

<style scoped>

</style>
