<template src="./copilotContainerReservation.html"></template>

<script>


import CopilotCardReservation from "@/components/copilotCardReservation/CopilotCardReservation";
import TableIcon from "@/core/icons/TableIcon";
import {useI18n} from "vue-i18n";
import {computed} from "vue";

export default {
	name: 'CopilotContainerReservation',
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
		dateFormatterService: 'dateFormatterService',
	},
	emits: [],
	props: {
		service: Object,
	},
	data() {
		return {
			isLoad: false,
			paidAccount: false,
			viewClosedAccounts: false,
			currentShow: this.safeServiceName,
			reservationsOptions: [
				{key: this.safeServiceName, label: 'Durante el servicio', value:  this.safeServiceName, fnc: 'serviceReservations'},
				{key: 'all', label: 'Ver todas', value: 'all', fnc: 'allReservations'},
				{key: 'reservationsToArrive', label: 'Reservas por llegar', value: 'reservationsToArrive', fnc: 'reservationsToArrive'},
				{key: 'seated', label: 'Sentadas', value: 'seated', fnc: 'seatedReservations'},
				{key: 'finalized', label: 'Finalizadas', value: 'finalized', fnc: 'finalizedReservations'},
				{key: 'cancelled', label: 'Canceladas', value: 'cancelled', fnc: 'cancelledReservations'},
				{key: 'walkin', label: 'Walk in', value: 'walkin', fnc: 'walkinReservations'},
			],
			isServiceOpen: false,
			currentHour: new Date().getHours(),

			selectedAreaFilter: 'hour'

		};
	},
	computed: {
		dropdownButtonContent(){
			const item = this.reservationsOptions.find(item => item.key === this.currentShow);
			return item ? item.label : null;
		},
		currentReservations() {
			const selectedOption = this.reservationsOptions.find(option => option.value === this.currentShow);

			if (selectedOption) {
				// this.dropdownButtonContent = selectedOption.label;
				return this[selectedOption.fnc];
			} else {
				console.error('Opción no válida:', this.currentShow);
				return null;
			}
		},
		allReservations() {
			if (!this.service || !this.service.serviceReservations) return null;

			const totalReservations = this.service.serviceReservations;
			const totalPax = totalReservations.reduce((total, reservation) => total + (reservation.pax || 0), 0);

			return totalPax > 0 ? { totalReservations, totalPax } : null;
		},
		reservationsToArrive() {
			if (!this.service || !this.service.serviceReservations) return null;
			const totalReservations = this.service.serviceReservations.filter(reservation => {
				return reservation.menuAccessEnabled !== 1 && !reservation.finishedDateTime && reservation.cancelledDateTime === null && reservation.status !== -4;
			});
			const totalPax = totalReservations.reduce((total, reservation) => total + reservation.pax, 0);
			return totalPax > 0 ? { totalReservations, totalPax } : null;
		},
		seatedReservations() {
			if (!this.service || !this.service.serviceReservations) return null;
			const totalReservations = this.service.serviceReservations.filter(reservation => {
				return reservation.menuAccessEnabled === 1 && !reservation.finishedDateTime;
			});
			const totalPax = totalReservations.reduce((total, reservation) => total + reservation.pax, 0);
			return totalPax > 0 ? { totalReservations, totalPax } : null;
		},
		finalizedReservations() {
			if (!this.service || !this.service.serviceReservations) return null;
			const totalReservations = this.service.serviceReservations.filter(reservation => reservation.finishedDateTime !== null);
			const totalPax = totalReservations.reduce((total, reservation) => total + reservation.pax, 0);
			return totalPax > 0 ? { totalReservations, totalPax} : null;
		},
		walkinReservations() {
			if (!this.service || !this.service.serviceReservations) return null;
			const totalReservations = this.service.serviceReservations.filter(reservation => {
				return reservation.id.includes("WALKIN");
			});
			const totalPax = totalReservations.reduce((total, reservation) => total + reservation.pax, 0);
			return totalPax > 0 ? { totalReservations, totalPax } : null;
		},
		noShowReservations() {
			if (!this.service || !this.service.serviceReservations) return null;
			const totalReservations = this.service.serviceReservations.filter(reservation => reservation.status === -4);
			const totalPax = totalReservations.reduce((total, reservation) => total + reservation.pax, 0);
			return totalPax > 0 ? { totalReservations, totalPax } : null;
		},
		reserveReservation() {
			if (!this.service || !this.service.serviceReservations) return null;
			const totalReservations = this.service.serviceReservations.filter(reservation => {
				return !reservation.id.includes("WALKIN") ;
			});
			const totalPax = totalReservations.reduce((total, reservation) => total + reservation.pax, 0);
			return totalPax > 0 ? { totalReservations, totalPax } : null;
		},
		cancelledReservations() {
			if (!this.service || !this.service.serviceReservations) return null;
			const totalReservations = this.service.serviceReservations.filter(reservation => {
				return reservation.cancelledDateTime !== null;
			});
			const totalPax = totalReservations.reduce((total, reservation) => total + reservation.pax, 0);
			return totalPax > 0 ? { totalReservations, totalPax } : null;
		},
		serviceReservations() {
			if (!this.service || !this.service.serviceReservations) return null;
			const totalReservations = this.service.serviceReservations.filter(reservation => !reservation.cancelledDateTime && reservation.status !== -1);
			const totalPax = totalReservations.reduce((total, reservation) => total + reservation.pax, 0);
			return totalPax > 0 ? { totalReservations, totalPax } : null;
		},
		emptyStateDescription() {
			const option = this.reservationsOptions.find(option => option.value === this.currentShow);
			if (!option) return "No hay reservas disponibles."; // Default empty message

			//TODO: Ver como hacemos esto
			switch (option.value) {
				case 'all':
					return 'No hay reservas disponibles para mostrar.';
				case 'reservationsToArrive':
					return 'No hay reservas pendientes de llegada para este día.';
				case 'seated':
					return 'No hay clientes sentados en este momento.';
				case 'finalized':
					return 'No se han finalizado reservas hoy.';
				case 'cancelled':
					return 'No hay reservas canceladas para mostrar.';
				case 'walkin':
					return `No hay registros de walk-in para hoy.`;
				default:
					return `No hay reservas durante ${option.label} para este día.`;
			}
		},
		reservationCategories() {
			if (!this.service || !this.service.serviceReservations) return [];

			const categories = [
				{ key: 'serviceReservations', label: 'Durante el servicio'},
				{ key: 'allReservations', label: 'Reservas' },
				{ key: 'reservationsToArrive', label: 'Faltan por llegar' },
				{ key: 'seatedReservations', label: 'Sentadas' },
				{ key: 'finalizedReservations', label: 'Finalizadas' },
				{ key: 'cancelledReservations', label: 'Canceladas' },
				{ key: 'walkinReservations', label: 'Walk in' }
			];

			return categories.map(cat => ({
				label: cat.label,
				totalReservations: this[cat.key]?.totalReservations?.length || 0,
				totalPax: this[cat.key]?.totalPax || 0,
			}));
		},
		sortedActiveReservations() {
			if (!this.currentReservations || !this.currentReservations.totalReservations) return [];


			// Crea una copia del array y ordénala
			return [...this.currentReservations.totalReservations]
				// Filtra solo las reservaciones con status mayor a 0 y que no están finalizadas
				.filter(reservation => reservation.status > 0 && reservation.finishedDateTime === null)
				// Ordena las reservaciones según los criterios de fecha finalizada y estado 7
				.sort((a, b) => {
					// Si uno de los dos tiene status 7 y el otro no, prioriza el que no tiene status 7
					if (a.status === 7 && b.status !== 7) {
						return 1;
					} else if (a.status !== 7 && b.status === 7) {
						return -1;
					}

					// Ordena según las fechas finalizadas, poniendo las finalizadas al final
					if (a.finishedDateTime && !b.finishedDateTime) {
						return 1;
					} else if (!a.finishedDateTime && b.finishedDateTime) {
						return -1;
					} else {
						return 0;
					}
				});
		},

		sortedNoShowReservations() {
			if (!this.currentReservations || !this.currentReservations.totalReservations) return [];


			// Crea una copia del array y ordénala
			return [...this.currentReservations.totalReservations]
				.filter(reservation => reservation.status === -4 )
				.sort((a, b) => {
					if (a.finishedDateTime && !b.finishedDateTime) {
						return 1;
					} else if (!a.finishedDateTime && b.finishedDateTime) {
						return -1;
					} else {
						return 0;
					}
				});
		},
		sortedFinishReservations() {
			if (!this.currentReservations || !this.currentReservations.totalReservations) return [];

			// Crea una copia del array y ordénala
			return [...this.currentReservations.totalReservations]
				.filter(reservation => reservation.finishedDateTime !== null )
				.sort((a, b) => {
					if (a.finishedDateTime && !b.finishedDateTime) {
						return 1;
					} else if (!a.finishedDateTime && b.finishedDateTime) {
						return -1;
					} else {
						return 0;
					}
				});
		}
	},
	components: {
		'copilot-card-reservation': CopilotCardReservation,
		'table-icon': TableIcon,
	},
	setup(props) {
		const { t } = useI18n();

		const safeServiceName = computed(() => {
			return props.service.serviceName.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase();
		});

		return {
			t,
			safeServiceName
		};
	},
	async mounted() {
		this.isLoad = true;
		// this.updateCurrentHour();
		// setInterval(this.updateCurrentHour, 60000);
		// this.openSectionByHour();
	},
	methods: {
		applyFilter(filterType) {
			this.selectedAreaFilter = filterType;
			this.$emit('filterSelected', filterType);
			localStorage.setItem('selectedAreaFilter', filterType);
		},
		showReservationList(show) {
			this.currentShow = show;
		},
		handleViewClosedAccounts() {
			this.viewClosedAccounts = !this.viewClosedAccounts;
		},
		isOpen(serviceName) {
			const panel = document.getElementById(`collapse_${serviceName}`);
			return panel && panel.classList.contains('show');
		},
		openSectionByHour() {
			if (this.currentHour >= 7 && this.currentHour < 13) {
				this.openSectionByService('Desayuno');
			} else if (this.currentHour >= 13 && this.currentHour < 18) {
				this.openSectionByService('Comida');
			} else if (this.currentHour >= 18) {
				this.openSectionByService('Cena');
			}
		},
		openSectionByService(serviceName) {
			const sectionId = `collapseService${serviceName}`;
			const sectionElement = document.getElementById(sectionId);
			const parentId = `parentCollapseServices${serviceName}`
			const parentElement = document.getElementById(parentId);

			if (sectionElement) {
				sectionElement.classList.add('show');
				parentElement.classList.remove('collapsed')
			}
		},
		updateCurrentHour() {
			this.currentHour = new Date().getHours();
			this.openSectionByHour();
		},
	},

}
</script>
