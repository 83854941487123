<template src="./generatorQr.html"></template>

<script>
import QRCodeVue from "vue-qrcode";
export default {
	components: {
		QRCodeVue
	},
	props: {
		link: String,
	},
	data() {
		return {}
	},
	watch: {
		link: {
			handler(newVal) {
				console.log('ha cambiado el link del QR a:',newVal)
			},
			deep: true
		},
	},
	beforeMount() {

	},
	mounted() {
		console.log('el link desde el generator QR', this.link)
	},
	methods: {
	}
}
</script>
