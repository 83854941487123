import {DateTime} from 'luxon';
import {reservationRepository} from "@/service/repository/reservationRepository";
import {copilotRepository} from "@/service/repository/copilotRepository";
import {groupItemsBySkuAndChoices, hideAllOffcanvas} from "@/utils";
import {menuRepository} from "@/service/repository/menuRepository";
import {cookieManager} from "@/service/cookieManager";
import {productOrderService} from "@/service/ProductOrderService";

const state = {
	selectedDate: DateTime.now().toISODate(),
	availableRoles: [],
	selectedRole: '0',
	reservations: [],
	notifications: [],
	accounts: [],
	assistantNotifications: [],
	isPlayNotificationSound: false,
	isLoadingGetReservations: false,
	isShowToastScanQr: null,
	isShowModalScanQr: null,
	toast: {
		showToast: false,
		toastMessage: "",
		idToast: ""
	},
	newNotification: null,
	commanderReservation: null,
	notificationMode: "0",
	pendingNotifications: [],
	tables: [],
	reservationId: null,
	serviceEvents: [],
	selectedProductOrdersToServe: [],
	selectedProductOrdersToPrepared: [],
	selectedProductOrdersToClient: [],
	productOrder: null,
	selectedReservationId: null,

	isRemoveProduct: false,
	localProductIdModalReject: null,

	reservationUserUid: null,
	showAllProductOrdersInKitchen: true,
	selectedStations:[]
};

const getters = {
	productOrder: state => {
		return state.productOrder
	},
	isRemoveProduct: state => {
		return state.isRemoveProduct
	},
	selectedReservationId: state => {
		return state.selectedReservationId
	},
	selectedReservation: state => {
		return state.reservations.find(reservation =>
			reservation.id === state.selectedReservationId
		)
	},
	selectedProductOrdersToServe: state => {
		return state.selectedProductOrdersToServe;
	},
	selectedProductOrdersToPrepared: state => {
		return state.selectedProductOrdersToPrepared;
	},
	selectedProductOrdersToClient: (state, getters) => {
		const readyProductOrdersIds = getters.serviceEventPendingService
			.flatMap(serviceEvent => serviceEvent.items)
			?.filter(productOrder => productOrder.statusProductOrder < 100 && productOrder.requirementProductCopilotRoles
				?.some(role => role.name === getters.getSelectedRole?.name))
			?.map(productOrder => productOrder.productOrderId) || [];

		return state.selectedProductOrdersToClient.filter(productOrderId => readyProductOrdersIds.includes(productOrderId));
	},
	hotTable: state => {
		const STATUS_IN_HOT_TABLE = 7;
		let selectedRole = getters.getSelectedRole(state);
		return state.reservations
			.flatMap(reservation => reservation.cartSession ? reservation.cartSession.productOrders : [])
			.filter(order => order)
			.flatMap(order => {
				return order.items
					.filter(item => {
						return !productOrderService.checkIsGhostProductOrder(item) && item.statusProductOrder === STATUS_IN_HOT_TABLE && item.requirementProductCopilotRoles?.some(role => role.name === selectedRole.name)
					})
					.map(item => ({
						...item,
						createdDateTime: order.createdDateTime
					}));
			})
			.sort((a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime));
	},
	selectedDate: state => {
		return state.selectedDate;
	},
	showTicketRequestNotifications: (state, getters) => {
		console.log("El rol seleccionado ", state.selectedRole);

		const selectedRoleObject = getters.getSelectedRole;

		console.log("Objeto del rol seleccionado", selectedRoleObject);

		// Assuming isKitchenSelectedRole is also a getter
		return !getters.isKitchenSelectedRole;// "Esto no lo tengo tan seguro porque el del TPV imprime el ticket de una cuenta ->" || !selectedRoleObject?.name.includes("TPV + Barra");
	},
	getSelectedRole: state => {
		if (!state || !state.availableRoles || state.availableRoles.length === 0) {
			return null;
		}
		return state.availableRoles.find(role => role.id === state.selectedRole);
	},
	isKitchenSelectedRole: state => {
		const kitchenRoleObject = state.availableRoles.find(role => role.name === 'Cocina');
		const kitchenRole = kitchenRoleObject ? kitchenRoleObject.id : null;
		return state.selectedRole === kitchenRole;
	},
	isOnlyRoom: state => {
		const onlyRoomRoleObject = state.availableRoles.find(role => role.name === '1 en Sala');
		const onlyRole = onlyRoomRoleObject ? onlyRoomRoleObject.id : null;
		return state.selectedRole === onlyRole;
	},
	pendingOrders: (state, getters) => {
		const statusCartSessionRoleTypes = getters.isKitchenSelectedRole ? [2] : [1, 3];
		const orders = state.reservations
			.flatMap(reservation => reservation.cartSession ? reservation.cartSession.productOrders : [])
			.filter(order => order && statusCartSessionRoleTypes.includes(order.cartSessionStatus))
			.map(order => {
				if (order.cartSessionStatus > 1) {
					return {
						...order,
						items: order.items.filter(item => item.type === 'Comida')
					};
				}
				return order;
			})
			.sort((a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime))

		// Group items by SKU and choices
		const groupedOrders = orders.map(order => ({
			...order,
			items: groupItemsBySkuAndChoices([order])
		}));

		return groupedOrders;
	},
	allOrderProducts(state) {
		return state.reservations
			.flatMap(reservation => reservation.cartSession ? reservation.cartSession.productOrders : [])
			.filter(order => order)
			.flatMap(order => {
				return order.items.map(item => ({
					...item,
					createdDateTime: order.createdDateTime
				}));
			})
			.sort((a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime));
	},
	availableRoles: state => {
		return state.availableRoles;
	},
	selectedRole: state => {
		return state.selectedRole;
	},
	reservations: state => {
		return state.reservations.slice().sort((a, b) => {
			return new Date(a.startDateTime) - new Date(b.startDateTime);
		});
	},
	notifications: state => {
		return state.notifications;
	},
	accounts: state => {
		return state.accounts;
	},
	assistantNotifications: state => {
		return state.assistantNotifications;
	},
	isPlayNotificationSound: state => {
		return state.isPlayNotificationSound;
	},
	isLoadingGetReservations: state => {
		return state.isLoadingGetReservations;
	},
	isShowToastScanQr: state => {
		return state.isShowToastScanQr;
	},
	toast: state => {
		return state.toast;
	},
	newNotification: state => {
		return state.newNotification;
	},
	menusVenue: state => {
		return state.venueMenus;
	},
	commanderReservation: state => {
		return state.commanderReservation;
	},
	notificationMode: state => {
		return state.notificationMode;
	},
	pendingNotifications: state => {
		return state.pendingNotifications;
	},
	tables: state => {
		return state.tables;
	},
	reservationId(state) {
		return state.reservationId;
	},
	reservationUserUid(state){
		return state.reservationUserUid
	},
	reservationsWithProducts: (state) => {
		return state.reservations
			.filter(reservation => reservation.status > 0)
			.map(reservation => ({
				...reservation,
				orderedItems: reservation.cartSession
					? reservation.cartSession.productOrders.flatMap(order =>
						order.items.map(item => ({
							...item,
							createdDateTime: order.createdDateTime,
							table: reservation.table,
						}))
					)
					: []
			}))
			.sort((a, b) => {
				const minDateA = a.orderedItems
					.filter(item => item.statusProductOrder < 100)
					.map(item => new Date(item.createdDateTime.replace(' ', 'T')))
					.reduce((min, current) => current < min ? current : min, new Date(8640000000000000)); // Máxima fecha posible

				const minDateB = b.orderedItems
					.filter(item => item.statusProductOrder < 100)
					.map(item => new Date(item.createdDateTime.replace(' ', 'T')))
					.reduce((min, current) => current < min ? current : min, new Date(8640000000000000)); // Máxima fecha posible

				return minDateA - minDateB;
			});
	},
	// reservationsWithProducts: (state) => {
	// 	return state.reservations
	// 		.filter(reservation =>
	// 			reservation.status > 0 &&
	// 			reservation.cartSession &&
	// 			reservation.cartSession.productOrders.some(order => order.cartSessionStatus === 1) // Filtrar por cartSessionStatus
	// 		)
	// 		.map(reservation => ({
	// 			...reservation,
	// 			orderedItems: reservation.cartSession
	// 				? reservation.cartSession.productOrders
	// 					.filter(order => order.cartSessionStatus === 1) // Solo incluir productOrders con cartSessionStatus === 1
	// 					.flatMap(order =>
	// 						order.items.map(item => ({
	// 							...item,
	// 							createdDateTime: order.createdDateTime
	// 						}))
	// 					)
	// 				: []
	// 		}))
	// 		.sort((a, b) => {
	// 			const minDateA = a.orderedItems
	// 				.filter(item => item.statusProductOrder < 100)
	// 				.map(item => new Date(item.createdDateTime.replace(' ', 'T')))
	// 				.reduce((min, current) => current < min ? current : min, new Date(8640000000000000)); // Máxima fecha posible
	//
	// 			const minDateB = b.orderedItems
	// 				.filter(item => item.statusProductOrder < 100)
	// 				.map(item => new Date(item.createdDateTime.replace(' ', 'T')))
	// 				.reduce((min, current) => current < min ? current : min, new Date(8640000000000000)); // Máxima fecha posible
	//
	// 			return minDateA - minDateB;
	// 		});
	// }
	serviceEvents: state => {
		return state.serviceEvents
	},
	serviceEventPendingService(state) {
		return state.serviceEvents
			.filter(serviceEvent => serviceEvent.serviceEventStatus !== 100)
			.map(serviceEvent => {
				const selectedRoleObject = state.availableRoles.find(role => role.id === state.selectedRole);

				const servesDrinks = selectedRoleObject?.notificationTypes
					? selectedRoleObject.notificationTypes.some(notificationType => notificationType.name === "order_ready_drinks")
					: false;

				const servesFood = selectedRoleObject?.notificationTypes
					? selectedRoleObject.notificationTypes.some(notificationType => notificationType.name === "order_ready_food")
					: false;

				const itemsFiltered = serviceEvent.items.filter(item =>
					state.selectedRole === "0" ||
					//TODO: Pendiente, esto es sala en cocina
					selectedRoleObject.name.includes('Cocina') ||
					item.requirementProductCopilotRoles
						.some(role => servesFood && role.name === "Cocina" || servesDrinks && role.name === "Barra"));

				return {
					...serviceEvent,
					items: itemsFiltered
				};
			})
			.filter(serviceEvent => serviceEvent.items.length > 0);

	},
	view(state) {
		const role = state.availableRoles.find(role => role.id === state.selectedRole);
		hideAllOffcanvas();
		return role && role.copilotView && role.copilotView.name ? role.copilotView.name : null;
	},
	elaborationStations(state) {
		const role = getters.getSelectedRole(state);
		return role && role.elaborationStations ? role.elaborationStations : [];
	},
	reservationsWithComments(state) {
		return state.reservations.map(reservation => {
			const cartSessionOrders = reservation.cartSession?.productOrders.map(cartSessionOrder => {
				const items = cartSessionOrder.items.map(item => item.name).join(', ');
				const isAllProductsServed = cartSessionOrder.items.every(item => item.statusProductOrder === 100);
				const createdDateTime = cartSessionOrder.createdDateTime;
				const clientMessage = cartSessionOrder.clientMessage || null;
				return {createdDateTime, isAllProductsServed, items, clientMessage}
			})
			return {reservationId: reservation.id, table: reservation.table, cartSessionOrders}
		})
	},
	localProductIdModalReject: state => {
		return state.localProductIdModalReject
	},
	showAllProductOrdersInKitchen(state) {
		return state.showAllProductOrdersInKitchen
	},
	selectedStations(state) {
		return state.selectedStations
	},
};

const mutations = {
	setProductOrder(state, payload) {
		state.productOrder = payload.productOrder;
	},
	setSelectedReservationId(state, payload) {
		state.selectedReservationId = payload.reservationId
	},
	setSelectedReservationUserId(state, payload){
		console.log(payload)
		state.reservationUserUid = payload
	},
	setIsRemoveProduct(state, value) {
		state.isRemoveProduct = value;
	},
	setSelectedDate(state, payload) {
		state.selectedDate = payload.selectedDate;
	},
	setAvailableRoles(state, payload) {
		state.availableRoles = payload.availableRoles;
	},
	setSelectedRole(state, payload) {
		state.showAllProductOrdersInKitchen = true;
		state.selectedStations = []
		state.selectedReservationId = null
		cookieManager.setCookie('selectedRole', payload.selectedRole)
		state.selectedRole = payload.selectedRole;
	},
	setSelectedLocale(state, payload) {
		cookieManager.setCookie('selectedLocale', payload.selectedLocale)
		state.selectedLocale = payload.selectedLocale;
	},
	setReservations(state, payload) {
		state.reservations = payload.reservations;
		state.isLoadingGetReservations = false;
	},
	setServiceEvents(state, payload) {
		state.serviceEvents = payload.serviceEvents;
	},
	setNotifications(state, payload) {
		if (state.notifications.length === 0 && payload.notifications.length > 0) {
			state.isPlayNotificationSound = true;
		}
		state.notifications = payload.notifications;
	},
	setIsPlayNotificationSound(state, payload) {
		state.isPlayNotificationSound = payload.isPlayNotificationSound;
	},
	setIsLoadingGetReservations(state, payload) {
		state.isLoadingGetReservations = payload.isLoadingGetReservations;
	},
	setAccounts(state, payload) {
		if (state.accounts.length === 0 && payload.accounts.length > 0) {
			state.isPlayNotificationSound = true;
		}
		state.accounts = payload.accounts;
	},
	setAssistantNotifications(state, payload) {
		state.assistantNotifications = payload.assistantNotifications;
	},
	setIsShowToastScanQr(state, payload) {
		state.isShowToastScanQr = payload.isShowToastScanQr;
	},
	setToast(state, payload) {
		state.toast = payload.toast;
	},
	setNewNotification(state, payload) {
		state.isPlayNotificationSound = true;
		state.newNotification = payload.newNotification;
	},
	setVenueMenus(state, payload) {
		state.venueMenus = payload.venueMenus;
	},
	setCommanderReservation(state, payload) {
		console.log('Desde el modulo seteando: ', payload)
		state.commanderReservation = payload.commanderReservation;
		console.log('Ya seteado: ', state.commanderReservation)
	},
	setNotificationMode(state, payload) {
		state.notificationMode = payload.notificationMode;
	},
	addNotificationToPendingNotifications(state, payload) {
		state.pendingNotifications.push(payload.notification)
	},
	deleteNotificationToPendingNotifications(state, payload) {
		payload.notificationId ?
			state.pendingNotifications = state.pendingNotifications.filter(notification =>
				notification.id !== payload.notificationId)
			: state.pendingNotifications = []
	},
	setTables(state, payload) {
		state.tables = payload.tables;
	},
	setReservationId(state, id) {
		state.reservationId = id;
	},
	setSelectedProductOrdersToServe(state, payload) {
		state.selectedProductOrdersToServe = payload.selectedProductOrdersToServe;
	},
	setSelectedProductOrdersToPrepared(state, payload) {
		state.selectedProductOrdersToPrepared = payload.selectedProductOrdersToPrepared;
	},
	setSelectedProductOrdersToClient(state, payload) {
		state.selectedProductOrdersToClient = payload.selectedProductOrdersToClient();
	},
	updateProductOrdersStatus(state, payload) {
		console.log('vamos a actualizar los productsOrders y este es el payload', payload)
		state.reservations = state.reservations.map(reservation => {
			if (!reservation.cartSession || !Array.isArray(reservation.cartSession.productOrders)) {
				return reservation;
			}

			return {
				...reservation,
				cartSession: {
					...reservation.cartSession,
					productOrders: reservation.cartSession.productOrders.map(productOrder => {
						if (!Array.isArray(productOrder.items)) {
							return productOrder;
						}

						return {
							...productOrder,
							items: productOrder.items.map(item => {
								if (payload.productOrdersIdsToUpdate.includes(item.productOrderId)) {
									return {
										...item,
										statusProductOrder: payload.status
									};
								}
								return item;
							})
						};
					})
				}
			};
		});
	},
	updateSingleProductOrder(state, payload) {

		// const selectedRoleObject = state.availableRoles.find(role => role.id === state.selectedRole);
		// const selectedRoleName = selectedRoleObject ? selectedRoleObject.name : null;

		state.reservations = state.reservations.map(reservation => {
			if (!reservation.cartSession || !Array.isArray(reservation.cartSession.productOrders)) {
				return reservation;
			}

			return {
				...reservation,
				cartSession: {
					...reservation.cartSession,
					productOrders: reservation.cartSession.productOrders.map(productOrder => {
						if (!Array.isArray(productOrder.items)) {
							return productOrder;
						}

						return {
							...productOrder,
							items: productOrder.items.map(item => {
								if (payload.productOrderIdToUpdate === item.productOrderId) {
									return {
										...item,
										statusProductOrder: payload.status,
									};
								}
								return item;
							})
						};
					})
				}
			};
		});

		state.serviceEvents = state.serviceEvents.map(serviceEvent => {
			const items = serviceEvent.items.map(item => {
				if (payload.productOrderIdToUpdate === item.productOrderId) {
					return {
						...item,
						statusProductOrder: payload.status,
					};
				}
				return item;
			})
			return {...serviceEvent, items}
		})
	},
	addServiceEvent(state, payload) {
		state.serviceEvents.push(payload.serviceEvent);
	},
	updateReservation(state, payload) {
		state.reservations = state.reservations.map(reservation => {
			return reservation.id === payload.updatedReservation.id ? payload.updatedReservation : reservation
		});
	},
	updateServiceEvent(state, payload) {
		state.serviceEvents = state.serviceEvents.map(serviceEvent => {
			return serviceEvent.serviceEventId === payload.serviceEvent.serviceEventId ? payload.serviceEvent : serviceEvent
		});
	},
	addReservationToReservations(state, payload) {
		const logicalDayStartHour = 6;
		const logicalDayStart = DateTime.fromFormat(state.selectedDate, 'yyyy-MM-dd').plus({hours: logicalDayStartHour});
		const reservationTime = DateTime.fromFormat(payload.reservation.startDateTime, 'yyyy-MM-dd HH:mm:ss');
		const selectedDayStart = logicalDayStart.minus({days: reservationTime.hour < logicalDayStartHour ? 1 : 0});
		const selectedDayEnd = logicalDayStart.plus({days: 1});

		const isSameLogicalDay = reservationTime >= selectedDayStart && reservationTime < selectedDayEnd;

		if (isSameLogicalDay) {
			state.reservations.push(payload.reservation);
		}
	},
	setLocalProductIdModalReject(state, product) {
		if (!product || !product.reservationId || !product.orderId) {
			console.log('Datos de producto insuficientes');
			state.localProductIdModalReject = null;
			return;
		}

		const reservation = state.reservations.find(
			reservation => reservation.id === product.reservationId
		);

		if (!reservation) {
			console.log('Reserva no encontrada');
			state.localProductIdModalReject = null;
			return;
		}

		if (reservation.cartSession) {
			for (let order of reservation.cartSession.productOrders) {
				const item = order.items.find(
					item => item.productOrderId === product.orderId
				);

				if (item) {
					state.localProductIdModalReject = item; // Asigna directamente el producto encontrado
					console.log('Producto encontrado:', item);
					return;
				}
			}
			console.log('Producto no encontrado en la orden');
			state.localProductIdModalReject = null; // Asigna null si no se encuentra el producto
		} else {
			console.log('El estado del cartSession no es mayor que 1');
			state.localProductIdModalReject = null;
		}
	},
	toggleProductOrderSelection(state, productOrderId) {
		const index = state.selectedProductOrdersToServe.indexOf(productOrderId);
		if (index !== -1) {
			// Deselect the product if it is already selected
			state.selectedProductOrdersToServe.splice(index, 1);
		} else {
			// Select the product if it is not yet selected
			state.selectedProductOrdersToServe.push(productOrderId);
		}
	},
	clearSelectedProductOrdersToServe(state) {
		state.selectedProductOrdersToServe = [];
	},
	toggleProductOrderToClientSelection(state, productOrderId) {
		const index = state.selectedProductOrdersToClient.indexOf(productOrderId);
		if (index !== -1) {
			// Deselect the product if it is already selected
			state.selectedProductOrdersToClient.splice(index, 1);
		} else {
			// Select the product if it is not yet selected
			state.selectedProductOrdersToClient.push(productOrderId);
		}
	},
	clearSelectedProductOrdersToClient(state) {
		state.selectedProductOrdersToClient = [];
	},
	deleteProductOrderFromSelectedProductOrdersToServe(state, payload) {
		state.selectedProductOrdersToServe = state.selectedProductOrdersToServe
			.filter(productOrderId => productOrderId !== payload.productOrderId);
	},
	setShowAllProductOrdersInKitchen(state, payload) {
		state.showAllProductOrdersInKitchen = payload.show;
	},
	setSelectedStations(state, payload) {
		state.selectedStations = payload.selectedStations;
	},
};

const actions = {
	// changeSelectedRole({ commit }, roleId) {
	// 	commit('setSelectedRole', roleId);
	// },
	addProductOrder({commit}, productOrder) {
		commit('setProductOrder', productOrder)
	},

	addReservationUserUid({commit}, uid) {
		commit('setSelectedReservationUserId', uid)
	},

	async initAvailableRoles({commit}, {user}) {
		if (user != null && user.copilotRoles != null && user.copilotRoles.length > 0) {
			console.log("Leyendo roles del usuario:", user.copilotRoles)
			commit('setAvailableRoles', {availableRoles: user.copilotRoles});

			user.copilotRoles.forEach(role => {
				if (role.active === 1) {
					console.log("El usuario " + user.name + " tiene activo el rol " + role.name);
					commit('setSelectedRole', {selectedRole: role.id});
				}
			});

		} else {
			try {
				console.log("No hay usuario, trayendo todos los roles del restaurante")
				let roleResponse = await copilotRepository.getRolesCopilot()
				commit('setAvailableRoles', {availableRoles: roleResponse.copilot_roles});
			} catch (e) {
				console.log("pues hay excepcion", e)
			}
		}
	},

	async updateReservations({commit}) {
		try {
			commit('setIsLoadingGetReservations', {isLoadingGetReservations: true});
			console.log('La fecha desde', state.selectedDate)
			const resultGetByDay = await reservationRepository.getReservationsByDay(state.selectedDate);

			if (resultGetByDay === 401) {
				return window.location.href = origin + '/login';
			}

			if (resultGetByDay === 403) {
				return alert('No estas autorizado para acceder a copilot');
			}

			commit('setReservations', {reservations: resultGetByDay.reservations});
			commit('setServiceEvents', {serviceEvents: resultGetByDay.serviceEvents});

		} catch (e) {
			console.error(e)
		}
	},
	async updateOrdersAndNotifications({commit}) {
		try {
			console.log('Vamos a traer todos los pedidos y notificaciones: ')
			const result = await copilotRepository.getTakeAway(state.selectedDate);
			commit('setNotifications', {notifications: result.notifications});
			commit('setAccounts', {accounts: result.accounts});
			commit('setAssistantNotifications', {assistantNotifications: result.we});
			console.log(result.notifications)

		} catch (e) {
			console.log(e)
		}
	},
	async loadVenueMenus({commit}) {
		try {
			const menus = await menuRepository.getAvailableMenus();
			if (menus.result === 0) {
				console.log('desde la carga', menus);
				commit('setVenueMenus', {venueMenus: menus.menus});
			}
		} catch (error) {
			console.error("Failed to fetch venue menus", error);
		}
	},
	async getTables({commit}) {
		try {
			const rommsAndTablesResult = await copilotRepository.getTables();
			let rooms = rommsAndTablesResult.rooms;

			if (rooms.length >= 0) {
				console.log("Las rooms llegan como array")
			} else {
				rooms = Object.values(rooms);
			}
			const tables = rooms.flatMap(room => room.tables.map(table => ({
					...table,
					room: room.name
				}))
			);
			console.log('Las mesas: ', tables)
			if (rommsAndTablesResult.result === 0) {
				commit('setTables', {tables: tables});
			}
		} catch (error) {
			console.error("Failed to fetch venue menus", error);
		}
	},

	getIdReservation({commit}, id) {
		commit('setReservationId', id);
	},

	changeIsRemoveProduct({commit}, status) {
		commit('setIsRemoveProduct', status);
	},
	saveLocalProductIdModalReject({commit}, status) {
		commit('setLocalProductIdModalReject', status)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
};
