<div v-if="reservation.status !== 0" class="service-reservation-container" :id="'reservation-' + reservation.id" :data-bs-parent="`childrenCollapseService${serviceName}`">
	<div class="service-reservation container-fluid p-0" :class="{[reservationStatus(reservation)]:true,'table-dubbing': hasDubbing}" @click="openCollapse(reservation.id)">
		<span v-if="pendingOrders && pendingOrders.length > 0 && isReservationRequest" class="alert-reserve order">
			Pedido
		</span>

		<span v-if="notifications[0] && notifications[0].reservation.id === reservation.id" class="alert-reserve text-dark process-pay">
			Cuenta pedida / {{ paymentMethodName(notifications[0].message) }}
		</span>

		<div class="row g-0">
			<div class="col-1">
				<div class="icon-reservation">
					<i v-if="isWalkin" class="fas fa-walking "></i>
					<SittingIcon fill-color="white" v-if="isSitting"/>
					<i v-if="isReserve" class="fas fa-qrcode"></i>
				</div>
			</div>
			<div class="col-2">
				<div class="ps-1">{{ reservation.startDate }}h</div>
			</div>
			<div class="col-2">
				<button :disabled="reservationFinishDisabled" class="btn btn-light w-100 btn-sm" @click="openModalUpdatePax(reservation)">
					<i class="fas fa-user"></i> {{ reservation.pax }}
				</button>
			</div>
			<div class="col-7">
				<copilot-card-select-table :reservation="reservation"></copilot-card-select-table>
			</div>
		</div>

		<div class="row g-0">
			<div class="col-9">
				<div class="service-meta text-truncate" v-if="reservation.paymentInfo.payments && reservation.paymentInfo.payments.length">
					Pago:
					<span v-for="payment in reservation.paymentInfo.payments">
						<span v-if="payment && payment.methodName === 'Efectivo' && payment.type === 'Local'"><i :class="{'text-success':payment.status === 100}" class="fa-regular fa-money-bill-1"></i></span>
						<span v-if="payment && payment.methodName === 'Tarjeta' && payment.type === 'Local'"><i :class="{'text-success':payment.status === 100}" class="fa-solid fa-credit-card"></i></span>
						<span v-if="payment && payment.type === 'Online'"><i :class="{'text-success':payment.status === 100}" class="fa-solid fa-mobile-screen-button"></i></span>
					</span>
				</div>
			</div>
			<div class="col-3">
				<div class="service-meta" v-if="reservationReview">
					Media:
					<i class="fas fa-star" style="color: var(--bs-secondary)"></i>
					<b>{{ reservationReview }}</b>
				</div>
			</div>

			<div class="col-12" v-if="reservations.status === 7">
				La reserva se moverá a 'Finalizadas' automáticamente.
			</div>
		</div>

		<div class="collapse" :id="'collapse' + reservation.id">
			<div class="container-fluid g-0 row-column-gap">
				<div class="row g-0">
					<div class="col-1">
						<div class="icon-invitation">
							<i class="fas fa-users "></i>
						</div>
					</div>
					<div class="col-11">
						<span v-if="reservation.reservationUsers && invitationText.length">
							<span v-for="(diner, index) in invitationText" :key="diner.userId">
								<span v-if="diner.reviewScore" class="mx-1 fw-bold">
									<i class="fas fa-star me-1" style="color: var(--bs-secondary)"></i>{{ diner.reviewScore }}
								</span>

								<span v-if="diner.type === 1 && !reservation.id.includes('_')"
									  class="text-uppercase fw-bold ps-1"
									  style="cursor: pointer"
									  @click="viewDetailsUser(diner.userUid)">
									<i class="fa-solid fa-circle-info"></i>
									{{ diner.name }}
								</span>
								<span :class="diner.type === 1? 'text-uppercase fw-bold ps-1':''" v-else>{{ diner.name }}</span>

								<span v-if="index !== invitationText.length - 1">,&nbsp;</span>
								<span v-else>.</span>
							</span>
						</span>
						<span class="ms-1" v-else>No hay invitados</span>
					</div>
				</div>
			</div>
			<div class="container">
				<div v-if="pendingOrders && pendingOrders[0] && isReservationRequest" class="order-container order-container-copilot mt-3">
					<div class="order-data">
						<div class="order-data-body">
							<span>
								<i class="fas fa-utensils"></i> <b>{{ pendingOrders[0].userName }}</b>
							</span>
							<span>
								<i class="fas fa-clock"></i> {{ elapsedTime }}
							</span>
						</div>
					</div>
					<div class="products-list-content">
						<div v-if="groupedOrdersMenuDaily.length">
							<div class="border-bottom h5">Menus del día</div>
							<orders-product-menu-daily-card v-for="menu in groupedOrdersMenuDaily" source="notification" :menu="menu" :key="menu.productSku"></orders-product-menu-daily-card>
						</div>
						<div v-for="(orders, category) in groupedOrders" :key="category">
							<div v-if="orders.length" class="fw-bold">{{ category }}</div>
							<orders-product-card v-for="order in orders" source="notification" :order="order" :key="order.productSku"></orders-product-card>
						</div>
					</div>
					<button :disabled="isLoadingBtnOrder" @click="handleProcessOrder(pendingOrders[0].cartSessionOrderId)" class="btn btn-success w-100">
						<span class="process-button" v-html="buttonTextOrder"></span></button>
				</div>
			</div>
			<div class="container">
				<div class="row my-1 g-2">
					<copilot-cta-menu-finisher v-if="hasPendingMenus" :reservation-id="reservation.id"></copilot-cta-menu-finisher>
					<copilot-fireable-buttons :reservation="reservation"></copilot-fireable-buttons>
					<copilot-cta-enable-reservation :reservation="reservation"></copilot-cta-enable-reservation>
					<div class="btn-group col-12" role="group" aria-label="Small button group">
						<button
							:disabled="reservationFinishDisabled"
							class="btn btn-secondary w-100"
							@click.stop="reservation.id.includes('WALKIN') ? openQRInvitation(reservation) : handleOptionClick('camera', reservation.id)">
							<span v-if="reservation.id.includes('WALKIN')">{{ t('copilot.showQRInvitation', {showQR: t('copilot.showQR')}) }}</span>
							<span v-else>{{ t('copilot.scanReservationQR', {reservation: t('copilot.reservation')}) }}</span>
						</button>
						<button v-if="isReservationStatusStart && !isReservationCancelled && reservation.finishedDateTime === null && !isReservationFinish" class="btn btn-light w-100 " @click.stop="openComanderMenu" type="button">
							{{ t('copilot.takeOrder') }}
						</button>
					</div>
					<div class="btn-group col-12" role="group" aria-label="Small button group">
						<button class="btn btn-light w-100" v-if="isReservationStatusStart && !isReservationCancelled" type="button" @click="openOffcanvasHistoricAccount(reservation)">
							{{ t('copilot.checkAccount', {account: t('copilot.account')}) }}
						</button>
						<button v-if="!reservation.finishedDateTime && !reservation.cancelledDateTime && !isNoShow && reservation.menuAccessEnabled === 0" class="btn btn-light w-100" @click="handleFinishReservation(1)">{{ t('copilot.noShow') }}</button>
						<button v-if="!reservation.finishedDateTime && !reservation.cancelledDateTime && !isNoShow && reservation.menuAccessEnabled === 1 && !isReservationFinish" class="btn btn-light w-100" @click="openModalFinishReservation(reservation)">{{ t('copilot.requestBill', {account: t('copilot.account')}) }}</button>
						<button v-else v-if="isReservationStatusStart || isNoShow" class="btn btn-light w-100" @click="handleReactivateReservation">
							<span v-html="reactivateReservationTextButton"></span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="row g-0">
			<div class="col-1">
				<div class="icon-invitation" v-if="viewListUsers">
					<i class="fas fa-users "></i>
				</div>
			</div>
			<div class="col-10">
				<div class="service-meta guests text-truncate ps-1" v-if="viewListUsers">
					<span v-if="reservation.reservationUsers && invitationText.length">
						<span v-for="(diner, index) in invitationText" :key="diner.userId">
							<span v-if="diner.reviewScore" class="mx-1 fw-bold">
								<i class="fas fa-star me-1" style="color: var(--bs-secondary)"></i>{{ diner.reviewScore }}
							</span>

							<span v-if="diner.type === 1"
							   class="text-uppercase fw-bold">
								{{ diner.name }}
							</span>
							<span v-else>{{ diner.name }}</span>

							<span v-if="index !== invitationText.length - 1">,&nbsp;</span>
							<span v-else>.</span>
						</span>
					</span>
					<span v-else>No hay invitados</span>
				</div>
			</div>

			<div class="arrow col-1" :id="'containerArrow'+reservation.id">
				<i :id="'iconArrow'+reservation.id" class="fas fa-chevron-down"></i>
			</div>
		</div>
	</div>
</div>
