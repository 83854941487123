import {sendRequest, sendRequestJson} from '@/utils'

export class CopilotRepository {

	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"|| host.startsWith("192.168")){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
		console.log('CopilotRepository con urlBase: ', this.urlBase)
	}

	async getRolesCopilot() {
		const url = this.urlBase + "/world/api/copilotrole/list";
		try {
			return await sendRequestJson({data: null, url, method: "GET"});
		} catch (e) {
			console.log("Ups, algoo ha ido mal comprobando el role del usuario", e);
		}
	}

	async getTakeAway(date) {
		const url = this.urlBase + "/world/account/gettakeaway"
		try {
			const data = {date}
			return await sendRequest({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal en el getTakeAway");
		}
	}

	async setMessagePlayed(notificationId) {
		console.log('la notificacion desde el repo', notificationId)
		const url = this.urlBase + "/world/notification/setplayed"
		try {
			return await sendRequestJson({data:{notification: notificationId}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido dando por reproducida la notificación");
		}
	}

	async createKioskoWalkin(data) {
		const url = this.urlBase + "/world/api/kiosk/createwalkin"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal creando el walkin desde kiosko");
		}
	}

	async createWalkin(data) {
		const url = this.urlBase + "/world/reservation/createwalkin"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal creando el walkin desde kiosko");
		}
	}

	async registerKioskScanQr(data) {
		const url = this.urlBase + "/world/api/kiosk/register"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal resgistrando la entrada desde el quiosco");
		}
	}

	async registerDoorLog(data) {
		const url = this.urlBase + "/world/doorlog/register"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal resgistrando la entrada");
		}
	}

	async acknowledgeNotification(notificationId) {
		const url = this.urlBase + "/world/notification/ack";
		try {
			return await sendRequestJson({data:{id: notificationId}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido validando la notificacion");
		}
	}

	async getTables() {
		const url = this.urlBase + "/world/api/tables"
		try {
			return await sendRequestJson({data:null, url, method:"GET"});
		} catch (e) {
			console.log("Ups, algo ha ido mal en el getTables");
		}
	}

	async updateTable(data) {
		const url = this.urlBase + "/world/api/reservation/updatetable"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal modificando la mesa");
		}
	}

	async updateAvailabilityProduct(data) {
		const url = this.urlBase + "/world/api/product/menu/update-availability-product"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal actualizando el producto.");
		}
	}

	async updateTopChoiceProduct(data) {
		const url = this.urlBase + "/world/api/product/menu/update-top-choice-product"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal actualizando el producto.");
		}
	}


	async updateProductorderDishClass(data) {
		const url = this.urlBase + "/world/api/productorder/update-dish-class"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal actualizando el producto.");
		}
	}

	async markProductOrderAsRejected(data) {
		const url = this.urlBase + '/world/api/productorder/mark-as-rejected';

		try {
			return await sendRequestJson({data, url, method:"POST"})
		} catch (e) {
			console.log('No se ha podido quitar el producto')
		}
	}
	async markProductOrderAsCancelled(data) {
		const url = this.urlBase + '/world/api/productorder/mark-as-cancelled';

		try {
			return await sendRequestJson({data,url, method:"POST"})
		} catch (e) {
			console.log('No se ha podido quitar el producto')
		}
	}
	async markProductOrderAsLoss(data) {
		const url = this.urlBase + '/world/api/productorder/mark-as-loss';

		try {
			return await sendRequestJson({data , url, method:"POST"})
		} catch (e) {
			console.log('No se ha podido quitar el producto')
		}
	}

	async completeMenu(data) {
		const url = this.urlBase + '/world/api/productorder/complete-menu';

		try {
			return await sendRequestJson({data , url, method:"POST"})
		} catch (e) {
			console.log('No se ha podido completar el menu');
			return {result: -1, message:'No se ha podido completar el menu'}
		}
	}
}

export const copilotRepository = new CopilotRepository();
