<template>
	<div v-if="fireableDishClasses.length" class="btn-group col-12" role="group" aria-label="Small button group">
		<button :disabled="isLoadingDispatch" v-for="fireableDishClass in fireableDishClasses" :key="reservation.id + fireableDishClass.id" class="btn btn-light w-100 " type="button" @click.stop="dispatchFireable(fireableDishClass.id)">
			<span v-if="isLoadingDispatch">Solicitando...&nbsp;&nbsp;<i class="fas fa-spinner fa-spin"></i></span>
			<span v-else>Marchar {{fireableDishClass.name.toLowerCase()}}</span>
		</button>
	</div>
</template>

<script>
import store from "@/store";

export default {
	name: "CopilotFireableButtons",
	inject: {
		productOrderRepository: 'productOrderRepository',
	},
	props: {
		reservation: {
			type: Object,
			required: true
		}
	},
	data(){
		return {
			isLoadingDispatch: false,
			validProductOrderStatesForFireable: [1, 2, 7]
		}
	},
	mounted() {
		console.log('fireableDishClasses de: ', this.reservation.name, this.fireableDishClasses);
	},
	computed:{
		fireableDishClasses(){
			if (!this.reservation?.cartSession?.productOrders) {
				return [];
			}
			const dishClasses = this.reservation.cartSession.productOrders
				.flatMap(order => order.items)
				.filter(item =>  this.validProductOrderStatesForFireable.includes(item.statusProductOrder) && !item.dateFired)
				.map(item => item.dishClass)
				.filter(dishClass => dishClass && dishClass.fireable === 1);

			const uniqueDishClasses = Array.from(
				new Map(dishClasses.map(dish => [dish.id, dish])).values()
			);

			uniqueDishClasses.sort((a, b) => a.sortOrder - b.sortOrder);

			return uniqueDishClasses;
		}
	},
	methods:{
		async dispatchFireable(dishClassId){
			if (event) {
				event.stopPropagation();
			}
			this.isLoadingDispatch = true;

			const data = {reservationId: this.reservation.id, dishClassId}
			const resultDispatch = await this.productOrderRepository.dispatchFireableProductOrders(data);
			if(resultDispatch.result !== 0){
				store.commit("copilot/setToast", {toast:{toastMessage: 'Ha habido un error.', showToast:true, idToast: "incorrectlyToast"}});
				this.isLoadingDispatch = false;
			}
			this.isLoadingDispatch = false;
		}
	}
}
</script>


