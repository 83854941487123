import store from "@/store";
export class ProductOrderService {

	constructor() {
	}

	checkIsGhostProductOrder(productOrder){
		if (store.getters['copilot/showAllProductOrdersInKitchen']) return false;
		if(productOrder.elaborationStations.length === 0) return false
		const productElaborationStationIds = productOrder.elaborationStations.map(es => es.id);
		const hasCommonStations = productElaborationStationIds.some(id => store.getters['copilot/selectedStations'].includes(id));
		return !hasCommonStations;
	}
}

export const productOrderService = new ProductOrderService();
