<template>
	<div class="modal fade" v-if="product" id="modal-remove-product" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4">
						<i class="fa-solid fa-circle-exclamation"></i> {{ product.name }}
					</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<p class="text-center fw-bold" v-if="orderPreparingInKitchen">{{ product.name }} está en preparación</p>
					<p class="text-center fw-bold" v-if="orderReadyToServe">{{ product.name }} ya está listo para servir</p>

					<p class="text-center">¿Estás seguro de que quieres <b> {{ titleModal }} {{ product.name }}</b>?</p>
				</div>
				<div class="modal-footer">
					<DismissButton :is-modal="true" content-btn-text="Cancelar" />

					<button :disabled="isLoading" class="btn btn-primary" @click="handleClick">
						<span v-if="isLoading">
							Cargando...<SpinnerIcon/>
						</span>
						<span v-else>Sí, {{ titleModal }}</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {mapGetters} from "vuex";

import DismissButton from "@/core/DismissButton";
import {hideAllModals} from "@/utils";
import store from "@/store";
import SpinnerIcon from "@/core/icons/SpinnerIcon";

export default {
	name: 'ModalRemoveProduct',

	data() {
		return {
			isLoading: false,
		}
	},
	watch: {},
	props: {},
	inject: {
		copilotRepository: 'copilotRepository',
	},
	computed: {
		...mapGetters('copilot',['isRemoveProduct','localProductIdModalReject','reservations']),

		titleModal(){
			if (this.isRemoveProduct) {
				return 'Quitar'
			} else {
				return 'Mermar'
			}
		},

		orderPreparingInKitchen(){
			return this.product.statusProductOrder === 2
		},
		orderReadyToServe(){
			return this.product.statusProductOrder === 3
		},

		product() {
			return this.localProductIdModalReject
		}

	},
	components: {
		SpinnerIcon,
		DismissButton
	},
	beforeMount() {
	},
	mounted() {
	},
	methods: {
		async handleClick(){
			this.isLoading = true
			if (this.isRemoveProduct){
				console.log({id: this.product})
				const result = await this.copilotRepository.markProductOrderAsCancelled({productOrderId: this.product.productOrderId})
				console.log({result:result})
				if (result.result === 0) {
					hideAllModals()
					this.isLoading = false
				} else {
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: `No se ha podido ${this.titleModal} el producto correctamente.`,
							showToast: true,
							idToast: "incorrectlyToast"
						}
					});
					this.isLoading = false
				}

			}else {
				console.log({id: this.product})
				const result = await this.copilotRepository.markProductOrderAsLoss({productOrderId: this.product.productOrderId});
				console.log({result:result})
				if (result.result === 0) {
					hideAllModals()
					this.isLoading = false
				} else {
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: `No se ha podido ${this.titleModal} el producto correctamente.`,
							showToast: true,
							idToast: "incorrectlyToast"
						}
					});
					this.isLoading = false
				}
			}
		}
	}
}
</script>
