<template src="./reservationComponent.html"></template>

<script>

import * as bootstrap from 'bootstrap';
import {mapGetters, mapMutations} from "vuex";

import store from "@/store";

import {VueTelInput} from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

import {WebSocketClient} from "@/service/webSocketClient";

import { validateEmail} from "@/utils";


import DatePicker from "@/components/pickdate/DatePicker";
import TimePicker from "@/components/pickdate/TimePicker";
import NavBarTop from "@/components/navBarTop/NavBarTop";
import ModalCheckInbox from "@/components/mods/modalCheckInbox/ModalCheckInbox";
import ModalPrivacy from "@/components/mods/modalPrivacy/ModalPrivacy";
import ModalAvisoLegal from "@/components/mods/modalAvisoLegal/ModalAvisoLegal";
import HeaderComponent from "@/core/HeaderComponent";
import WidgetReserve from "@/views/widgetReserve/WidgetReserve";
import parsePhoneNumberFromString from "libphonenumber-js";
import FooterWelcom from "@/core/FooterWelcom";
import SpinnerIcon from "@/core/icons/SpinnerIcon";

export default {
	name: 'ReservationComponent',
	props: {
		source: {
			type: String,
			required: false,
			default: null
		},
	},
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
	},
	emits: ['clear-form'],
	data() {
		return {
			webSocketClient: WebSocketClient,
			reservationDate: '',
			reservationTime: '',
			reservationPax: null,
			reservationRoom: -1,
			signupGender: 0,
			signupName: null,
			signupSurname: null,
			signupTerms: false,
			isVisibleTimeSelect: false,
			isVisiblePhoneInput: false,
			// isVisibleGenderInput: false,
			isVisibleSurnameInput: false,
			isVisibleTermsInput: false,
			reservationMailWelkey: '',
			errorMessage: '',
			showErrorMessageDate: false,
			showErrorMessageWelKey: false,
			showErrorMessageForm: false,
			reserveButtonContent: 'Reservar',
			isSpinnerIconLoading: false,
			isVisibleSelectRooms: false,
			inputId: 'phone',
			classResponsiveAlert: 'col-12 col-md-10 col-lg-10',
			mailConfirmSave: null,
			isOverlappingReservation: null,
			userReservationUid: null
		};
	},
	components: {
		'navbar-top': NavBarTop,
		'footer-welcom': FooterWelcom,
		'modal-check-inbox': ModalCheckInbox,
		'modal-privacy': ModalPrivacy,
		'modal-aviso-legal': ModalAvisoLegal,
		DatePicker,
		TimePicker,
		VueTelInput,
		HeaderComponent,
		WidgetReserve,
		SpinnerIcon
	},
	async beforeMount() {
	},
	async mounted() {
		if (window.self !== window.top && this.source !== 'copilot') {
			store.commit('reservationForm/setIsIframe', {isIframe: true})
			this.sendResizeMessage();
		}

		if(this.rooms.length > 1){
			this.isVisibleSelectRooms = true
		}

	},
	beforeUnmount() {
		store.commit('reservationForm/clearDataFormModule');
	},
	watch: {
		availableRooms(){
			this.isOverlappingReservation = null
			this.userReservationUid = null
			if(this.availableRooms.length === 1){
				this.isVisibleSelectRooms = false;
				store.commit('setSelectedRoom', {selectedRoom: this.availableRooms[0].room_id})
			}else{
				this.isVisibleSelectRooms = true;
			}
		},
		selectedTime(){
			this.isOverlappingReservation = null
			this.userReservationUid = null
			if(this.availableRooms.length === 1){
				this.reservationRoom = this.availableRooms[0].room_id;
			}else{
				this.reservationRoom = "-1";
			}
		},
		async reservationPax(newReservationPax) {
			this.clearTimePicker();
			this.reservationRoom = "-1";
			this.setSelectedPax({selectedPax: newReservationPax});
			if (this.selectedDate && this.selectedPax) {
				console.log('Vamos a comprobar la disponibilidad');
				this.reserveButtonContent = 'Comprobando...'
				this.isSpinnerIconLoading = true
					await store.dispatch('reservationForm/checkAvailability');
				this.reserveButtonContent = 'Reservar'
				this.isSpinnerIconLoading = false
			}
		},
		async reservationRoom(newReservationRoom) {
			this.setSelectedRoom({selectedRoom: newReservationRoom});
		},
		async selectedDate() {
			this.isOverlappingReservation = null
			this.userReservationUid = null
			this.clearTimePicker();
			this.reservationRoom = "-1";
			if (this.selectedDate && this.selectedPax) {
				console.log('Vamos a comprobar la disponibilidad');
				this.reserveButtonContent = 'Comprobando...'
				this.isSpinnerIconLoading = true

				await store.dispatch('reservationForm/checkAvailability');
				this.reserveButtonContent = 'Reservar'
				this.isSpinnerIconLoading = false

			}
		},
		reservationMailWelkey() {
			this.isOverlappingReservation = null
			this.userReservationUid = null
			store.commit('reservationForm/setCurrentStep', {currentStep:2})
			this.isVisibleSurnameInput = false;
			// this.isVisibleGenderInput = false;
			this.isVisibleSurnameInput = false;
			this.isVisibleTermsInput = false;
			this.isVisiblePhoneInput = false;
		},
		currentStep() {
			requestAnimationFrame(() => {
				if(this.source !== 'copilot'){
					this.sendResizeMessage()
				}

			})
		}
	},
	computed: {
		...mapGetters('config' , ['websocketUrl','websocketPort', 'weWelcomUrl', 'wePassUrl']),
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName','reservationAgent','reservationManagerUrl' ]),
		...mapGetters('reservationForm', ['currentStep','availableRooms', 'selectedRoom', 'selectedPax', 'selectedDate', 'selectedTime', "selectedPhone", 'isCannotBeReserved', 'isIframe', 'selectedReservationDuration', 'isVisibleRoomsSelect', 'rooms']),
		reserveButtonDisabled() {
			const reserveButtonDisabled = !this.selectedDate || Number(this.selectedRoom) < 0 || !this.selectedPax || !this.selectedTime || this.isOverlappingReservation
			return reserveButtonDisabled;
		},

		isCopilotRoute() {
			return this.$route.path.includes('/copilot')
		},
	},
	methods: {
		onInputPhone(phoneInput, phoneObject) {
			if (phoneObject?.valid) {
				console.log('El telefono introducido: '+ phoneInput);
				console.log(phoneObject);

				const parsedPhoneNumber = parsePhoneNumberFromString(phoneInput, phoneObject.countryCode);
				const e164PhoneNumber = parsedPhoneNumber.number; // This will be in E.164 format
				console.log('El telefono introducido en E164: '+ e164PhoneNumber);

				const builtPhone = {
					phone: phoneObject.formatted,
					phoneE164: e164PhoneNumber,
					countryCode: phoneObject.countryCode.toLowerCase(),
					countryName: phoneObject.country.name.match(/\(([^)]+)\)/)[1],
					countryDialCode: phoneObject.countryCallingCode
				}
				console.log('El telefono definitivo: ', builtPhone);
				this.setPhone({selectedPhone: builtPhone})
			} else if (phoneObject && !phoneObject?.valid) {
				console.log("Teléfono inválido")
				this.setPhone({selectedPhone: null})
			}
		},
		...mapMutations('reservationForm', ['setSelectedPax', 'setSelectedRoom', 'setPhone', 'clearTimePicker', 'clearDataFormModule', 'sendResizeMessage']),
		clearForm(){
			this.reservationMailWelkey = null;
			this.signupName = null;
			this.signupSurname = null;
			this.signupTerms = null;
			this.errorMessage = null;
			this.reservationPax = null;
			this.reserveButtonContent = "Reservar";
			this.isSpinnerIconLoading = false
			this.reservationDate = '';
			this.reservationTime = '';
			this.reservationPax = '';
			store.commit('reservationForm/clearDataFormModule');
			store.commit('reservationForm/setCurrentStep', {currentStep:1})
			this.$emit('clear-form');
		},
		async createReservation(data){
			this.reserveButtonContent = "Solicitando reserva...";
			this.isSpinnerIconLoading = true;

			if (this.source === 'copilot'){
				data = {
					...data, // Keep the existing properties of `data`
					source: 10, // Add the "source" field (10 = copilot)
				};
			}

			console.log("Creando reserva con data:", data)
			const createReservationResult = await this.reservationRepository.create(data);

			if (createReservationResult.result === 0){
				if (createReservationResult.reservationStatus === 1){
					let uid = createReservationResult.uid;
					if(this.source === 'copilot'){
						this.clearForm();
						return true;
					}
					window.location.href = `${store.getters['config/wePassUrl']}/world/wepass/${uid}`;
					return
				}
				if (createReservationResult.reservationStatus === -6){
					console.log('la fecha del solapamiento1',this.selectedDate, this.selectedTime)
					this.reserveButtonContent = 'Reservar'
					this.isSpinnerIconLoading = false
					this.isOverlappingReservation = true
					this.userReservationUid = createReservationResult.uid
				}
			}
		},
		async submitReservation() {
			const enteredMailWelKey = this.reservationMailWelkey.trim();
			event.preventDefault();

			const data = {
				'reservation-date': this.selectedDate,
				'reservation-time': this.selectedTime,
				'reservation-duration': this.selectedReservationDuration,
				'reservation-pax': this.selectedPax,
				'reservation-room': this.selectedRoom,
				'reservation-welkhome-key': null,
				'reservation-terrace-preference': 0,
				'utmCampaign': false,
				'utmMedium': false,
				'utmSource': false,
				'utmTerm': false,
				'utmContent': false,
				'urlConversion': window.location.pathname,
				'urlReferrer': document.referrer,
			}

			console.log(data)
			switch (this.currentStep) {
				case 1:
					if (!this.selectedDate || !this.selectedRoom || !this.selectedPax || !this.selectedTime) {
						this.showErrorMessageDate = true;
						if (this.isIframe && this.source !== 'copilot') {
							requestAnimationFrame(() => {
								this.sendResizeMessage()
							})
						}
						return
					} else {
						this.showErrorMessageDate = false
						this.reserveButtonContent = 'Continuar';
						this.isSpinnerIconLoading = false
						store.commit('reservationForm/setCurrentStep', {currentStep:2})

						requestAnimationFrame(() => {
							// const inputRef = this.$refs['reservation-mail-wel-key'];
							// inputRef ? this.scrollToVue({element: inputRef, time: 1500, focus: true}) : null;
						});
					}
					break;
				case 2:
					console.log('Estás en el step 2');
					if (validateEmail(enteredMailWelKey)) {
						this.showErrorMessageDate = false;
						this.showErrorMessageWelKey = false;

						this.mailConfirmSave = enteredMailWelKey;

						console.log('Dirección de email detectada');
						this.reserveButtonContent = 'Comprobando...'
						this.isSpinnerIconLoading = true
						const result = await this.userRepository.checkEmail(enteredMailWelKey, true);

						if (result.result === -1) {
							console.log("Email no encontrado");
							this.reserveButtonContent = 'Reservar';
							this.isSpinnerIconLoading = false
							store.commit('reservationForm/setCurrentStep', {currentStep:4})
							this.isVisiblePhoneInput = true;
							this.isVisibleSurnameInput = true;
							this.isVisibleTermsInput = true;
							return;
						}

						if (result.result === 0) {
							console.log('Email encontrado', result);
							this.reserveButtonContent = 'Reservar';
							this.isSpinnerIconLoading = false

							if (!result.hasPhone || !result.hasEmail  || !result.hasSurName || (!result.hasAcceptedTerms && !this.isCopilotRoute)) {
								if(!result.hasAcceptedTerms && !this.isCopilotRoute){
									this.isVisibleTermsInput = true;
									this.showErrorMessageForm = true;
									this.errorMessage = 'Acepta nuestra política de privacidad para continuar.';
								}
								this.isVisiblePhoneInput = !result.hasPhone;
								this.isVisibleEmailInput = !result.hasEmail;
								this.isVisibleSurnameInput = !result.hasSurName;
								this.signupName = result.userName;
								this.signupSurname = result.userSurname;
								this.idReservationUser = result.id;
								store.commit('reservationForm/setCurrentStep', {currentStep:3})
								return;
							} else {
								try {

									data.identifiedByMail = true;
									data.identifiedByMailEmail = this.reservationMailWelkey.trim();

									await this.createReservation(data);
								} catch (e) {
									console.log('ha ocurrido un error', e);
								}
							}
						}
					} else {
						this.showErrorMessageWelKey = true;
						if (this.isIframe && this.source !== 'copilot') {
							requestAnimationFrame(() => {
								this.sendResizeMessage()
							})
						}
					}
					break;
				case 3:
					console.log('Estás en el step 3');

					data.identifiedByMail = true;
					data.identifiedByMailEmail = this.reservationMailWelkey.trim();

					if (!this.signupName) {
						this.errorMessage = 'Introduzca un nombre.'
						this.showErrorMessageForm = true;
						this.reserveButtonContent = "Reservar";
						this.isSpinnerIconLoading = false
						if (this.isIframe && this.source !== 'copilot') {
							requestAnimationFrame(() => {
								this.sendResizeMessage()
							})
						}
						return;
					}
					data.nameToUpdate = this.signupName;

					if (this.isVisiblePhoneInput) {
						if (!this.selectedPhone) {
							this.errorMessage = 'Parece que el número de teléfono introducido no es válido.';
							this.showErrorMessageForm = true;
							this.reserveButtonContent = "Reservar";
							this.isSpinnerIconLoading = false
							if (this.isIframe && this.source !== 'copilot') {
								requestAnimationFrame(() => {
									this.sendResizeMessage()
								})
							}
							return;
						}
						Object.assign(data, this.selectedPhone);
					}

					if (this.isVisibleSurnameInput) {
						data.surnameToUpdate = this.signupSurname;
					}

					if (this.isVisibleTermsInput && !this.signupTerms && !this.isCopilotRoute) {
						this.errorMessage = 'Por favor, acepta nuestros terminos de privacidad.'
						this.showErrorMessageForm = true;
						this.reserveButtonContent = "Reservar";
						this.isSpinnerIconLoading = false
						if (this.isIframe && this.source !== 'copilot') {
							requestAnimationFrame(() => {
								this.sendResizeMessage()
							})
						}
						return;
					}

					try {
						data.userAcceptedTerms = this.signupTerms;
						data.uid = this.idReservationUser;
						await this.createReservation(data);

					} catch (e) {
						console.log("Ups, algo ha ido mal", e);
					}

					break;
				case 4:
					console.log('Estás en el step 4', this.selectedPhone, this.signupName);
					if (!this.selectedPhone  || !this.signupName || (!this.signupTerms && !this.isCopilotRoute) ) {
						if (!this.signupName) {
							this.errorMessage = 'Por favor, dinos cómo podemos llamarte.'
						}
						if (!this.selectedPhone) {
							this.errorMessage = 'Parece que el número de teléfono introducido no es válido.';
						}
						if (!this.signupTerms && !this.isCopilotRoute) {
							this.errorMessage = 'Acepta nuestra política de privacidad para continuar.';
						}
						this.showErrorMessageForm = true;

						if (this.isIframe && this.source !== 'copilot') {
							requestAnimationFrame(() => {
								// this.sendResizeMessage()
							})
						}
						return;
					}
					if(this.source === 'copilot'){
						data.noInteraction = 1
					}
					Object.assign(data, this.selectedPhone);
					data.email = this.reservationMailWelkey;
					data.gender = this.signupGender;
					data.name = this.signupName;
					data.surname = this.signupSurname;
					this.showErrorMessageForm = false;
					console.log(data);

					try {
						this.reserveButtonContent = "Solicitando reserva...";
						this.isSpinnerIconLoading = true
						await this.createReservation(data);

					} catch (e) {
						console.log('ha ocurrido un error', e);
					}
					break;
			}
		},
		handleUserValidation(createReservationResult){
			this.webSocketClient = new WebSocketClient(this, this.websocketUrl, this.websocketPort);
			this.webSocketClient.connect(this.reservationMailWelkey.trim());
			this.reserveButtonContent = "Reservar";
			this.isSpinnerIconLoading = false
			const modalCheckInbox = new bootstrap.Modal(document.getElementById('modal-check-inbox'), {
				backdrop: 'static',
				keyboard: false
			});
			modalCheckInbox.show();
			const modalEnteredEmail = document.querySelector('#modal-entered-email');
			createReservationResult.userEmail ? modalEnteredEmail.innerText = createReservationResult.userEmail.trim() : modalEnteredEmail.innerText = this.signupEmail.trim();
			if (this.isIframe && this.source !== 'copilot') {
				this.sendResizeMessage(700);
			}
		}
	},
}
</script>
